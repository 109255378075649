import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TagInput from '../tagInput/TagInput'
import AiswSpinner from '../../../../../common/AiswSpinner'

import { mergeTeam } from '../../../../../api/api-v2-admin'

import './teamMerge.css'

class TeamMerge extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openOverlay: false,
      teamsToMerge: [],
      loading: false
    }
  }

  handleOpen = () => {
    this.setState({ openOverlay: true })
  }

  handleClose = () => {
    this.setState({ openOverlay: false })
  }

  onAddItem = (value) => {
    if (value !== '' && !isNaN(value) && !this.state.teamsToMerge.includes(value) && value !== this.props.editRow.RowKey) {
      const teamsToMerge = JSON.parse(JSON.stringify(this.state.teamsToMerge))
      teamsToMerge.push(value)
      this.setState({ teamsToMerge })
      return true
    }
    return false
  }

  onRemoveItem = (index) => {
    const teamsToMerge = JSON.parse(JSON.stringify(this.state.teamsToMerge))
    teamsToMerge.splice(index, 1)
    this.setState({ teamsToMerge })
  }

  onMergeClick = async () => {
    const doMerge = window.confirm('Teams [' + this.state.teamsToMerge.join(', ') + '] werden dem aktuell ausgewählten Team ' + this.props.editRow.RowKey + ' hinzugefügt und dann gelöscht. Bist du sicher?')
    if (doMerge) {
      this.setState({ loading: true })
      for (let i in this.state.teamsToMerge) {
        const teamId = this.state.teamsToMerge[i]
        try {
          await mergeTeam(teamId, this.props.editRow.RowKey)
        } catch (err) {
          console.error('Team ', + teamId, + 'konnte dem Hauptteam ' + this.props.editRow.RowKey + ' nicht hinzugefügt werden: ' + err)
          // TODO: Implement Toast to notify user
        }
      }
      this.setState({ loading: false, openOverlay: false })
      this.props.onDone(undefined, true)
    }
  }

  render() {
    return (
      <div>
        <Dialog fullWidth open={this.state.openOverlay} onClose={this.handleClose} aria-labelledby='alert-dialog-title'>
          <DialogTitle id='alert-dialog-title'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {this.props.editRow.RowKey}:{this.props.editRow.name}
            </div>
          </DialogTitle>
          <DialogContent>
            <TagInput
              addLabel="Teams Hinzufügen"
              listLabel="Teams"
              placeholder="TeamId"
              items={this.state.teamsToMerge}
              onAddItem={this.onAddItem}
              onRemoveItem={this.onRemoveItem}
            />
            {this.state.loading && <AiswSpinner />}
          </DialogContent>
          <DialogActions>
            <Button disabled={this.state.loading} onClick={this.handleClose} color='primary'>
              Abbrechen
            </Button>
            <Button disabled={this.state.loading || this.state.teamsToMerge.length <= 0} onClick={this.onMergeClick} color='primary'>
              Zusammenfügen
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          onClick={() => this.handleOpen()}
          color='primary'
          variant='contained'
          className='camera-btn'
          style={{ alignSelf: 'center' }}
        >
          {this.props.row.title} Öffnen
        </Button>
      </div>
    )
  }
}

export default TeamMerge
