import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'

rax.attach()

export const postCup = async (body, RowKey) => {
  return axios.post(discovery.API_CUP + '/info/' + RowKey, body, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const deleteAdCup = async (RowKey) => {
  return axios.delete(discovery.API_CUP + '/delete/' + RowKey, { headers: { Authorization: await getAuthorization() } })
}
