import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'

import { postHighlight } from '../../../api/api-clip'
import { InputField } from '../../static/inputFields'

import '../clips.css'

class EditColum extends Component {
  constructor (props) {
    super(props)
    this.state = {
      RowKey: this.props.tableRow.RowKey,
      name: this.props.tableRow.name,
      priority: this.props.tableRow.priority,
      Timestamp: this.props.tableRow.Timestamp,
      eventType: this.props.tableRow.eventType,
      level: this.props.tableRow.level,
      matchIds: JSON.stringify(this.props.tableRow.matchIds),
      metaTags: this.props.tableRow.metaTags,
      referenceDate: this.props.tableRow.referenceDate,
      leagues: this.props.tableRow.leagues,
      clipStream: this.props.tableRow.clipStream,
      active: this.props.tableRow.active
    }
  }

  handleChange (event) {
    const x = event.target.value
    this.setState({ [event.target.id]: x })
  }

  handleEditColum () {
    const metaTags = this.state.metaTags && this.state.metaTags.length ? this.state.metaTags.split(', ') : undefined
    const editClip = {
      RowKey: String(this.state.RowKey),
      name: this.state.name,
      priority: this.state.priority,
      Timestamp: this.state.Timestamp,
      eventType: this.state.eventType,
      metaTags: metaTags,
      referenceDate: this.state.referenceDate,
      leagues: this.state.leagues
    }
    Object.keys(editClip).forEach((key) => {
      if (editClip[key] === undefined) {
        delete editClip[key]
      }
    })
    postHighlight(editClip)
  }

  render () {
    const tempHandleChange = this.handleChange.bind(this)
    return (
      <Grid container spacing={24}>
        <InputField
          id='RowKey'
          label='RowKey'
          value={this.state.RowKey}
          activeCheck={false}
          handleChange={tempHandleChange}
        />
        <InputField id='name' label='name' value={this.state.name} activeCheck handleChange={tempHandleChange} />
        <InputField
          id='priority'
          label='priority'
          value={this.state.priority}
          activeCheck
          handleChange={tempHandleChange}
        />
        <InputField
          id='Timestamp'
          label='Timestamp'
          value={this.state.Timestamp}
          activeCheck
          handleChange={tempHandleChange}
        />
        <InputField
          id='eventType'
          label='eventType'
          value={this.state.eventType}
          activeCheck
          handleChange={tempHandleChange}
        />
        <InputField
          id='level'
          label='level'
          value={this.state.level}
          activeCheck={false}
          handleChange={tempHandleChange}
        />
        <InputField
          id='matchIds'
          label='matchIds'
          value={this.state.matchIds}
          activeCheck={false}
          handleChange={tempHandleChange}
        />
        <InputField
          id='metaTags'
          label='metaTags'
          value={this.state.metaTags}
          activeCheck
          handleChange={tempHandleChange}
        />
        <InputField
          id='referenceDate'
          label='referenceDate'
          value={this.state.referenceDate}
          activeCheck
          handleChange={tempHandleChange}
        />
        <InputField
          id='leagues'
          label='leagues'
          value={this.state.leagues}
          activeCheck
          handleChange={tempHandleChange}
        />
        <InputField
          id='clipStream'
          label='clipStream'
          value={this.state.clipStream}
          activeCheck={false}
          handleChange={tempHandleChange}
        />
        <Grid className='dialog-btn'>
          <DialogActions>
            <Button onClick={this.props.handleClose} className='btn' color='primary' variant='contained'>
              Abbrechen
            </Button>
            <Button
              onClick={this.handleEditColum.bind(this)}
              className='btn'
              color='primary'
              variant='contained'
              autoFocus
            >
              Speichern
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    )
  }
}

export default EditColum
