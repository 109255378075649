import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization, callAs } from './helper'
rax.attach()

export const getCloudlog = async (type, rowkey) => {
  return axios.get(discovery.API_CLOUDLOG + '/cloudlog/' + type + '/'+ rowkey + callAs('admin'), { headers: { Authorization: await getAuthorization() } })
}
