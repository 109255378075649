import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

class ErrorBox extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (id) {
    window.open(window.location.origin + '/cameras/uid=' + id, '_blank')
  }

  renderHandler () {
    if (this.props.type === 'connection_lost') {
      if (this.props.data) {
        return this.connectionLost(1, 'rgb(255, 94, 94)')
      }
    } else if (this.props.type === 'no_power') {
      if (this.props.data) {
        return this.connectionLost(30, 'rgb(217, 160, 38)')
      }
    }
  }

  connectionLost (day, color) {
    return (
      <Table>
        <TableHead>
          <TableRow style={{ color: '#fff !important' }}>
            <TableCell>ID</TableCell>
            <TableCell align='right'>Verein</TableCell>
            <TableCell align='right'>Offline seit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { this.props.data.map((row, index) => (
            <TableRow key={index} style={row.offlineDifferenceDays > day ? { background: color } : {}} onClick={this.handleClick.bind(this, row.RowKey)}>
              <TableCell component='th' scope='row'>
                {row.RowKey}
              </TableCell>
              <TableCell align='right'>{row.ownerClubId}</TableCell>
              <TableCell align='right'>{row.offlineDifference}</TableCell>
            </TableRow>
          )) }
        </TableBody>
      </Table>
    )
  }

  clubMerge () {
    this.props.data.map(data => {
      this.props.clubs.map(club => {
        if (data.ownerClubId === club.RowKey) {
          data.ownerClubId = club.name
        }
        return null
      })

      return null
    })
  }

  render () {
    let dataLength = 0
    if (this.props.data) {
      dataLength = this.props.data.length
    }

    if (this.props.clubs) {
      this.clubMerge()
    }

    return (
      <div>
        <Paper>
          <div className='layout-head'>{this.props.headText} (Anzahl: { dataLength})</div>
          <div className='errorBox errorBox-lost'>
            {this.renderHandler()}
          </div>
        </Paper>
      </div>
    )
  }
}

export default ErrorBox
