import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import './webrtc.scss'

class Webrtc extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: this.props.webrtc
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  render () {
    return (
      <div className='camera-btn-heigh'>
        {this.props.disabled === true ? (
          <Button
            onClick={this.handleClickOpen}
            color='primary'
            variant='contained'
            disabled
            className='camera-btn camera-btn-disable'
          >
            {this.props.btnname}
          </Button>
        ) : (
          <Button onClick={this.handleClickOpen} color='primary' variant='contained' className='camera-btn'>
            {this.props.btnname}
          </Button>
        )}
        <Dialog 
          maxWidth={'xl'}
          fullWidth
          disableBackdropClick
          open={this.state.open}
          onClose={this.handleClose}
        >
          <DialogTitle>Cockpit</DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <aisw-rtc login={false} camera={this.props.camId ? String(this.props.camId) : undefined} isk2x={this.props.hardwarePlatform === 'K2X'}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default Webrtc
