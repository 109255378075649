import axios from 'axios'
import * as rax from 'retry-axios'
// import firebase from 'firebase/compat/app'
// import 'firebase/compat/auth'
import { auth } from '../../components/common/firebase'
rax.attach()

let callAsAdminTenet = 'admin'

export const setAdminTenet = (tenetRole) => {
  callAsAdminTenet = tenetRole
}

export const callAs = (role) => {
  if (role === 'admin') {
    return `?callAs=${callAsAdminTenet}`
  }
  return `?callAs=${role}`
}

export const getAuthorization = async () => {
  const checkUser = await auth.currentUser
  if (checkUser) {
    return 'Bearer ' + await auth.currentUser.getIdToken()
  }
}

export const getAuthorizationHeader = async (headers) => {
  const header = headers || {}
  const userId = await auth.currentUser.getIdToken()
  header.Authorization = 'Bearer ' + userId
  header['Content-Type'] = 'application/json'
  return {
    headers: header
  }
}

export const header = async () => { return { headers: { Authorization: await getAuthorization() } } }
export const headerJson = async () => { return { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } } }

export const getLazyLoading = async (config, mounted, since) => {
  const dataArray = []
  const responseArray = []
  let lastToken = false
  const callNumber = config[0].number || 0
  let callRound = []
  try {
    for (var g = 0; g < config.length; g++) {
      responseArray.push((await axios.get(config[g].url + callAs('admin'), { headers: { Authorization: await getAuthorization() } })).data)
      dataArray[g] = dataArray.concat(responseArray[g].data)
      // getHandler
      lastToken = responseArray[g].nextToken || true
      config[g].handle(dataArray[g], lastToken, lastToken)
    }

    for (var y = 0; y < config.length; y++) {
      for (var i = 0; i < config.length; i++) {
        // Check components mounted
        const checkMounted = mounted()
        if (checkMounted === false) {
          config[i].handle(dataArray[i], lastToken, lastToken)
          return null
        }
        if (responseArray[i].nextToken) {
          const token = typeof responseArray[i].nextToken === 'string' ? responseArray[i].nextToken.replace(/\//g, '%2F') : responseArray[i].nextToken
          
          responseArray[i] = (await axios.get(
            config[i].url + token + callAs('admin'),
            { headers: { Authorization: await getAuthorization() } }
          )).data
          dataArray[i] = dataArray[i].concat(responseArray[i].data)
          // getHandler
          lastToken = responseArray[i].nextToken || true
          // config[i].handle(dataArray[i], lastToken);
          callRound.push('1')

          // for time break
          if (since) {
            const temp = dataArray[i][dataArray[i].length - 1]
            const refDate = temp.referenceDate ? new Date(temp.referenceDate).getTime() : null
            if (!(refDate > since.dateFrom)) {
              config[i].handle(dataArray[i], true, lastToken)
              return null
            }
          }

          if (callRound.length >= callNumber || lastToken === true) {
            callRound = []
            config[i].handle(dataArray[i], lastToken)
          }

          if (Math.abs(y) === config[i].intervalStop) {
            config[i].handle(dataArray[i], true, lastToken)
            return null
          }

          if (responseArray[i].nextToken) {
            if (config[i].minEntrys && config[i].minEntrys < dataArray.length) {
              break
            } else {
              i--
              y--
            }
          }
        }
      }
    }
    return null
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getLazyLoadingWithoutDataObj = async (config, mounted, since) => {
  const dataArray = []
  const responseArray = []
  let lastToken = false
  const callNumber = config[0].number || 0
  let callRound = []
  try {
    for (var g = 0; g < config.length; g++) {
      responseArray.push((await axios.get(config[g].url + callAs('admin'), { headers: { Authorization: await getAuthorization() } })).data)
      dataArray[g] = dataArray.concat(responseArray[g])
      // getHandler
      lastToken = responseArray[g].nextToken || true
      config[g].handle(dataArray[g], lastToken)
    }

    for (var y = 0; y < config.length; y++) {
      for (var i = 0; i < config.length; i++) {
        // Check components mounted
        const checkMounted = mounted()
        if (checkMounted === false) {
          return null
        }

        if (responseArray[i].nextToken) {
          responseArray[i] = (await axios.get(
            config[i].url + responseArray[i].nextToken.replace(/\//g, '%2F') + callAs('admin'),
            { headers: { Authorization: await getAuthorization() } }
          ))
          dataArray[i] = dataArray[i].concat(responseArray[i])
          // getHandler
          lastToken = responseArray[i].nextToken || true
          // config[i].handle(dataArray[i], lastToken);
          callRound.push('1')

          // for time break
          if (since) {
            const temp = dataArray[i][dataArray[i].length - 1]
            const refDate = temp.referenceDate ? new Date(temp.referenceDate).getTime() : null
            if (!(refDate > since.dateFrom)) {
              config[i].handle(dataArray[i], true)
              return null
            }
          }

          if (callRound.length >= callNumber || lastToken === true) {
            callRound = []
            config[i].handle(dataArray[i], lastToken)
          }

          if (Math.abs(y) === config[i].intervalStop) {
            config[i].handle(dataArray[i], true, lastToken)
            return null
          }

          if (responseArray[i].nextToken) {
            if (config[i].minEntrys && config[i].minEntrys < dataArray.length) {
              break
            } else {
              i--
              y--
            }
          }
        }
      }
    }
    return null
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getLazyLoadingPageToken = async (config, mounted, since) => {
  const dataArray = []
  const responseArray = []
  let lastToken = false
  const callNumber = config[0].number || 0
  let callRound = []
  try {
    for (var g = 0; g < config.length; g++) {
      responseArray.push((await axios.get(config[g].url, { headers: { Authorization: await getAuthorization() } })).data)
      dataArray[g] = dataArray.concat(responseArray[g].data)
      // getHandler
      lastToken = responseArray[g].pageToken || true
      config[g].handle(dataArray[g], lastToken)
    }

    for (var y = 0; y < config.length; y++) {
      for (var i = 0; i < config.length; i++) {
        // Check components mounted
        const checkMounted = mounted()
        if (checkMounted === false) {
          config[i].handle(dataArray[i], lastToken, lastToken)
          return null
        }

        if (responseArray[i].pageToken) {
          const checkLastChar = config[i].url.charAt(config[i].url.length - 1) === '0'
          if (checkLastChar) {
            config[i].url = config[i].url.slice(0, config[i].url.length - 1)
          }

          responseArray[i] = (await axios.get(
            config[i].url + responseArray[i].pageToken.replace(/\//g, '%2F'),
            { headers: { Authorization: await getAuthorization() } }
          )).data
          dataArray[i] = dataArray[i].concat(responseArray[i].data)
          // getHandler
          lastToken = responseArray[i].pageToken || true
          // config[i].handle(dataArray[i], lastToken);
          callRound.push('1')

          // for time break
          if (since) {
            const temp = dataArray[i][dataArray[i].length - 1]
            const refDate = temp.referenceDate ? new Date(temp.referenceDate).getTime() : null
            if (!(refDate > since.dateFrom)) {
              config[i].handle(dataArray[i], true)
              return null
            }
          }

          if (callRound.length >= callNumber || lastToken === true) {
            callRound = []
            config[i].handle(dataArray[i], lastToken)
          }

          if (Math.abs(y) === config[i].intervalStop) {
            config[i].handle(dataArray[i], true, lastToken)
            return null
          }

          if (responseArray[i].pageToken) {
            if (config[i].minEntrys && config[i].minEntrys < dataArray.length) {
              break
            } else {
              i--
              y--
            }
          }
        }
      }
    }
    return null
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getLazyLoadingNext = async (config, mounted, dataArrayX, nextRound) => {
  const dataArray = dataArrayX
  const responseArray = []
  let lastToken = nextRound
  const callNumber = config[0].number || 0
  let callRound = []
  try {
    for (var g = 0; g < config.length; g++) {
      responseArray.push((await axios.get(config[g].url + lastToken.replace(/\//g, '%2F') + callAs('admin'), { headers: { Authorization: await getAuthorization() } })).data)
      dataArray[g] = dataArray.concat(responseArray[g].data)
      // getHandler
      lastToken = responseArray[g].nextToken || true
      config[g].handle(dataArray[g], lastToken, lastToken)
    }

    for (var y = 0; y < config.length; y++) {
      for (var i = 0; i < config.length; i++) {
        // Check components mounted
        const checkMounted = mounted()

        if (checkMounted === false) {
          config[i].handle(dataArray[i], lastToken, lastToken)
          return null
        }

        if (responseArray[i].nextToken) {
          responseArray[i] = (await axios.get(
            config[i].url + responseArray[i].nextToken.replace(/\//g, '%2F') + callAs('admin'),
            { headers: { Authorization: await getAuthorization() } }
          )).data

          dataArray[i] = dataArray[i].concat(responseArray[i].data)
          // getHandler
          lastToken = responseArray[i].nextToken || true
          // config[i].handle(dataArray[i], lastToken);
          callRound.push('1')

          if (callRound.length >= callNumber || lastToken === true) {
            callRound = []
            config[i].handle(dataArray[i], lastToken, lastToken)
          }

          if (Math.abs(y) === config[i].intervalStop) {
            config[i].handle(dataArray[i], true, lastToken)
            return null
          }

          if (responseArray[i].nextToken) {
            if (config[i].minEntrys && config[i].minEntrys < dataArray.length) {
              config[i].handle(dataArray[i], true, lastToken)
              break
            } else {
              i--
              y--
            }
          }
        }
      }
    }
    return null
  } catch (err) {
    return Promise.reject(err)
  }
}

export const apiList = async (url, minEntrys, header) => {
  let dataArray = []
  let response

  try {
    response = (await axios.get(url + callAs('admin'), header)).data
    response.nextToken = response.nextToken ? String(response.nextToken) : undefined
    dataArray = dataArray.concat(response.data)
    // Fetch all cities and use nextToken as long as necessary
    while (response.nextToken) {
      const checkLastChar = url.charAt(url.length - 1) === '/'
      if (!checkLastChar) {
        url = url + '/'
      }
      // Replace Slashes
      response = (await axios.get(url + String(response.nextToken).replace(/\//g, '%2F') + callAs('admin'), header)).data
      dataArray = dataArray.concat(response.data)
      if (minEntrys && minEntrys < dataArray.length) {
        break
      }
    }
    return dataArray
  } catch (err) {
    return Promise.reject(err)
  }
}

export const apiListStatus = async (url, minEntrys, header) => {
  let dataArray = []
  let response

  try {
    response = (await axios.get(url, header)).data
    dataArray = dataArray.concat(response.data)
    // Fetch all cities and use nextToken as long as necessary
    while (response.nextToken) {
      // Replace Slashes
      response = (await axios.get(url + response.nextToken.replace(/\//g, '%2F'), header)).data
      dataArray = dataArray.concat(response.data)
      if (minEntrys && minEntrys < dataArray.length) {
        break
      }
    }
    return { data: dataArray, status: 200 }
  } catch (err) {
    return Promise.reject(err)
  }
}

/**
 * Fetches elelements from a soccerwatch.tv list api and checks after each
 * call if fetching should stop now or procede.
 *
 * @param {string} url
 * @param {function} stopFetchingFunction
 * <listEntry> := true (stop fetching), false(continue)
 *
 * @param {object} header Secure header
 */
export const apiListFilter = async (url, stopFetchingFunction, header) => {
  let dataArray = []
  let response

  try {
    response = (await axios.get(url, header)).data
    dataArray = dataArray.concat(response.data)
    // Fetch all cities and use nextToken as long as necessary
    while (response.nextToken) {
      // Replace Slashes
      response = (await axios.get(url + response.nextToken.replace(/\//g, '%2F'), header)).data
      dataArray = dataArray.concat(response.data)

      if (stopFetchingFunction(dataArray[dataArray.length - 1])) {
        break
      }
    }
    return dataArray
  } catch (err) {
    return Promise.reject(err)
  }
}

export const apiListFilterTelemetry = async (url, header, matchId) => {
  let dataArray = []
  let response
  let stop = false

  try {
    response = (await axios.get(url, header)).data
    dataArray = dataArray.concat(response.data).filter(n => n.telemetry_type === 'upload')

    if (!response.nextToken) {
      for (let i = 0; i < dataArray.length - 1; i++) {
        if (dataArray[i].message.record_upload && dataArray[i].message.record_upload.uploader) {
          if (dataArray[i].message.record_upload.uploader[matchId] && dataArray[i + 2] === undefined) {
            dataArray.splice(i, dataArray.length)
            stop = true
            break
          }
        }
      }
    }

    // Fetch all cities and use nextToken as long as necessary
    while (response.nextToken) {
      // Replace Slashes
      response = (await axios.get(url + response.nextToken.replace(/\//g, '%2F'), header)).data
      dataArray = dataArray.concat(response.data).filter(n => n.telemetry_type === 'upload')

      for (let i = 0; i < dataArray.length - 1; i++) {
        if (dataArray[i].message.record_upload && dataArray[i].message.record_upload.uploader) {
          if (dataArray[i].message.record_upload.uploader[matchId] && !dataArray[i + 1].message.record_upload.uploader[matchId]) {
            dataArray.splice(i, dataArray.length)
            stop = true
            break
          }
        }
      }

      if (stop) {
        break
      }
    }

    if (!stop) {
      dataArray = {}
    }

    return dataArray
  } catch (err) {
    return Promise.reject(err)
  }
}
