import React, { Component } from 'react'
import { SketchPicker } from 'react-color'

import './colorPicker.scss'

class ColorPicker extends Component {
  constructor (props) {
    super(props)

    this.state = {
      colors: []
    }
  }

  componentDidMount () {
    const notSet = this.props.max - this.props.value.length
    let tempColor = []
    
    if (!Array.isArray(this.props.value)) {
      return
    }
    
    tempColor = this.props.value.map(color => {
      return { color: color, active: false, set: true }
    })

    for (var i = 0; notSet > i; i++) {
      tempColor.push({ color: undefined, active: false, set: false })
    }

    this.setState({
      colors: tempColor
    })
  }

  handleChangeComplete = (color) => {
    this.setState({ background: color.hex })
  };

  handleClick = (index) => {
    let tempColor = JSON.parse(JSON.stringify(this.state.colors))

    if (!tempColor[index].active) {
      tempColor = tempColor.map((itm, i) => {
        if (i !== index) {
          itm.active = false
          return itm
        }
        return itm
      })
    }

    tempColor[index].active = !tempColor[index].active
    this.setState({ colors: tempColor })
  };

  handleClose = (index) => {
    const tempColor = JSON.parse(JSON.stringify(this.state.colors))
    tempColor[index].active = false
    this.setState({ colors: tempColor })
  };

  handleChange = (color, index) => {
    const tempColor = JSON.parse(JSON.stringify(this.state.colors))
    tempColor[index].color = color.hex
    tempColor[index].set = true
    this.props.handleChange(this.props.row.name, this.filterColor(tempColor))
    this.setState({ colors: tempColor })
  };

  handleDelete = (index) => {
    const tempColor = JSON.parse(JSON.stringify(this.state.colors))
    tempColor[index].color = undefined
    tempColor[index].set = false
    this.props.handleChange(this.props.row.name, this.filterColor(tempColor))
    this.setState({ colors: tempColor })
  };

  filterColor (colors) {
    const res = colors.map(itm => {
      if (itm.color) {
        return itm.color
      }
    }).filter(function (el) {
      return el != null
    })
    return res
  }

  render () {
    return (
      <div>
        <div className='textField-label' style={{ paddingBottom: '5px' }}>{this.props.row.title}</div>
        <div className='colorBar'>
          {this.state.colors.map((itm, i) => {
            return (
              <div key={i}>
                <div className='swatch'>
                  <div style={{ display: 'flex' }}>
                    <div className='colorBox' style={{ backgroundColor: itm.color }} onClick={() => this.handleClick(i)}> {itm.set ? null : '+'} </div>
                    {itm.set ? (
                      <div
                        className='colorBox'
                        style={{
                          backgroundColor: '#ffb6b6',
                          marginLeft: '-5px',
                          width: '14px'
                        }}
                        onClick={() => this.handleDelete(i)}
                      >
                        x
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )
          })}

        </div>
        {this.state.colors.map((itm, i) => {
          return (
            <div key={i}>
              {itm.active ? (
                <div>
                  <div className='cover' onClick={() => this.handleClose(i)}>
                    <div>Box {i + 1}</div>
                    <div> X </div>
                  </div>

                  <SketchPicker color={itm.color} onChange={(itm) => this.handleChange(itm, i)} />
                </div>
              ) : null}
            </div>
          )
        })}
      </div>
    )
  }
}

export default ColorPicker
