import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import './deleteButton.css'

import AiswSpinner from '../../../../../common/AiswSpinner'

class DeleteButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      disabled: false,
      loading: false
    }
  }

  handleClick = async () => {
    const confirm = await window.confirm(`Bist du sicher, dass du den Eintrag ${this.props.name} Löschen möchtest?`)
    if (confirm) {
      this.setState({ loading: true, error: undefined })
      try {
        const res = await this.props.onDeleteConfirmed(this.props.entry)
      } catch (err) {
        if (err.response.data.status) {
          return this.setState({ disabled: true, error: 'Das Team ist berreits Spielen zugeordnet und kann deshalb nicht gelöscht werden.', loading: false })
        }
        else {
          console.error(err)
          return this.setState({ error: 'Irgendwas ist hier schiefgelaufen. Bitte versuche es später nochmal.', loading: false })
        }
      }
      this.setState({ loading: false })
      this.props.onDone(undefined, true)
    }
  }

  render() {
    return (
      <div>
        <Button
          onClick={() => this.handleClick()}
          color='error'
          variant='contained'
          className='deleteButton'
          disabled={this.state.disabled}
          style={{ alignSelf: 'center' }}
        >
          Eintrag Löschen
        </Button>
        {this.state.loading && <AiswSpinner />}
        {this.state.error && <p className='ERROR'>{this.state.error}</p>}
      </div>
    )
  }
}

export default DeleteButton
