import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization, callAs, apiList } from './helper'

rax.attach()

export const getMetaById = async (id, state) => {
  return axios.get(discovery.API_VIDEO + '/metaBy/camera/' + id + '/' + state + callAs('admin'), { headers: { Authorization: await getAuthorization() } })
}

export const getMetaBy = async (id) => {
  return axios.get(discovery.API_VIDEO + '/meta/' + id + callAs('admin'), { headers: { Authorization: await getAuthorization() } })
}

export const postMeta = async (json) => {
  return axios.post(discovery.API_VIDEO + '/meta/' + callAs('admin'), JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const postMetaId = async (json, id) => {
  return axios.post(discovery.API_VIDEO + '/meta/' + id + callAs('admin'), JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const cloneMatch = async (id) => {
  return axios.post(discovery.API_VIDEO + '/cloneMeta/' + id + callAs('admin'), {}, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getAllVideosOfContract = async (contractId) => {
  return apiList(`${discovery.API_VIDEO}/metaBy/contract/${contractId}`, undefined, { headers: { Authorization: await getAuthorization() } })
}