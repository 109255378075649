import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { apiList, getAuthorization, callAs } from './helper'
import { ExitToAppSharp } from '@material-ui/icons'
rax.attach()

export const getClubInfo = async (id) => {
  return axios.get(discovery.API_CLUB + '/info/' + id + callAs('admin'), { headers: { Authorization: await getAuthorization() } })
}

export const getClubs = async () => {
  return apiList(discovery.API_CLUB + '/clubs/', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const getClubsSystem = async () => {
  return apiList(discovery.API_CLUB + '/clubs/system/', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const postClub = async (json) => {
  return axios.post(discovery.API_CLUB + '/info/' + callAs('admin'), JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getClubsOfCity = async (name) => {
  const res = await axios.get(`${discovery.API_CLUB}/clubsByCity/${name}/${callAs('admin')}`, { headers: { Authorization: await getAuthorization() } })
  return res.data.data
}

export const getClubsByCityId = async (id) => {
  const res = await axios.get(`${discovery.API_CLUB}/clubsByCityId/${id}/${callAs('admin')}`, { headers: { Authorization: await getAuthorization() } })
  return res.data.data 
}