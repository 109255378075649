import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import TagInput from '../../static/table/container/tagInput/TagInput'

import 'react-select/dist/react-select.css'
import '../game.css'

import { DialogContent } from '@material-ui/core'
import { postTestgame } from '../../../api/api-import'
import moment from 'moment/moment'

class FinalCheckTest extends Component {
  constructor (props) {
    super(props)

    const date = new Date()
    date.setMinutes(date.getMinutes() + 5)

    this.state = {
      open: false,
      selectCamIds: [],
      selectedOption: { value: '' },
      res: undefined
    }
  }

  handleClose = () => {
    this.setState({ open: false, res: undefined })
  }

  async handleAddTest () {
    this.setState({
      open: true,
      loading: true
    })

    let date = new Date()
    date = date.setMinutes(date.getMinutes() + 5)
    date = new Date(date).toISOString('de',{timeZone:'Europe/Berlin', timeZoneName: 'long'}).split('T')
    let startTime = moment().set('minute',moment().minutes() + 5).format("HH:mm")

    const configTestSettings = {
      0: { startDate: date[0] , startTime: startTime, duration: 20 }
      // 0: { startDate: todayDate.toISOString().split('T')[0] , startTime: startNow, duration: 1 },
      // 1: { startDate: todayDate.toISOString().split('T')[0] , startTime: '16:00', duration: 120 },
      // 2: { startDate: tomorrowDate.toISOString().split('T')[0] , startTime: '04:00', duration: 10 }
    }
    const tempArray = []

    for (let i = 0; i < this.state.selectCamIds.length; i++) {
      for (let ia = 0; ia < 1; ia++) {
        tempArray.push({
          swcsID: this.state.selectCamIds[i],
          date: configTestSettings[ia].startDate,
          startTime: configTestSettings[ia].startTime,
          duration: configTestSettings[ia].duration,
          contentType: 'test',
          useStateFlow: 'cameraUploadTest', 
          useForCalibration: false,
          cameraBitrate: 10000
        })
      }
    }

    const json = JSON.stringify(tempArray) // Object to JSON

    await postTestgame(json).then(res => {
      if (res.status === 200) {
        this.setState({
          res: res.data,
          loading: false
        })
      }
    })

    this.setState({
      selectCamIds: [],
      activeProcessing: [],
      selectedOption: { value: '' },
      loading: false
    })
  }

  handleChangeSelect = (selected) => {
    const selectedOption = selected || { value: '' }
    this.setState({ selectedOption })
  }

  
  handleTagInputChanged = (add) => (item, index) => {
    let value = this.state.selectCamIds

    if (add) {
      item = item.trim()
      item = item.replaceAll(' ','')

      if (isNaN(item)) {
        return false
      }

      value.push(item)
    } else {
      value.splice(index, 1)
    }

    this.setState({
      selectCamIds: value
    })
    return true
  }

  render () {
    const { selectCamIds } = this.state

    return (
      <div>
        <form className='game-addtest' noValidate style={{ paddingTop: 10, paddingBottom: 10 }}>
          <TagInput
            addLabel={`Kamera/s Hinzufügen`}
            placeholder={`Kamera ID`}
            items={selectCamIds}
            onAddItem={this.handleTagInputChanged(true)}
            onRemoveItem={this.handleTagInputChanged(false)}
          />

          <div className='infoText-finalCheck'>
            Die Aufnahme/n werden angelegt mit einer birate von 10000.
            {/*Da die Tests so eingestellt werden, dass immer fix eine Aufnahme um 16Uhr eingereiht wird, beachte, dass dein Test vor 15:30Uhr hier drüber eingereicht werden sollte, ansonsten kann es zu Fehler kommen.
            <br /> <br />
            Erste Aufnahem in 10 Minuten / 1 min
            <br />
            Zweite Aufnahme Heute um 15uhr / 120 min
            <br />
            Dritte Aufnahme Morgen um 4Uhr / 10 min*/}
          </div>
          
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {selectCamIds.length > 0
              ? <Button onClick={this.handleAddTest.bind(this)} variant='contained' color='primary' className='addtest-btn btn'>Anlegen</Button>
              : <Button onClick={this.handleAddTest.bind(this)} disabled variant='contained' color='primary' className='addtest-btn btn'> Anlegen</Button>}
          </div>
         
          <div>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
              fullWidth
              disableBackdropClick
            >
              <DialogTitle id='alert-dialog-title'>
                Testaufnahmen werden angelegt
              </DialogTitle>
              <DialogContent>
                {this.state.loading ? ('bitte habe etwas gedult...') : (JSON.stringify(this.state.res))}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} className='btn' color='primary' variant='contained' autoFocus>
                  Bestätigen
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </form>
      </div>
    )
  }
}

export default FinalCheckTest
