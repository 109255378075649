import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import EditColum from './tableDialog/editColum'
import ContentMenu from '../static/menus/contentMenu'
import OverlayTrain from './modules/trainerMailAuth'
import discovery from '@soccerwatch/discovery'
import { getLazyLoadingPageToken } from '../../api/helper'
import { patchUser } from '../../api/api-user'
import { Cookies } from 'react-cookie'

const config = {
  tableName: 'userTable',
  tableUrl: '/user/',
  tableDialogTitle: 'Benutzer',
  tableToolbar: false,
  tableAdd: false,
  tableSubscript: true,
  columns: [
    { name: 'licenses', title: 'Licenses', width: 70 },
    { name: 'contracts', title: 'contracts', width: 100 },
    { name: 'uid', title: 'uid', width: 190 },
    { name: 'Username', title: 'Username', width: 190 },
    { name: 'email', title: 'Email', width: 260 },
    { name: 'stripe', title: 'stripe', width: 100 },
    { name: 'ban', title: 'ban', width: 105 },
    { name: 'disabled', title: 'Deaktiviert', width: 200, value: 'boolean' },
    { name: 'emailVerified', title: 'Email Verifiziert', width: 200, value: 'boolean' },
    { name: 'role', title: 'Rolle', width: 280 },
    { name: 'lastSignInTime', title: 'Letzter Login', width: 250 }
    // { name: 'coins', title: 'coins', width: 200 },
    // { name: 'subscriptions', titel: 'Subscriptions', width: 280 }
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class User extends Component {
  constructor (props) {
    super(props)
    this.child = React.createRef()

    this.state = {
      users: [],
      rows: [],
      tempId: '0',
      loadingRows: true
    }
  }

  _isMounted = false;

  handleId = (id) => {
    if (id != null) {
      this.setState({ users: [], tempId: id })
    }
  };

  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  handleMounted = () => {
    return this._isMounted
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleBanStatus = (data, index) => {
    const editUser = {
      uid: data.uid,
      disabled: data.disabled = !data.disabled
    }

    const json = JSON.stringify(editUser)
    patchUser(json)

    editUser.tableId = index
    editUser.ban = (
      <a
        style={{ color: '#FF3517', textTransform: 'uppercase', fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.75', letterSpacing: '0.02857em', cursor: 'pointer', userSelect: 'none', WebkitTapHighlightColor: 'transparent' }}
        onClick={() => { this.handleBanStatus(data, index) }}
      >
        {data.disabled ? 'Entbannen' : 'Bannen'}
      </a>

    )
    this.child.current.changeRow({ json: editUser, row: this.state.rows[index] })
  }

  handleGetUserList = (res, checkLast) => {
    const rowTemp = res.map((n, index) => {
      const stripe = (
        <a
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: 'blue' }}
          href={`https://dashboard.stripe.com/customers?email=${n.email}`}
        >
          Zu Stripe
        </a>
      )
      const ban = (
        <a
          style={{ color: '#FF3517', textTransform: 'uppercase', fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.75', letterSpacing: '0.02857em', cursor: 'pointer', userSelect: 'none', WebkitTapHighlightColor: 'transparent' }}
          onClick={() => { this.handleBanStatus(n, index) }}
        >
          {n.disabled ? 'Entbannen' : 'Bannen'}
        </a>
      )
      const licenses = (
        <a
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: 'blue' }}
          href={`/licenses/${n.displayName}/${n.uid}`}
        >Lizenzen
        </a>
      )

      const contracts = (
        <a
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: 'blue' }}
          href={`/userContracts/${n.displayName}/${n.uid}`}
        >Contracts
        </a>
      )

      const role = n.customClaims ? JSON.stringify(n.customClaims.role ? n.customClaims.role : []) : '[]'
      return {
        tableId: index,
        Id: n.id,
        uid: n.uid,
        stripe: stripe,
        ban: ban,
        licenses,
        contracts,
        PartitionKey: n.PartitionKey,
        Username: n.displayName,
        email: n.email,
        emailVerified: n.emailVerified,
        disabled: n.disabled,
        lastSignInTime: n.metadata.lastSignInTime,
        role,
        coins: '{"TODO": true}', // Number(n.coins),
        subscriptions: '{"TODO": true}', // JSON.stringify(n.subscriptions),
        paywallAccess: '{"TODO": true}', // n.paywallAccess,
        cameraAccess: null // n.cameraAccess
      }
    })

    if (checkLast === true) {
      this.setState({
        users: res.data,
        rows: rowTemp,
        loadingRows: false
      })
    } else {
      this.setState({
        users: res.data,
        rows: rowTemp
      })
    }
  }

  async handleGetCall (check) {
    if (check) {
      this.state.users.splice(0, this.state.users.length)
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: discovery.API_USER + '/list/1000/0',
        minEntrys: undefined,
        handle: this.handleGetUserList
      }
    ]

    getLazyLoadingPageToken(configArray, this.handleMounted)
  }

  render () {
    return (
      <div>
        <HeadText text={this.props.headingText} />
        <ContentMenu
          config={[
            { title: 'Trainertool Sub', onClick: null, overlay: <OverlayTrain />, overlayTitle: 'Trainertool Subscription', overlayAccessTitle: 'Subscription Versenden' }
          ]}
        />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              ref={this.child}
              style={{ whiteSpace: 'normal' }}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              param={this.props.match}
              editColum={EditColum}
              loadingData={this.state.loadingRows}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default User
