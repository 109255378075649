import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'

import OverlayMatchDetail from './overlayImportMatchDetail'
import { getImportMatches } from '../../../api/api-import'

class FormDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      days: 3,
      errorCall: false,
      OverlaySuccess: false,
      data: null,
      loading: false
    }

    this.handleClickOpen = this.handleClickOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleImport = this.handleImport.bind(this)
    this.handleDayfield = this.handleDayfield.bind(this)
    this.handleOverlaySuccess = this.handleOverlaySuccess.bind(this)
    this.handleCloseOverlayClose = this.handleCloseOverlayClose.bind(this)
  }

  componentDidUpdate () {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open })
    }
  }

  handleClickOpen () {
    this.setState({ open: true })
  };

  handleClose () {
    this.setState({
      open: false,
      days: 3,
      OverlaySuccess: false,
      loading: false,
      data: null
    })
    this.props.close()
  };

  async handleImport () {
    const dId = this.props.rowinfo.dId

    if (dId) {
      this.setState({
        loading: true
      })

      await getImportMatches(dId, this.state.days).then(res => {
        if (res.data && res.data.status === 'running') {
          this.setState({
            data: null,
            loading: false
          })
          return
        }
        this.setState({
          data: res.data,
          loading: false
        })
      })
    }
  }

  handleDayfield (event) {
    const temp = event.target.value
    // check of number
    if (!isNaN(temp) /* && temp < 31 */) {
      this.setState({
        days: event.target.value
      })
    }
  }

  handleOverlaySuccess () {
    this.setState({
      OverlaySuccess: true
    })
  }

  handleCloseOverlayClose () {
    this.setState({
      OverlaySuccess: false
    })
  }

  render () {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.state.loading ? this.handleClose : null} aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Matches importieren</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Hier Können Spiele importiert werden für den Club,
            </DialogContentText>
            {this.props.rowinfo ? (
              <DialogContentText>
                {this.props.rowinfo.RowKey}: {this.props.rowinfo.name}
              </DialogContentText>
            ) : null}
            <TextField
              autoFocus
              margin='dense'
              id='day'
              label='Wie viele Tage? Max:30Tage'
              fullWidth
              value={this.state.days}
              onChange={this.handleDayfield}
            />
          </DialogContent>

          {this.state.data ? (
            <DialogContent>
              <Button onClick={this.handleOverlaySuccess} color='primary' variant='contained' className='camera-btn'>
              Weitere Details anzeigen
              </Button>
            </DialogContent>
          ) : null}

          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Abbrechen
            </Button>
            <Button onClick={this.handleImport} color='primary'>
              Importieren
            </Button>
          </DialogActions>

          {this.state.loading ? (
            <div className='loading-overlay-block'>
              <CircularProgress size={100} thickness={3} />
            </div>
          ) : null}
        </Dialog>
        {this.state.OverlaySuccess ? (
          <OverlayMatchDetail club={this.props.rowinfo} data={this.state.data} onCloseOverlay={this.handleCloseOverlayClose} />
        ) : null}
      </div>
    )
  }
}

export default FormDialog
