import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/core';

import { getSubmitButtonOptions } from '@rjsf/utils';
import { postRegionLanguage } from '../../../api/api-region'

import './rjsk.scss'

const schema = {
  title: 'DEUTSCH',
  type: 'object',
  required: ['name','subline', 'info'],
  properties: {
    name: { type: 'string', title: 'Name' },
    subline: { type: 'string', title: 'Untertitle' },
    info: { type: 'string', title: 'Infotext' },
    contact: {
      title: 'Kontakt',
      type: 'object',
      properties: {
        website: {
          title: 'Webseite',
          type: 'string',
        },
        mail: {
          title: 'Email',
          type: 'string',
          format: 'email'
        },
        instagram: {
          title: 'Instagram',
          type: 'string',
        },
        twitter: {
          title: 'Twitter',
          type: 'string',
        },
        facebook: {
          title: 'Facebook',
          type: 'string',
        },
        youtube: {
          title: 'Youtube',
          type: 'string',
        }
      }
    },
    merch: {
      title: 'Merch',
      type: 'object',
      properties: {
        website: {
          title: 'Webseite',
          type: 'string',
        }
      }
    },
    ['ad1:1']: {
      title: 'Ad 1',
      type: 'object',
      properties: {
        link: {
          title: 'Link',
          type: 'string',
        },
        media: {
          title: 'Media',
          type: 'string',
        }
      }
    },
    ['ad1:3']: {
      title: 'Ad 3',
      type: 'object',
      properties: {
        link: {
          title: 'Link',
          type: 'string',
        },
        media: {
          title: 'Media',
          type: 'string',
        }
      }
    }
  }
};

class FormDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      OverlaySuccess: false,
      loading: true,
      langData: {
        de: undefined,
        en: undefined
      },
      lang: 'de',
      regionName: undefined
    }

    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount () {
    if(this.state.regionName !== undefined) {
      this.getJSONFiles()
    }
  }

  getJSON = async function(url, callback, lang) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    xhr.onload = async function() {
      var status = xhr.status;
      if (status === 200) {
        await callback(null, xhr.response, lang);
      } else {
        await callback(status, xhr.response, lang);
      }
    };
    xhr.send();
  };

  getRegionLanguage = (status, res, lang) => {
    if(res) {
      const langData = this.state.langData !== undefined ? {...this.state.langData, [lang]: res} : {}
      this.setState({
        langData,
        loading: false
      })
    } else {
      this.setState({
        loading: false
      })
    }
  }

  getJSONFiles = async () => {
    this.setState({
      loading: true
    })
    await this.getJSON(`https://storage.googleapis.com/aisw-assets/fanpage/langs/enabler/de/${this.state.regionName}.json`, this.getRegionLanguage, 'de')
    await this.getJSON(`https://storage.googleapis.com/aisw-assets/fanpage/langs/enabler/en/${this.state.regionName}.json`, this.getRegionLanguage, 'en')
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.regionName !== this.state.regionName) {
      console.log('drin', this.state.regionName)
      if(this.state.regionName !== undefined) {
        this.getJSONFiles()
      }
    }
  }

  handleClose () {
    this.setState({
      open: false,
      OverlaySuccess: false,
      regionName: undefined,
      langData: {
        de: undefined,
        en: undefined
      },
      lang: 'de'
    })
    this.props.close()
  };

  handleLangSwitch = (shortLang) => {
    this.setState({
      lang: shortLang
    })
  }

  openOverlay = (regionName, rowName) => {
    this.setState({
      open: true,
      regionName: regionName,
      rowName: rowName
    })
  }

  setJSONFile = async (JSONfile, type) => {
    this.setState({
      loading: true
    })

    await postRegionLanguage(this.state.regionName, this.state.lang, JSONfile).then(res => {
      this.setState({
        loading: false
      })
    })

    this.getJSONFiles()
  }

  SubmitButton(props) {
    const { uiSchema } = props;
    const { norender } = getSubmitButtonOptions(uiSchema);
    if (norender) {
      return null;
    }
    return (
      <button type='submit' style={{ width: '100%', height: '34px', border: 'none', background: '#ff6262', cursor: 'pointer', fontSize: '20px', color: '#fff', borderRadius: 40 }}>
        Speichern
      </button>
    );
  }

  render () {
    schema.title = this.state.lang === 'de' ? 'DEUTSCH' : 'ENGLISCH'
    const SubmitButton = this.SubmitButton

    return (
      <div>
        <Dialog open={this.state.open} onClose={this.state.loading ? this.handleClose : null} aria-labelledby='form-dialog-title' fullWidth>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ position: 'absolute', top: '0px', left: '4px', background: '#333333', color:' #fff', padding: '0 12px 2px', borderRadius: '0px 0px 10px 10px'}} >
                {this.state.rowName}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <Button
                  onClick={() => this.handleLangSwitch('de')}
                  color='secondary'
                  variant='contained'
                  className='camera-btn'
                  style={{ backgroundColor: '#fff !important', height: '26px' }}
                >
                  Deutsch
                </Button>
                <Button
                  onClick={() => this.handleLangSwitch('en')}
                  color='secondary'
                  variant='contained'
                  className='camera-btn'
                  disabled={!this.state.langData.de}
                  style={{ backgroundColor: '#fff !important', height: '26px' }}
                >
                  Englisch
                </Button>
              </div>
            </div>
          </DialogContent>
          <DialogContent>
            {this.state.loading ? (
              <div style={{ height: '575px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
                <Form
                 schema={schema}
                 validator={validator}
                 formData={this.state.langData[this.state.lang]}
                 // onChange={log('changed')}
                 onSubmit={(type) => this.setJSONFile(type.formData ,type)}
                 // onError={log('errors')}
                 templates={{ ButtonTemplates: { SubmitButton } }}
               />
            )}
         
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Schließen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default FormDialog
