import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import OverlayCheck from '../../../modules/static/overlayCheck'
import OverlayCheckVr from '../../../modules/static/overlayAlertCheck'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import moment from 'moment'
import DialogRefund from './refundDialog'
import HighlightDownloadOverlay from './highlightDownload'

import { getCamStatus } from '../../../api/api-camera'
import { postMetaId, cloneMatch } from '../../../api/api-video'
import { addPanoramaRenderingForMatch } from '../../../api/api-import'
import { postAispotters } from '../../../api/api-externalcompany'

import '../game.css'

class Info extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      camStatus: false,
      openCreateVrStream: false,
      openDuplicateStream: false,
      openConfirmed: false,
      openAispotters: false,
      aispotterLastRun: false,
      openRefund: false,
      openStopRecOverlay: false,
      downlaodOverlay: false,
      vrSettings: {
        active: false,
        videoType: 'quarterBoxPanorama'
      }
    }
  }

  /**
   *  Dialog OverlayCheck
   */
  onDialogOpen = (value) => {
    this.setState({ open: value })
  };

  componentDidUpdate (prevProps) {
    if (prevProps.rowinfo !== this.props.rowinfo) {
      if (this.props.rowinfo !== undefined) {
        getCamStatus(this.props.rowinfo.swcsID).then((res) => {
          this.setState({ camStatus: res.data.online })
        })

        if (this.props.rowinfo && this.props.rowinfo.aispotterTimestamp) {
          const aispotterTimestamp = moment(this.props.rowinfo.aispotterTimestamp).add(2, 'hours')
          const now = moment(new Date())

          if (now.isSameOrAfter(aispotterTimestamp)) {
            this.setState({
              aispotterLastRun: true
            })
          } else {
            this.setState({
              aispotterLastRun: false
            })
          }
        } else {
          this.setState({
            aispotterLastRun: true
          })
        }
      }
    }
  }

  handleActive (value) {
    let tempInfo = ''
    if (this.props.rowinfo !== undefined) {
      tempInfo = this.props.rowinfo
    }

    var editMatch = {
      PartitionKey: String(tempInfo.PartitionKey),
      RowKey: String(tempInfo.RowKey),
      active: value
    }

    postMetaId(editMatch, tempInfo.RowKey).then(() => {
      this.setState({
        openSnackbar: true,
        snackbarMessage: `${editMatch.RowKey} set ACTIVE to ${String(editMatch.active)}`
      })

      this.props.handleMergeRow(editMatch)
    }).catch(() => {
      this.setState({
        openSnackbar: true,
        snackbarMessage: `ERROR with ACTIVE update : ${editMatch.RowKey} `
      })
    })
  }

  async handleActiveOptions (value) {
    let tempInfo = ''
    let msg = ''
    if (this.props.rowinfo !== undefined) {
      tempInfo = this.props.rowinfo
    }

    var editMatch = {
      PartitionKey: String(tempInfo.PartitionKey),
      RowKey: String(tempInfo.RowKey),
      active: value.active,
      state: value.state,
      canceledReason: value.state === 'create' ? '' : undefined
    }

    const text = this.state.openConfirmedOption && !this.state.openConfirmedOption.onClickOption.active ? 'Übertragung abgelehnt' : 'Übertragung bestätigt'

    await postMetaId(editMatch, tempInfo.RowKey).then(() => {
      this.props.handleMergeRow(editMatch)
      msg = 'Erfolgreich - ' + text
    }).catch(() => {
      msg = `ERROR with update : ${editMatch.RowKey}`
    })

    return { msg: msg }
  }

  handleRerendering () {
    this.onDialogOpen(true) // Dialog OverlayCheck
  }

  apiCall (useLabelId, useLatestCalibration) {
    let tempInfo = ''
    if (this.props.rowinfo !== undefined) {
      tempInfo = this.props.rowinfo
    }

    const meta = {
      RowKey: String(tempInfo.RowKey),
      AgentActive: false,
      state: 'Produce clone',
      trys: 0.1,
      uselabels: useLabelId,
      rerender: true,
      use_latest_calibration: useLatestCalibration,
      error: null,
      stopRequested: false,
      zone: null,
      gpuOperation: null
    }

    if (tempInfo.archiveState === 'gridArchived') {
      window.alert('Das Grid des Spiels ' + tempInfo.RowKey + ' ist archiviert. Es muss zuerst in den live zustand gebracht werden. Rerendering aktuell nicht möglich. ')
      return
    }
    if (tempInfo.archiveState === 'deleted') {
      window.alert('Das Grid des Spiels ' + tempInfo.RowKey + ' ist gelöscht. Es kann nicht erneut gerendert werden.')
      return
    }

    if (tempInfo.state === 'done') {
      postMetaId(meta, tempInfo.RowKey)
        .then(() => {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `${meta.RowKey} set rerender:${meta.rerender} and uselabels ${meta.useLabelId}`
          })
        })
        .catch(() => {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `ERROR with update : ${meta.RowKey} `
          })
        }) // APicall to meta
    } else {
      window.alert('Das Spiel ' + tempInfo.RowKey + ' rendert noch! Daher kein rerendern zurzeit möglich')
    }
  }

  apiCallPrio (value) {
    let tempInfo = ''
    if (this.props.rowinfo !== undefined) {
      tempInfo = this.props.rowinfo
    }

    const editMatch = {
      PartitionKey: String(tempInfo.PartitionKey),
      RowKey: String(tempInfo.RowKey),
      priority: String(value)
    }

    postMetaId(editMatch, tempInfo.RowKey)
      .then(() => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: `${editMatch.RowKey} set to ${editMatch.priority}`
        })

        this.props.handleMergeRow(editMatch)
      })
      .catch(() => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: `ERROR with update : ${editMatch.RowKey} `
        })
      }) // APicall
  }

  apiCallReset (event) {
    /* let tempInfo = '';
    if (this.props.rowinfo !== undefined) {
      tempInfo = this.props.rowinfo;
    }

    const bundle = {
      RowKey: String(tempInfo.RowKey),
      error: ''
    };

    const meta = {
      RowKey: String(tempInfo.RowKey),
      error: ''
    }; */
    // let jsonBundle = JSON.stringify(bundle); // Object to JSON
    // let jsonMeta = JSON.stringify(meta); // Object to JSON#
    // postScheduleBundle(jsonBundle, getCookieLog()); // APicall to bundle
    // postScheduleMeta(jsonMeta, getCookieLog()); // APicall to meta
    // this.props.notification(event, 'Erfolg', 'Fehler wurden zurückgestezt', 'success', 'tc');
  }

  handleChange = (name) => (event) => {
    switch (name) {
      case 'false': // game setActvie
        this.handleActive(true)
        break
      case 'true': // game setDeactivated
        this.handleActive(false)
        break
      case 'rerender': // start game rerendering
        this.handleRerendering()
        break
      case 'delay': // open Delay Graph
        this.props.openDelay()
        break
      case 'resetError': // reset error
        this.apiCallReset(event)
        break
      default:
        break
    }
  };

  onOpenDuplicateStream () {
    this.setState({
      openDuplicateStream: true
    })
  }

  onOpenConfirmed = (options) => {
    this.setState({
      openConfirmed: true,
      openConfirmedOption: options
    })
  }

  onCloseConfirmed = () => {
    this.setState({
      openConfirmed: false,
      openConfirmedOption: undefined
    })
  }

  onCloseRefund () {
    this.setState({
      openRefund: false
    })
  }

  onOpenRefund () {
    this.setState({
      openRefund: true
    })
  }

  onOpenStopRec () {
    this.setState({
      openStopRecOverlay: true
    })
  }

  onCloseStopRec () {
    this.setState({
      openStopRecOverlay: false
    })
  }

  onCloseDuplicateStream () {
    this.setState({
      openDuplicateStream: false
    })
  }

  onOpenCreateVrStream () {
    this.setState({
      openCreateVrStream: true
    })
  }

  onCloseCreateVrStream () {
    this.setState({
      openCreateVrStream: false
    })
  }

  onOpenAispotter () {
    this.setState({
      openAispotters: true
    })
  }

  onCloseAispotter () {
    this.setState({
      openAispotters: false
    })
  }

  onOpenOverlayHighlight () {
    this.setState({
      downlaodOverlay: true
    })
  }

  onCloseOverlayHighlight () {
    this.setState({
      downlaodOverlay: false
    })
  }

  async onAcceptCreateVrStream () {
    const massage = await addPanoramaRenderingForMatch(this.props.rowinfo.RowKey, this.state.vrSettings)
      .then((itm) => {
        if (itm.status === 200) {
          this.props.resetData()
          this.setState({
            vrSettings: {
              active: false,
              videoType: 'quarterBoxPanorama'
            }
          })
          return { msg: 'Erfolgreich - Die Panorama aufnahme wird neu gerendert unter der ID ' + itm.data.RowKey }
        } else {
          return { msg: 'ERROR - Es ist ein Fehler aufgetreten.' }
        }
      })
      .catch(() => {
        return { msg: 'ERROR - Es ist ein Fehler aufgetreten.' }
      })
    return massage
  }

  async onAcceptDuplicateStream () {
    const massage = await cloneMatch(this.props.rowinfo.RowKey)
      .then((itm) => {
        if (itm.data.RowKey) {
          this.props.resetData()
          return { msg: 'Erfolgreich - Kopie erstellt ' + itm.data.RowKey }
        } else {
          return { msg: 'ERROR - Es ist ein Fehler aufgetreten.' }
        }
      })
      .catch((error) => {
        return { msg: 'ERROR - Es ist ein Fehler aufgetreten.' + error }
      })
    return massage
  }

  async onAcceptAispotters () {
    const body = {
      homeTeam: this.props.rowinfo.clubAName,
      awayTeam: this.props.rowinfo.clubBName,
      startTime: this.props.rowinfo.startTime,
      matchID: String(this.props.rowinfo.RowKey),
      m3u8: this.props.rowinfo.userStream
    }

    // check body
    if (!body.homeTeam || !body.awayTeam || !body.startTime || !body.matchID || !body.m3u8) {
      return { msg: 'ERROR - Wichtige daten "clubAName, clubBName, startTime, RowKey oder userStrem" fehlen scheinbar bei diesem Match' }
    }

    const massage = await postAispotters(body)
      .then((res) => {
        if (res.status === 200) {
          this.props.resetData()
          return { msg: 'Erfolgreich' }
        } else {
          return { msg: 'ERROR - Es ist ein Fehler aufgetreten.' }
        }
      })
      .catch((err) => {
        return { msg: 'ERROR - Es ist ein Fehler aufgetreten. ' + err }
      })
    return massage
  }

  handleCloseSnackbar = (event) => {
    this.setState({ openSnackbar: false })
  };

  handleVrStreamSettings = (name, value) => {
    const vrSettings = this.state.vrSettings

    vrSettings[name] = value

    this.setState({
      vrSettings: vrSettings
    })
  }

  handleVrStreamSettings2 = (event) => {
    const vrSettings = this.state.vrSettings

    vrSettings.videoType = event.target.value

    this.setState({
      vrSettings: vrSettings
    })
  };

  vrSettingOverlay = () => {
    return (
      <div>
        <form>
          <label>
            soll die aufnahme auf aktiv gestellt sein?
            <br />
            <input
              name='isActive'
              type='checkbox'
              checked={this.state.vrSettings.active}
              onChange={() => this.handleVrStreamSettings('active', !this.state.vrSettings.active)}
            />
          </label>
          <br />
          <br />
          <label>
            videoType?
            <br />
            <select value={this.state.vrSettings.videoType} onChange={this.handleVrStreamSettings2}>
              <option value='quarterBoxPanorama'>quarterBoxPanorama</option>
              <option value='panorama'>panorama</option>
            </select>
          </label>
        </form>
      </div>
    )
  }

  /*
    confirmFromImporter: 'Übertragung bestätigen',
    declineFromImporter: 'Übertragung ablehnen',
    cancelVideo: 'Absagen',
    revokeCancellation: 'Wieder ankündigen',
    activate: 'Aktivieren',
    deactivate: 'Deaktivieren',
  */

  hasVideo = (video) => (
    video.vrStream ||
      video.userStream ||
      video.gridStream ||
      video.userStreamAdaptive ||
      video.view_userStream ||
      video.view_gridStream
  )

  getRecordingStatus = (video) => {
    let option
    const now = new Date()
    const tenMinutesInMilliseconds = 10 * 60 * 1000
    const lastActivateableStartingTime = new Date(now.getTime() + tenMinutesInMilliseconds)
    const videoCanStillBeDeActivated = new Date(video.startTime) > lastActivateableStartingTime

    if (videoCanStillBeDeActivated) {
      if (video.state === 'created') {
        option = { text: 'Übertragung ablehnen', onClickOption: { state: 'done', active: false } }
      } else if (video.state === 'done') {
        option = { text: 'Übertragung bestätigen', onClickOption: { state: 'created', active: true } }
      }
    }

    if (option === undefined) {
      return (
        <Button disabled color='primary' variant='contained' className='game-btn'>
          Übertragung gelaufen
        </Button>
      )
    }
    return (
      <Button color='primary' variant='contained' onClick={() => this.onOpenConfirmed(option)} className='game-btn'>
        {option.text}
      </Button>
    )
    /* return (
      // this.onOpenConfirmed(option)
      <Button color='primary' variant='contained' onClick={() => this.handleActiveOptions(option)} className='game-btn'>
        {option.text}
      </Button>
    ) */
  }

  handleStopRecording = async () => {
    let msg = ''
    const body = {
      RowKey: String(this.props.rowinfo.RowKey),
      // state: 'Eliminate Clone',
      stopRequested: true
    }
    // TODO update video

    await postMetaId(body, String(this.props.rowinfo.RowKey)).then(res => {
      if (res.status === 200) {
        // this.props.resetData()
        msg = 'Aufnahme Erfolgreich beendet'
      } else {
        msg = 'Fehler - Aufnahme konnte nicht beendet werden'
      }
    }).catch(err => {
      msg = 'Error - Aufnahme konnte nicht beendet werden - ' + err
    })

    return { msg: msg }
  }

  render () {
    let tempInfo = ''
    if (this.props.rowinfo !== undefined) {
      tempInfo = this.props.rowinfo
    }

    let tempError
    if (tempInfo.bundle !== undefined) {
      tempError = tempInfo.bundle.error
    }

    return (
      <Paper className='game-layout'>
        <div className='layout-head'>INFO</div>
        {this.props.rowinfo !== undefined ? (
          <div>
            <div className='layout-body'>
              <div className='body-infoline'>Aktuelles Spiel: {tempInfo.RowKey}</div>

              {tempError !== undefined ? (
                <div className='body-infoline infoline-error'>Error "{tempError}" aufgetreten</div>
              ) : null}

              <div className='body-infoline'>State: {tempInfo.state}</div>
              <div className='body-infoline'>Camera: {tempInfo.swcsID}</div>
              <div className='body-infoline'>Storage: {tempInfo.storage}</div>
              <div className='body-infoline'>Klon Ip: {tempInfo.cloneIp}</div>
              {tempInfo.userStream ? (
                <div className='body-infoline'>
                  Video Url:{' '}
                  <a href={tempInfo.userStream} target='blank'>
                    {tempInfo.userStream}
                  </a>
                </div>
              ) : null}
              {tempInfo.gridStream ? (
                <div className='body-infoline'>
                  Grid Url:{' '}
                  <a href={tempInfo.gridStream} target='blank'>
                    {tempInfo.gridStream}
                  </a>
                </div>
              ) : null}
              <div className='body-infoline'>
                Zum{' '}
                <a href={'https://www.fussball.de/spiel/-/spiel/' + tempInfo.did} target='blank'>
                  Spiel
                </a>
                {' / '}
                <a href={tempInfo.clubAclubUrl} target='blank'>
                  Club(A)
                </a>
                {' / '}
                <a href={tempInfo.clubBclubUrl} target='blank'>
                  Club(B)
                </a>
              </div>
              <div className='body-infoline' style={{ paddingTop: '6px' }}>
                Kamera Status: <span style={{ margin: '0 0 -4px 0' }} className={this.state.camStatus ? 'infobox-dot dot-ok-fill' : 'infobox-dot dot-bad-fill'} />
              </div>
            </div>

            <div>
              <Grid
                container
                spacing={0}
                style={{
                  textAlign: 'center',
                  paddingBottom: '4px',
                  marginBottom: '4px',
                  borderBottom: 'solid 1px #123'
                }}
              >
                <Grid item xs={12} sm={12}>
                  Priority:
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '100%' }}
                    className='btn'
                    onClick={this.apiCallPrio.bind(this, 0)}
                  >
                    {' '}
                    0{' '}
                  </Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '100%' }}
                    className='btn'
                    onClick={this.apiCallPrio.bind(this, 1)}
                  >
                    {' '}
                    1{' '}
                  </Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '100%' }}
                    className='btn'
                    onClick={this.apiCallPrio.bind(this, 2)}
                  >
                    {' '}
                    2{' '}
                  </Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '100%' }}
                    className='btn'
                    onClick={this.apiCallPrio.bind(this, 3)}
                  >
                    {' '}
                    3{' '}
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div>
              {tempInfo.active === false ? (
                <Button color='primary' variant='contained' onClick={this.handleChange('false')} className='game-btn'>
                  Spiel Aktivieren
                </Button>
              ) : (
                <Button color='primary' variant='contained' onClick={this.handleChange('true')} className='game-btn'>
                  Spiel Deaktiveren
                </Button>
              )}

              <Button color='primary' variant='contained' onClick={this.handleChange('rerender')} className='game-btn'>
                Spiel rerendern
              </Button>
              <Button disabled={this.props.rowinfo.state === 'created'} color='primary' variant='contained' onClick={this.handleChange('delay')} className='game-btn'>
                Verzögerung
              </Button>

              <Button disabled={this.props.rowinfo.state !== 'done'} color='primary' variant='contained' onClick={() => this.onOpenOverlayHighlight()} className='game-btn'>
                Download
              </Button>
              {/* <Button disabled={this.props.rowinfo.state !== 'done'} color='primary' variant='contained' onClick={() => window.open('https://convert.soccerwatch.tv/' + this.props.rowinfo.RowKey, '_blank')} className='game-btn'>
                Download
              </Button> */}
              <Button disabled={Boolean(this.props.rowinfo.vrStream) || this.props.rowinfo.videoType === 'panorama'} color='primary' variant='contained' onClick={() => { this.onOpenCreateVrStream() }} className='game-btn'>
                VR Stream erzeugen
              </Button>
              <Button disabled={this.props.rowinfo.state !== 'Mission running'} color='primary' variant='contained' onClick={() => { this.onOpenAispotter() }} className='game-btn'>
                aispotters
              </Button>
              <Button color='primary' variant='contained' onClick={() => { this.onOpenDuplicateStream() }} className='game-btn'>
                Duplizieren
              </Button>

              <Button color='primary' variant='contained' onClick={() => { this.onOpenRefund() }} className='game-btn'>
                Paywall
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={this.onOpenStopRec.bind(this)}
                className='game-btn'
                disabled={this.props.rowinfo.state === 'done' || this.props.rowinfo.state === 'created'}
              >
                Aufnahme Stoppen
              </Button>

              {this.getRecordingStatus(tempInfo)}

            </div>
          </div>
        ) : null}
        <OverlayCheck
          open={this.state.open}
          click={this.onDialogOpen}
          check={this.apiCall.bind(this)}
          useLatestCalibration={tempInfo ? tempInfo.use_latest_calibration : false}
          title=''
          text={
            'Möchtest du das Spiel ' +
            tempInfo.RowKey +
          ' wirklich neu rendern? Der bisherige Userstream geht dabei verloren.'
          }
          labelId={tempInfo.uselabels || 0}
        />

        <OverlayCheckVr
          open={this.state.openCreateVrStream}
          onClose={this.onCloseCreateVrStream.bind(this)}
          onAccept={this.onAcceptCreateVrStream.bind(this)}
          title='VR Stream erzeugen'
          text={this.vrSettingOverlay()/* 'Möchtest du das Spiel ' + tempInfo.RowKey + ' als VR Stream erzeugen?' */}
          // disabled={this.props.rowinfo}
          disabledText='gridStream nicht vorhanden, daher kann zum jetzigen Zeitpunkt kein VR Stream erzeugt werden. Es kann sein das diese Aufnahme noch nicht gestartet ist und deswegen kein gridStream vorhanden ist'
        />

        <OverlayCheckVr
          open={this.state.openStopRecOverlay}
          onClose={this.onCloseStopRec.bind(this)}
          onAccept={this.handleStopRecording}
          title='Aufnahme Sofort Stoppen'
          text={'Möchtest du das Spiel ' + tempInfo.RowKey + ' wirklich jetzt stoppen?'}
        />

        <OverlayCheckVr
          open={this.state.openDuplicateStream}
          onClose={this.onCloseDuplicateStream.bind(this)}
          onAccept={this.onAcceptDuplicateStream.bind(this)}
          title='Aufnahme Duplizieren'
          text={'Möchtest du das Spiel ' + tempInfo.RowKey + ' duplizieren?'}
        />

        <OverlayCheckVr
          open={this.state.openConfirmed}
          onClose={this.onCloseConfirmed}
          onAccept={() => this.handleActiveOptions(this.state.openConfirmedOption.onClickOption)}
          title={this.state.openConfirmedOption && !this.state.openConfirmedOption.onClickOption.active ? 'Übertragung ablehnen' : 'Übertragung bestätigen'}
          text={this.state.openConfirmedOption && !this.state.openConfirmedOption.onClickOption.active ? 'Möchtest du die Übertragung ' + tempInfo.RowKey + ' ablehnen' : 'Möchtest du die Übertragung ' + tempInfo.RowKey + ' bestätigen'}
        />

        <OverlayCheckVr
          open={this.state.openAispotters}
          onClose={this.onCloseAispotter.bind(this)}
          onAccept={this.state.aispotterLastRun ? this.onAcceptAispotters.bind(this) : undefined}
          title='AISPotters Spiel analysieren'
          text={this.state.aispotterLastRun ? 'Möchtest du das Spiel ' + tempInfo.RowKey + ' von AISpotters analysieren lassen?' : 'Momentan nicht möglich, es sind noch keine 2 stunden her wo dieses Spiel angestoßen wurde'}
        />

        <HighlightDownloadOverlay
          open={this.state.downlaodOverlay}
          onClose={this.onCloseOverlayHighlight.bind(this)}
          matchId={tempInfo.RowKey}
        />

        <DialogRefund
          open={this.state.openRefund}
          onClose={this.onCloseRefund.bind(this)}
          onAccept={undefined}
          title='Paywall Zahlungen'
          matchId={tempInfo.RowKey}
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={this.state.openSnackbar}
          onClose={this.handleCloseSnackbar}
          autoHideDuration={2000}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id='message-id'>{this.state.snackbarMessage}</span>}
        />
      </Paper>
    )
  }
}

export default Info
