import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab';

import { videoTypeOnlySport } from '../../../dropdownValues'

import './validContext.scss'

class ValidContext extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownValue: 'sport',
      dropdownSport: 'soccer',
      inputfield: ''
    }
  }

  handleRemoveContext = (value, context) => {
    const tempArray = JSON.parse(this.props.value).filter(itm => itm.value !== value || itm.context !== context)
    this.props.handleChange(this.props.row.name, tempArray)
  }

  handleChangeDropdown = event => {
    const value = event.target.value === 'undefined' ? undefined : event.target.value
    this.setState({
      dropdownValue: value
    })
  }

  handleChangeDropdownSport = event => {
    const value = event.target.value === 'undefined' ? undefined : event.target.value
    this.setState({
      inputfield: value
    })
  }

  handleAddContext = () => {
    if(this.state.inputfield.trim().length > 0) {
      const newValidContext = JSON.parse(this.props.value)
      newValidContext.push({ context: this.state.dropdownValue ,value: this.state.inputfield })
      // reset option
      this.setState({
        // dropdownValue: 'sport',
        inputfield: ''
      })
      this.props.handleChange(this.props.row.name, newValidContext)
    }
  }

  handleInput = (event) => {
    this.setState({
      inputfield: event.target.value
    })
  }

  render () {
    const dropdownTypes = [{
      value: 'sport', label: 'sport'
    },
    {
      value: 'clubId', label: 'clubId'
    },
    {
      value: 'region', label: 'region'
    },
    {
      value: 'matchId', label: 'matchId'
    }]

    return (
      <div>
        <div className='textField-label' style={{ paddingBottom: '5px' }}>{this.props.row.title}</div>
          <div className='valid-input'>
            <TextField
              id='select-currency-native'
              select
              className='textField-table-dropdowns'
              // label={props.label}
              value={this.state.dropdownValue}
              onChange={this.handleChangeDropdown}
              SelectProps={{
                native: true
              }}
              style={{ width: '120px' }}
              margin='none'
            >
              {dropdownTypes.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            {this.state.dropdownValue === 'sport' ? (
              <TextField
                select
                value={this.state.inputfield}
                onChange={this.handleChangeDropdownSport}
                SelectProps={{
                  native: true
                }}
                margin='none'
                style={{ width: '65%'}}
              >
                {videoTypeOnlySport.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            ) : (
              <TextField
              value={this.state.inputfield}
              onChange={this.handleInput}
              SelectProps={{
                native: true
              }}
                margin='none'
              />
            )}
            
            <Fab onClick={() => this.handleAddContext()} className='btn' color='primary' variant='contained' size="small" style={{ width: '48px', height: '34px'}}>
             +
            </Fab>
          </div>
        <div className='valid-content'>
          {JSON.parse(this.props.value).map((content, i) => {
            return (
              <div key={i} className='content-itm'>
                <div className='itm-content'>
                  <div className='content-head'>{content.context}</div>
                  <div className='content-text'>{content.value}</div>
                </div>
                <div className='itm-delete' onClick={() => this.handleRemoveContext(content.value, content.context)}>x</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default ValidContext
