import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import OverlaySuccess from './overlaySuccess'
import { getImportClub } from '../../../api/api-import'

class FormDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      clubId: undefined,
      idError: false,
      errorCall: false,
      OverlaySuccess: false,
      data: null
    }

    this.handleClickOpen = this.handleClickOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleImport = this.handleImport.bind(this)
  }

  componentDidUpdate () {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open })
    }
  }

  handleClickOpen () {
    this.setState({ open: true })
  };

  handleClose () {
    this.setState({
      open: false,
      clubId: undefined,
      idError: false,
      OverlaySuccess: false
    })
    this.props.close()
  };

  handleImport () {
    const id = this.state.clubId

    if (id !== undefined && id !== '') {
      id.trim()

      getImportClub(id).then((res) => {
        if (res.data) {
          this.setState({
            open: false,
            clubId: undefined,
            idError: false,
            OverlaySuccess: true,
            data: res.data
          })
          this.props.close()
        }
      })
    } else {
      this.setState({
        idError: true
      })
    }
  }

  handleIdTextfield (event) {
    this.setState({
      clubId: event.target.value
    })
  }

  render () {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Club importieren</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Hier Können Vereine basierend auf der ID aus Fussball.de angelegt werden.
            </DialogContentText>
            <div style={{ padding: '10px', backgroundColor: '#eaeaea', margin: '10px 0', color: 'rgb(152, 152, 152)', fontSize: '12px' }}>
              Link: http://www.fussball.de/verein/bsv-beeck-05-niederrhein/-/id/00ES8GN8VS00002MVV0AG08LVUPGND5I#!/
              <br /><br />
              ID: 00ES8GN8VS00002MVV0AG08LVUPGND5I
            </div>
            {this.state.idError ? (
              <div style={{ padding: '10px', backgroundColor: 'rgb(255, 170, 170)', margin: '10px 0', fontSize: '12px' }}>
              Die ID muss angegeben werden!
              </div>
            ) : null}
            <TextField
              autoFocus
              margin='dense'
              id='clubId'
              label='Club ID von Fussball.de'
              fullWidth
              onChange={this.handleIdTextfield.bind(this)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Abbrechen
            </Button>
            <Button onClick={this.handleImport} color='primary'>
              Importieren
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.OverlaySuccess ? (
          <OverlaySuccess data={this.state.data} />
        ) : null}
      </div>
    )
  }
}

export default FormDialog
