import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'

rax.attach()

export const getMatchDelay = async (streamName, id) => {
  return axios.get(discovery.API_DELAY + '/offset/' + id + '/' + streamName + '/json/', { headers: { Authorization: await getAuthorization() } })
}
