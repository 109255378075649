import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: { main: '#FF3517' },
    secondary: { main: '#24CA8E' }
  },
  status: {
    danger: '#FF3517'
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#fff'
      }
    }
  },
  typography: {
    useNextVariants: true
  }
});
