import React from 'react'
import UserSelect from './UserSelect'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import Switch from '@material-ui/core/Switch'
import './userManagement.css'
import AiswSpinner from '../../../../../common/AiswSpinner'
import { deleteUserSubscriptionList, getCheckUserMail, getUsersOfContract, postUserSubscription } from '../../../../../api/api-user'

// type UserManagementLicenseType =
//   | LicenseType.ContractOwnerLicense
//   | LicenseType.CameraLicense
//   | LicenseType.TrainerSubscription
//   | LicenseType.ClubTaggerLicense

// type UserManagementPropTypes = {
//   setOpenMenu: (newIsOpen: boolean) => void
//   openMenu: boolean
//   contractContainer: ContractContainer
//   clubContainer: ClubContainer
//   cityContainer: CityContainer
//   userContainer: UserContainer
// }

// type UserManagementStateTypes = {
//   openUser: UserData | undefined
//   newUser: boolean
//   selectedContract: number
//   waitUserSearch: boolean
//   userNotFound: boolean
//   mail: string
//   checkSelect?: {
//     [key: string]: boolean
//   }
//   contractFormOpen: boolean
// }

export default class UserManagement extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openUser: undefined,
      newUser: false,
      waitUserSearch: false,
      userNotFound: false,
      mail: '',
      contractFormOpen: false,
      loadingData: true,
      user: []
    }
  }

  // ContractOwnerLicense = React.createRef()
  // CameraLicense = React.createRef()
  // TrainerSubscription = React.createRef()
  // ClubTaggerLicense = React.createRef()

  componentDidMount = async () => {
    this.getUsers()
  }

  getUsers = async (loading) => {
    if (loading) {
      this.setState({ loadingData: true })
    }
    const user = await getUsersOfContract(this.props.editRow.RowKey)
    this.setState({ user, loadingData: false })
  }

  handleOpenUser = (user) => {
    this.setState({ openUser: user, checkSelect: undefined, loadingCheckSelect: undefined })
  }

  handleNewUser = (value) => {
    this.setState({ newUser: value, mail: '' })
  }

  renderUserManagement = () => {

    const user = this.state.user

    return (
      <div className='userManagement'>
        <div>
          <UserSelect
            loadingData={this.state.loadingData}
            handleNewUser={this.handleNewUser}
            handleOpenUser={this.handleOpenUser}
            user={user}
          />
        </div>
      </div>
    )
  }

  handleDeleteSubUser = async (license, licenseType) => {
    if (license !== undefined) {
      this.setState({
        loadingCheckSelect: {
          ...this.state.loadingCheckSelect,
          [licenseType]: true
        }
      })

      const switchElement = document.getElementById(licenseType)
      const check = await deleteUserSubscriptionList(license)

      if (check) {
        this.setState({
          checkSelect: { ...this.state.checkSelect, [licenseType]: false },
          loadingCheckSelect: {
            ...this.state.loadingCheckSelect,
            [licenseType]: false
          }
        })
      } else {
        this.setState({
          checkSelect: { ...this.state.checkSelect, [licenseType]: true },
          loadingCheckSelect: {
            ...this.state.loadingCheckSelect,
            [licenseType]: false
          }
        })

        if (!switchElement.checked) {
          switchElement.click()
        }
        // if (this[licenseType]) {
        //   this[licenseType].current.instance.setVal(true)
        // }
      }

      this.getUsers(true)
    }
  }

  handleAddSubUser = async (licenseType) => {
    const switchElement = document.getElementById(licenseType)
    const selectedContract = this.props.editRow
    if (this.state.openUser) {

      this.setState({
        loadingCheckSelect: {
          ...this.state.loadingCheckSelect,
          [licenseType]: true
        }
      })

      const check = await postUserSubscription(
        selectedContract.RowKey,
        licenseType,
        this.state.openUser.RowKey
      )

      if (check) {
        this.setState({
          checkSelect: { ...this.state.checkSelect, [licenseType]: true },
          loadingCheckSelect: {
            ...this.state.loadingCheckSelect,
            [licenseType]: false
          }
        })
      } else {
        this.setState({
          checkSelect: { ...this.state.checkSelect, [licenseType]: false },
          loadingCheckSelect: {
            ...this.state.loadingCheckSelect,
            [licenseType]: false
          }
        })
        if (switchElement.checked) {
          switchElement.click()
        }
        // this[licenseType].current.instance.setVal(false)
      }

      this.getUsers(true)
    }
  }

  handleSwitch = (
    value,
    license,
    licenseType
  ) => {
    if (!value) {
      this.handleDeleteSubUser(license, licenseType)
    } else {
      this.handleAddSubUser(licenseType)
    }
  }

  renderUserRole = (
    licenseType,
    title,
    text,
    // superAdmin
  ) => {
    let license = this.state.openUser.licences.filter((licens) => {
      // Hope this works...
      return licens.type === licenseType
    })

    let switchCheck = license !== undefined && license.length > 0
    if (this.state.checkSelect) {
      switchCheck =
        this.state.checkSelect[licenseType] !== undefined ? this.state.checkSelect[licenseType] : switchCheck
    }

    const loadingPost = this.state.loadingCheckSelect && this.state.loadingCheckSelect[licenseType] ? this.state.loadingCheckSelect[licenseType] : false

    return (
      <div style={{ marginBottom: '10px' }}>
        <div style={{ marginLeft: '-16px', marginBottom: '-12px' }}>
          {title} <br />
          <Switch
            id={licenseType}
            // ref={this[licenseType]}
            checked={switchCheck}
            onChange={(ev) => this.handleSwitch(ev.target.checked, license, licenseType)}
            disabled={loadingPost}
          >
          </Switch>
          {loadingPost && <AiswSpinner className='loadingSpinner-userManagment' />}

        </div>

        <div style={{ fontSize: '14px', color: '#bbbbbb' }}>{text}</div>
      </div>
    )
  }

  handleAddNewUser = () => {
    const user = JSON.parse(JSON.stringify(this.state.user))
    const openUser = JSON.parse(JSON.stringify(this.state.openUser))
    user.push(openUser)
    this.setState({
      user,
      openUser: undefined,
      newUser: false,
      waitUserSearch: false,
      userNotFound: false,
      mail: ''
    })
  }

  renderUserEdit() {

    return (
      <div className='userEdit'>
        <div>
          {this.renderUserRole(
            'ContractOwnerLicense',
            'ContractOwner',
            'Zugriff auf alle Funktionen. Darf neue Nutzer hinzufügen und Rechte verwalten',
          )}
          {this.renderUserRole(
            'CameraLicense',
            'CameraController',
            'Darf Aufnahmen anlegen und aktivieren',
          )}
          {this.renderUserRole(
            'TrainerSubscription',
            'Trainer',
            'Hat Zugriff auf Staige Coach und alle Aufnahmen',
          )}
          {this.renderUserRole(
            'ClubTaggerLicense',
            'ClubTagger',
            'Darf Markierungen im Video hinzufügen',
          )}
          {this.renderUserRole(
            'AdManagerAccess',
            'AdManager',
            'Darf Werbung Bearbeiten'
          )}
          {this.renderUserRole(
            'ClubStreamerLicense',
            'Streamer',
            'Darf Streams mit zusätzlichem Content anlegen.'
          )}
          {this.state.openUser.addNewUser ? (
            <Button
              onClick={() => this.handleAddNewUser()}
              className={'round blue full fullWidth'}
              style={{ color: '#fff', backgroundColor: '#FF3517', height: '36px', padding: '0', lineHeight: '0', marginTop: '20px' }}
            >
              Hinzufügen
            </Button>
          ) : null}
        </div>
        <div>
          <Button
            onClick={() => this.handleOpenUser(undefined)}
            className={'round blue fullWidth'}
            style={{ color: '#fff', backgroundColor: '#FF3517', height: '36px', padding: '0', lineHeight: '0' }}
          >
            Zurück
          </Button>
        </div>
      </div>
    )
  }

  handleSearchMail = async () => {
    this.setState({
      waitUserSearch: true,
      userNotFound: false
    })

    const res = await getCheckUserMail(this.state.mail)
    if (res.uid) {
      const defaultUser = {
        RowKey: res.uid,
        username: res.displayName,
        thumbnail: '',
        licences: [],
        myLicenses: false,
        addNewUser: true
      }

      this.setState({
        waitUserSearch: false,
        openUser: defaultUser
      })
    }

    if (!res.userExists) {
      this.setState({
        waitUserSearch: false,
        userNotFound: true
      })
    }
  }

  handleMailAddress = (value) => {
    this.setState({
      mail: value,
      userNotFound: false
    })
  }

  renderNewUser() {
    return (
      <div className='userEdit'>
        <div>
          <Input
            onChange={(ev) => this.handleMailAddress(ev.target.value)}
            placeholder="Email"
            value={this.state.mail}
            style={{ margin: '0px' }}
            errorMessage={'administration.userManagement.userEdit.mail.errorMessage'}
            valid={!this.state.userNotFound}
          >
            Email
          </Input>
          {this.state.userNotFound ? (
            <div style={{ margin: '-14px 0 20px', fontSize: '12px', color: '#999999' }}>
              Nutzer nicht gefunden
            </div>
          ) : (
            <div style={{ height: '20px' }} />
          )}

          <Button
            onClick={() => this.handleSearchMail()}
            className={'round blue full fullWidth'}
            style={{ color: '#fff', backgroundColor: '#FF3517', height: '36px', padding: '0', lineHeight: '0' }}
            disabled={this.state.mail.length <= 0}
          >
            Suchen
          </Button>
        </div>

        <div>
          <Button
            onClick={() => this.handleNewUser(false)}
            className={'round blue fullWidth'}
            style={{ color: '#fff', backgroundColor: '#FF3517', height: '36px', padding: '0', lineHeight: '0' }}
          >
            Zurück
          </Button>
        </div>
      </div>
    )
  }

  render() {
    console.log(this.state)
    const { openUser, newUser } = this.state

    // if (this.props.openMenu) {
    //   return <Menu setOpenMenu={this.props.setOpenMenu} openMenu={this.props.openMenu} />
    // }
    if (this.state.loadingData && this.state.openUser === undefined) {
      return <AiswSpinner />
    }

    if (openUser) {
      return (
        <>
          <h2 className='username' onClick={() => { this.handleNewUser(undefined) }} style={{ marginTop: 0 }}>{openUser.username}</h2>
          <a className='userid' style={{ color: 'blue' }} href={`/user/uid=${openUser.RowKey}`} target="blank" rel="noopener">{openUser.RowKey}</a>
          {/* <Button></Button> */}
          {this.renderUserEdit()}
        </>
      )
    }

    if (newUser) {
      return (
        <>
          <h2 style={{ marginTop: 0 }}>Nutzer Hinzufügen</h2>
          {this.renderNewUser()}
        </>
      )
    }

    return (
      <>
        <h2 style={{ marginTop: 0 }}>Nutzer</h2>
        {this.renderUserManagement()}
      </>
    )
  }
}
