import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AlertCheck from '../../../static/overlayAlertCheckSimple'

import { getTelemetryCeckupToday } from '../../../../api/api-metric'
import { postTestgame } from '../../../../api/api-import'

import './dashboard.css'

class CheckSpeed extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    return (
      <div>
        <span className={this.props.speedValue >= 30 ? 'infobox-dot dot-ok-fill' : ''} />
        <span className={this.props.speedValue >= 10 && this.props.speedValue < 30 ? 'infobox-dot dot-middle-fill' : ''} />
        <span className={this.props.speedValue >= 0 && this.props.speedValue < 10 ? 'infobox-dot dot-bad-fill' : ''} />
      </div>
    )
  }
}

class CheckBox extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    let checkValue
    if (this.props.value === 'calibrated' || this.props.value === 'Kalibriert') {
      checkValue = <span className='infobox-dot dot-ok-fill' />
    } else if (this.props.value === 'Top ok' || this.props.value === 'Little Off' || this.props.value === 'littleOff') {
      checkValue = <span className='infobox-dot dot-middle-fill' />
    } else if (this.props.value === 'check' || this.props.value === 'TBD' || this.props.value === undefined) {
      checkValue = <span className='infobox-dot dot-bad-fill' />
    } else {
      checkValue = <span style={{ paddingLeft: '6px' }}>?</span>
    }

    return <div>{checkValue}</div>
  }
}

class InfoBox extends Component {
  constructor (props) {
    super(props)

    this.state = {
      uploadSpeedTest: null,
      showTestContainer: false,
      alertCheckOpen: false
    }

    this.handleTestContainer = this.handleTestContainer.bind(this)
    this.handleRunTest = this.handleRunTest.bind(this)
    this.handleRunTestFinal = this.handleRunTestFinal.bind(this)
  }

  componentDidMount () {
    getTelemetryCeckupToday(this.props.rowinfo.RowKey).then(res => {
      if (res.length > 0) {
        let sum = 0
        let counter = 0
        res.filter(n => {
          return n.Type === 'checkup'
        }).map(n => {
          if (n.message.upload_speed) {
            sum = sum + Number(n.message.upload_speed)
            counter++
          }
          return null
        })

        this.setState({
          uploadSpeedTest: (sum / counter).toFixed(2)
        })
      }
    })
  }

  handleTestContainer () {
    this.props.handleContainer('showTestContainer', !this.state.showTestContainer)
    this.setState({
      showTestContainer: !this.state.showTestContainer
    })
  }

  renderLogo () {
    return (
      <Grid item>
        <Grid item xs container direction='column' spacing={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs>
            <Typography style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              <span title={this.props.clubinfo.name}>{this.props.clubinfo.name}</span>
            </Typography>
          </Grid>
          <Grid item xs>
            <img
              title={this.props.clubinfo.name}
              className='logo'
              style={{ maxWidth: '160px' }}
              alt='complex'
              src={this.props.clubinfo.thumbnail}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  handleRunTest () {
    this.setState({
      alertCheckOpen: true
    })
  }

  handleRunTestFinal () {
    const date = new Date()
    const start = new Date(date.getTime() + 3 * 60000).getTime()
    var addTestMatch = [
      {
        date: date.toISOString().split('T')[0],
        startTime: start,
        swcsID: this.props.rowinfo.RowKey,
        duration: parseInt(3)
      }
    ]
    const json = JSON.stringify(addTestMatch) // Object to JSON
    postTestgame(json) // APicall

    setTimeout(
      function () {
        this.props.reload(this.props.rowinfo.RowKey)
        this.setState({
          alertCheckOpen: false
        })
      }.bind(this), 2000
    )
  }

  render () {
    let date = '--:--:----'
    if (this.props.handleMatch !== null && this.props.handleMatch.match !== null) {
      date = new Date(this.props.handleMatch.match.expectedStartTime).toLocaleString()
    }

    return (
      <div>
        <AlertCheck
          titel='Systemtest Starten!'
          text={'Möchtest du wirklich ein Systemtest von 3 Minuten auf Kamera ' + this.props.rowinfo.RowKey + ' durchführen?'}
          open={this.state.alertCheckOpen}
          func={this.handleRunTestFinal}
        />
        <Paper className='telemetry-container'>
          <Grid container spacing={16}>
            <Grid item xl={12} sm container>
              <Grid item xl container direction='column' spacing={16}>
                {this.renderLogo()}

                <Grid item xs>
                  <Typography gutterBottom variant='subtitle1' style={{ borderBottom: '1px solid #e0e0e0' }}>
                    KameraID: {this.props.rowinfo.RowKey}
                  </Typography>
                  <table>
                    <tbody>
                      <tr className='infobox-table tb-padding'>
                        <td>Status:</td>
                        <td className='table-state'>
                          {this.props.rowinfo.systemState} / {this.props.rowinfo.currentTask}
                        </td>
                      </tr>
                      <tr className='infobox-table'>
                        <td>Stichting:</td>
                        <td className='table-state'>
                          <CheckBox value={this.props.rowinfo.stitching} />
                        </td>
                      </tr>
                      <tr className='infobox-table'>
                        <td>Tickets:</td>
                        <td className='table-state'>
                          <CheckBox value='' />
                        </td>
                      </tr>
                      <tr className='infobox-table tb-padding'>
                        <td>Adresse:</td>
                        <td className='table-state'>{this.props.clubinfo.location}</td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl container direction='column' spacing={16}>
              <Grid item xl>
                <Typography
                  gutterBottom
                  variant='subtitle1'
                  style={{ borderBottom: '1px solid #e0e0e0', display: 'flex' }}
                >
                  <span className='table-text'>Upload: </span>
                  <CheckSpeed speedValue={this.state.uploadSpeedTest} />
                </Typography>
                <table>
                  <tbody>
                    <tr className='infobox-table tb-padding'>
                      <td className='table-text'>Speedtest Tag:</td>
                      {this.state.uploadSpeedTest ? (
                        <td>{this.state.uploadSpeedTest} MB/s</td>
                      ) : (
                        <td>Kein wert vorhanden.</td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <Typography gutterBottom variant='subtitle1' style={{ borderBottom: '1px solid #e0e0e0', paddingTop: '16px' }}>
                  Ausgewähltes Spiel:
                </Typography>
                {this.props.handleMatch !== null && this.props.handleMatch.match !== null ? (
                  <table>
                    <tbody>
                      <tr className='infobox-table tb-padding'>
                        <td className='table-text'>Datum:</td>
                        <td>{date}</td>
                      </tr>
                      <tr className='infobox-table tb-padding'>
                        <td className='table-text'>ID:</td>
                        <td>{this.props.handleMatch.match.RowKey}</td>
                      </tr>
                      <tr className='infobox-table  tb-padding'>
                        <td className='table-text'>VerzögerungUL:</td>
                        {this.props.handleMatch.data !== null ? (
                          <td>{this.props.handleMatch.data.message.record_upload.segment.upload_delay}</td>
                        ) : (
                          <td>Kein wert vorhanden.</td>
                        )}
                      </tr>
                      <tr className='infobox-table tb-padding'>
                        <td className='table-text'>Upload:</td>
                        {this.props.handleMatch.data !== null ? (
                          <td>{this.props.handleMatch.data.message.record_upload.segment.upload_rate}</td>
                        ) : (
                          <td>Kein wert vorhanden.</td>
                        )}
                      </tr>
                      <tr className='infobox-table tb-padding'>
                        <td className='table-text'>Aktiv:</td>
                        <td>{String(this.props.handleMatch.match.active)}</td>
                      </tr>
                      <tr className='infobox-table tb-padding'>
                        <td className='table-text'>State:</td>
                        <td>{this.props.handleMatch.match.state}</td>
                      </tr>
                      <tr className='infobox-table tb-padding'>
                        <td className='table-text'>ClubATeam:</td>
                        <td>{this.props.handleMatch.match.clubATeam}</td>
                      </tr>
                      <tr className='infobox-table tb-padding'>
                        <td className='table-text'>ClubBTeam:</td>
                        <td>{this.props.handleMatch.match.clubBTeam}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : <div style={{ paddingTop: '6px', fontSize: '0.875rem' }}>Keine Daten vorhanden.</div>}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ marginTop: '4px' }}>
          <Paper style={{ marginTop: '2px' }}>
            <button className='teleInfoContainerBtn' onClick={this.handleRunTest}>Systemtest Starten</button>
          </Paper>
          <Paper style={{ marginTop: '2px' }}>
            <button className='teleInfoContainerBtn' onClick={this.handleTestContainer}>{this.state.showTestContainer ? 'Systemtest ausblenden' : 'Systemtest anzeigen'}</button>
          </Paper>
        </div>
      </div>
    )
  }
}

export default InfoBox
