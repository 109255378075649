import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import BtnArea from './modules/btnarea'
import Comments from './modules/comments'
import Status from './modules/status'
import Dashboard from './modules/dashboard'
import ContentMenu from '../static/menus/contentMenu'
import SystemUpdateOverlay from './overlay/systemUpdateOverlay'
import discovery from '@soccerwatch/discovery'
import DocketSearch from './script/docketSearch'
// import CameraStatus from './script/script'

import {
  stitchingDown,
  systemStatusDown,
  colorLensClubDown,
  videoType,
  systemLifecircle,
  renderConfiguration,
  outputTemplates,
  hardwarePlatform,
  videoFormat,
  videoProcessing,
  eventType,
  contentType,
  legalContractType
} from '../static/dropdownValues'
import { postCameraSystem, postNewCameraSystem } from '../../api/api-camera'
import { getClubsSystem } from '../../api/api-club'
import { getLazyLoading } from '../../api/helper'
import { Cookies } from 'react-cookie'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'

const config = {
  tableName: 'cameraTable',
  tableUrl: '/cameras/',
  tableDialogTitle: 'Kamera',
  tableToolbar: true,
  tableAdd: false,
  columns: [
    { name: 'uid', title: 'Kamera ID', value: 'number', operation: 'equal', editable: false, tooltip: 'Kamera ID' },
    { name: 'installed', title: 'Installed', value: 'datetime', width: 200, tooltip: 'Installationsdatum der Kamera' },
    // { name: 'hardwarePlatform', title: 'hardwarePlatform', editable: false, width: 200 },
    { name: 'hardwarePlatform', title: 'hardwarePlatform', dropdown: true, dropdownValue: hardwarePlatform, width: 200, tooltip: 'Kamera Version (z.B. K1, K2, K2M)' },
    { name: 'live', title: 'live', value: 'boolean' },
    { name: 'reachable', title: 'reachable', value: 'boolean' },
    { name: 'networkInterface', title: 'Network', value: 'string', editable: false },
    { name: 'commercialReady', title: 'commercialReady', value: 'boolean' },
    { name: 'currentTask', title: 'currentTask', width: 120, disableDialog: true },
    { name: 'lastUpdate', title: 'lastUpdate', width: 150, disableDialog: true },
    { name: 'address', title: 'Adresse', width: 450, tooltip: 'Installationsort der Kamera nicht verändern, hängt mit fußball.de zusammen!' },
    { name: 'ownerClubId', title: 'ownerClubId', tooltip: 'ID des Vereines dem die Kamera gehört' },
    { name: 'name', title: 'Clubname', width: 210, disableDialog: true },
    { name: 'hasSystem', title: 'Club hat System', editable: false },
    { name: 'club_info', title: 'Club Info', dropdown: true, dropdownValue: colorLensClubDown },
    { name: 'color_calibration', title: 'Color Calibration', width: 110, dropdown: true, dropdownValue: colorLensClubDown, tooltip: 'Farbkalibrierung-Status' },
    { name: 'stitching', title: 'Stitching', dropdown: true, dropdownValue: stitchingDown, tooltip: 'Stitching-Status' },
    { name: 'lenses', title: 'Linsen', dropdown: true, dropdownValue: colorLensClubDown, tooltip: 'Linsen-Status' },
    { name: 'systemState', title: 'Systemstatus', width: 150, dropdown: true, dropdownValue: systemStatusDown, tooltip: 'Status des Systems' },
    { name: 'lifecircleState', title: 'Systemfunktion', width: 150, dropdown: true, dropdownValue: systemLifecircle },
    { name: 'systemOwner', title: 'Systeminhaber', width: 150 },
    { name: 'connectionKey', title: 'connectionKey', disableDialog: true },
    { name: 'version_services', title: 'swc-update', editable: false },
    { name: 'version_basis_setup', title: 'system-packages', editable: false },
    { name: 'version_recorder', title: 'Rec', editable: false },
    { name: 'latitude', title: 'latitude', tooltip: 'Lat-Koordinaten der Kamera' },
    { name: 'longitude', title: 'longitude', tooltip: 'Lon-Koordinaten der Kamera' },
    { name: 'meanLiveness', title: 'meanLiveness', disableDialog: true },
    { name: 'varianceLiveness', title: 'varianceLiveness', disableDialog: true },
    { name: 'minLiveness', title: 'minLiveness', disableDialog: true },
    { name: 'maxLiveness', title: 'maxLiveness', disableDialog: true },
    { name: 'cameraNetworkPath', title: 'cameraNetworkPath', disableDialog: false },
    { name: 'cameraRecordingMode', title: 'cameraRecordingMode', disableDialog: false },
    { name: 'subCameraCount', title: 'subCameraCount', disableDialog: false },
    { name: 'defaultStateFlow', title: 'defaultStateFlow', disableDialog: false },
    { name: 'livenessSamples', title: 'livenessSamples', disableDialog: true },
    { name: 'notes', title: 'notes', width: 230 },

    { name: 'legalContractType', title: 'Vertragsart', inputFieldWithDropdown: true, dropdownValue: legalContractType, width: 160 },
    { name: 'legalContractOwner', title: 'Vertragseigentümer', width: 230 },
    { name: 'legalContractCountry', title: 'Land', width: 150 },
    { name: 'legalContractState', title: 'Bundesland', width: 150 },

    { name: 'gIotId', title: 'Google IoT ID', width: 230 },
    { name: 'defaultVideoType', title: 'defaultVideoType', width: 150, dropdown: true, dropdownValue: videoType, tooltip: 'VERALTET : Kombination aus Sportart und KI Modus. Nutzt dafür aber jetzt videoFormat,videoProcessing,eventType und contentType' },
    { name: 'defaultWatermark', title: 'defaultWatermark', width: 150, tooltip: 'Standard Wasserzeichen' },
    { name: 'defaultBitrate', title: 'defaultBitrate', value: 'number' },
    { name: 'defaultAdFree', title: 'defaultAdFree', value: 'boolean', tooltip: 'Wenn gesetzt, ist jedes Spiel Standardmäßig OHNE Werbung' },
    { name: 'defaultPlatformIgnore', title: 'defaultPlatformIgnore', value: 'boolean', tooltip: 'Wenn gesetzt, werden die Spiele Standardmäßig nicht auf der Plattform angezeigt' },
    { name: 'defaultBlendingOnServer', title: 'defaultBlendingOnServer', value: 'boolean' },
    { name: 'defaultUseCameraSRT', title: 'defaultUseCameraSRT', value: 'boolean', tooltip: 'Setzt den Übertragungsmodus der Kamera zur KI von HLS auf SRT ( Dabei ist nur noch EINE parallele Übertragung möglich und KEINE Überlappung, delay ist hierbei aber deutlich reduziert ( < 1 Sec von Kamera zu KI ))' },
    { name: 'defaultHideScoreboard', title: 'defaultHideScoreboard', value: 'boolean', tooltip: 'blended das Scoreboard im Video defaultmäßig aus' },
    { name: 'defaultHideScoreBoardHighlight', title: 'defaultHideScoreBoardHighlight', value: 'boolean', tooltip: 'blended das Scoreboard im highlightclip defaultmäßig aus' },
    { name: 'defaultHideInternalLinking', title: 'defaultHideInternalLinking', value: 'boolean', tooltip: 'blended die Verlinkung auf andere Videos für Video/Highlights defaultmäßig aus' },
    { name: 'defaultHideEmbed', title: 'defaultHideEmbed', value: 'boolean', tooltip: 'blended die Einbettungsfunktion im Video defaultmäßig aus' },
    { name: 'defaultOneAdPerSlot', title: 'defaultOneAdPerSlot', value: 'boolean', tooltip: 'Setzt per default die Bannereinblendung auf 1 Anzeige pro 70 Sekunden' },
    { name: 'defaultRenderConfiguration', title: 'defaultRenderConfiguration', width: 200, dropdown: true, dropdownValue: renderConfiguration },
    { name: 'defaultOutputTemplate', title: 'defaultOutputTemplate', width: 200, dropdown: true, dropdownValue: outputTemplates, tooltip: 'Standard-Qualität der Aufnahmen' },
    { name: 'defaultVideoFormat', title: 'defaultVideoFormat', width: 200, dropdown: true, dropdownValue: videoFormat, tooltip: 'Sportart oder Event Type' },
    { name: 'defaultVideoProcessing', title: 'defaultVideoProcessing', width: 200, dropdown: true, dropdownValue: videoProcessing, tooltip: 'Anlieferung des Bildsignals ( K1,K2,K2M , ip cameras, APPS oder externe streams' },
    { name: 'defaultEventType', title: 'defaultEventType', width: 200, dropdown: true, dropdownValue: eventType, tooltip: 'Zu verwendente KI. Sollte dem des eventTypen entsprechen, kann aber abweichen ( VR-Stream (videoProcessing) von Type Handball(eventType) zb.)' },
    { name: 'defaultContentType', title: 'defaultContentType', width: 200, dropdown: true, dropdownValue: contentType, tooltip: 'Art der Aufzeichnung ( Training, Übertragung, Test' },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alle', value: [''] }]
}

class Cameras extends Component {
  constructor(props) {
    super(props)

    this.state = {
      camlist: [],
      clubInfo: [],
      rows: [],
      tempId: 0,
      statusActive: false,
      telemetryActive: false,
      statusId: 1,
      check: false,
      notesOpen: false,
      loadingRows: true
    }

    this.handleTelemetryShow = this.handleTelemetryShow.bind(this)
    this.handleNotesOpen = this.handleNotesOpen.bind(this)
  }

  _isMounted = false;

  handleId = (id) => {
    if (id != null) {
      this.setState({ camlist: [], tempId: id })
    }
  };

  componentDidMount() {
    this._isMounted = true

    // TODO: change this quickFix when a suitable role has been found
    // for security reasons, only certain users are currently admitted
    const cookies = new Cookies()
    if (cookies.get('DuHzzCByRa49A8fpTjqpz2gDW9Y9YTsP') === '49A8fpTj') {
      config.tableAdd = true
    }

    this.getData()
  }

  getData = async () => {
    await getClubsSystem().then((res) => {
      this.setState({ clubInfo: res })
    })
    this.handleGetCall()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleMounted = () => {
    return this._isMounted
  }

  handleGetCall = (check) => {
    if (check) {
      this.state.camlist.splice(0, this.state.camlist.length)
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: discovery.API_CAMERA + '/info/list/',
        minEntrys: undefined,
        handle: this.handleGetCameraList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  handleGetCameraList = (res, checkLast) => {
    let tempLegalContractType = legalContractType
    res.sort((a, b) => {
      return a.RowKey - b.RowKey
    }).map((row, index) => {
      if (row.legalContractType) {
        let check = true
        for (let index = 0; index < tempLegalContractType.length; index++) {
          const element = tempLegalContractType[index];
          if (element.value === row.legalContractType) {
            check = false
            index = tempLegalContractType.length
          }
        }

        if (check) {
          tempLegalContractType = [...tempLegalContractType, { value: row.legalContractType, label: row.legalContractType }]
        }
      }

      row.tableId = index
      row.RowKey = parseInt(row.RowKey, 10)
      row.uid = parseInt(row.uid, 10)
      if (row.RowKey !== 'undefined') {
        let address = ''
        if (row.address) {
          address = row.address.replace(/(\r\n|\n|\r)/gm, '')
          if (address.startsWith(' ')) {
            address = address.substr(1)
          }
          if (address.slice(-1) === ' ') {
            address = address.slice(0, -1)
          }
        }
        row.hasSystem = 'Nein'
        row = this.state.clubInfo.map((clubId) => {
          if (Number(row.ownerClubId) === Number(clubId.RowKey)) {
            row.name = clubId.name
            if (clubId.hasSystem === true) {
              row.hasSystem = 'Ja'
            }
          }
          return row
        })
      }

      return row
    })

    for (let index = 0; index < config.columns.length; index++) {
      const element = config.columns[index];
      if (element.name === 'legalContractType') {
        config.columns[index] = { ...config.columns[index], dropdownValue: tempLegalContractType }
      }
    }

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

  handleStatus(bool, id) {
    this.setState({ statusActive: bool, statusId: id })
  }

  handleTelemetry(bool) {
    this.setState({ telemetryActive: bool })
  }

  handleTelemetryShow() {
    this.setState({ telemetryActive: !this.state.telemetryActive })
  }

  handleNotesOpen() {
    this.setState({
      notesOpen: true
    })
  }

  async handleEditColum(data) {
    let checkPostStatus = false
    // SET 'RowKey' to Json, need this for backend
    data.json.uid = String(data.row.uid)

    const json = JSON.stringify(data.json) // Object to JSON
    await postCameraSystem(json).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
      }
    })

    return checkPostStatus
  }

  async handleAddColum(data) {
    let checkPostStatus = false
    const json = data.json
    json.initialized = new Date().toISOString()
    json.installed = new Date().toISOString()
    if (json.version === undefined) {
      json.version = '0.1'
    }

    await postNewCameraSystem(json).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
      }
    })
    return checkPostStatus
  }

  render() {
    if (this.state.tempId !== this.state.statusId) {
      // eslint-disable-next-line
      this.state.statusActive = false
    }

    if (this.state.telemetryActive) {
      return (
        <div>
          <div className='headingText'>
            <a href='/'>Startseite</a> / <span style={{ cursor: 'pointer', color: '#FF3517' }} onClick={this.handleTelemetryShow}>{this.props.headingText}</span> / Dashboard
          </div>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Dashboard rowinfo={this.state.rows[this.state.tempId]} />
            </Grid>
          </Grid>
        </div>
      )
    }

    return (
      <div>
        <HeadText text={this.props.headingText} />
        {/*<DocketSearch />*/}
        {/*<CameraStatus />*/}
        <ContentMenu
          style={{ marginBottom: '24px' }}
          config={[
            { title: 'Systeme Updaten', onClick: null, overlay: <SystemUpdateOverlay allCameras={this.state.rows} />, overlayTitle: 'Systeme Updaten', overlayAccessTitle: 'Alle Systeme Updaten' }
          ]}
          disabled={this.state.rows.length <= 0}
        />
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <BtnArea
              openRunnotes={this.state.notesOpen}
              rowinfo={this.state.rows[this.state.tempId]}
              handleStatus={this.handleStatus.bind(this)}
              handleTelemetry={this.handleTelemetry.bind(this)}
              telemetryState={this.state.telemetryActive}
              statusState={this.state.statusActive}
              notification={this.props.notification}
              resetData={this.componentDidMount.bind(this)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Comments rowinfo={this.state.rows[this.state.tempId]} />
          </Grid>
          {this.state.statusActive ? (
            <Grid item xs={12} sm={12}>
              <Status rowinfo={this.state.rows[this.state.tempId]} />
            </Grid>
          ) : null}

          <Grid item xs={12} sm={12}>
            <Table
              resetData={this.handleGetCall.bind(this)}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              param={this.props.match}
              loadingData={this.state.loadingRows}
              handleCreateRow={this.handleAddColum}
              handleEditRow={this.handleEditColum}
              handleNotesOpen={this.handleNotesOpen}
              csvExportCamera={true}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Cameras
