import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getCamSpeedTest } from '../../../../api/api-camera'

class SpeedTest extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: this.props.speedTest,
      camId: undefined,
      loading: true,
      speedTestDetails: undefined
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
      loading: true,
      error: false,
      errorMsg: undefined
    })
    this.setSpeedTest(this.props.camId)
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  setSpeedTest = (camId) => {
    getCamSpeedTest(camId).then((res) => {
      if(res.data.success) {
        if(res.data.detail.speedtest === 'finished') {
          this.setState({
            speedTestDetails: res.data.detail,
            loading: false
          })
        } else if ((res.data.detail.speedtest === 'is_running') || (res.data.detail.speedtest === 'start')) {
          setTimeout(
            () => this.setSpeedTest(camId),
            5000
          );
         
        } else {
          this.setState({
            error: true,
            errorMsg: 'Error',
            loading: false
          })
        }
      } else {
        this.setState({
          error: true,
          errorMsg: res.data.detail,
          loading: false
        })
      }
    })
  }

  render () {
    return (
      <div className='camera-btn-heigh'>
        {this.props.disabled === true ? (
          <Button
            onClick={this.handleClickOpen}
            color='primary'
            variant='contained'
            disabled
            className='camera-btn camera-btn-disable'
          >
            {this.props.btnname}
          </Button>
        ) : (
          <Button onClick={this.handleClickOpen} color='primary' variant='contained' className='camera-btn'>
            {this.props.btnname}
          </Button>
        )}
        <Dialog 
          maxWidth={'xs'}
          fullWidth
          disableBackdropClick
          open={this.state.open}
          onClose={this.handleClose}
        >
          <DialogTitle>Speedtest at {this.props.camId}</DialogTitle>
          <DialogContent>
            <div style={{ textAlign: 'center'}}>
              {!this.state.error ? (
                this.state.loading ? (
                  <div>
                    speedtest is running
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
                      <CircularProgress />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      result
                    </div>
                    <div style={{ fontSize: '28px'}}>{this.state.speedTestDetails.upload_speed_mbit} Mbit</div>
                  </div>
                )
              ) : (
                <div>
                  {this.state.errorMsg}
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default SpeedTest
