import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Details from './modules/detailModal'

import { getCitys, setGEOImage } from '../../api/api-city'
import { Dialog, Button, TextField } from '@material-ui/core'

class CityImages extends Component {
  constructor (props) {
    super(props)
    this.state = {
      citys: [],
      filter: '',
      sortByName: true,
      sortOrderDesc: false,
      selectedCity: -1,
      isImageClone: false,
      cityChangeToggle: false,
      error: null,
      imageHash: Date.now()
    }
    this.doBind.bind(this)()
  }

  doBind () {
    this.selectCity = this.selectCity.bind(this)
    this.deselectCity = this.deselectCity.bind(this)
    this.changeFilter = this.changeFilter.bind(this)
    this.toggleByName = this.toggleByName.bind(this)
    this.toggleOrderDesc = this.toggleOrderDesc.bind(this)
    this.setBigImage = this.setBigImage.bind(this)
    this.checkImageClone = this.checkImageClone.bind(this)
    this.closeBigImage = this.closeBigImage.bind(this)
    this.setGeoImage = this.setGeoImage.bind(this)
    this.closeError = this.closeError.bind(this)
  }

  componentDidMount () {
    getCitys().then((citys) => {
      this.setState({ citys })
    })
  }
  toggleByName () {
    const sortByName = !this.state.sortByName
    this.setState({ sortByName })
  }
  toggleOrderDesc () {
    const sortOrderDesc = !this.state.sortOrderDesc
    this.setState({ sortOrderDesc })
  }
  changeFilter (e) {
    const filter = e.target.value
    this.checkImageClone(filter)
    this.setState({ filter })
  }
  checkImageClone (src) {
    if (src.toLowerCase() === 'getcomradecommon') {
      this.setState({ isImageClone: true })
    }
  }
  closeError () {
    this.setState({ error: null })
  }
  setStateAsync (state) {
    return new Promise((resolve, reject) => {
      this.setState(state, () => {
        resolve()
      })
    })
  }
  async setGeoImage (body) {
    await this.setStateAsync({ uploading: true })
    const { citys, selectedCity } = this.state
    // const newCitys = JSON.parse(JSON.stringify(citys))
    const filteredCitys = this.filterCitys(citys)
    const city = filteredCitys[selectedCity]
    let Result
    try {
      Result = await setGEOImage(city.RowKey, body)
    } catch (err) {
      console.error(err)
      return this.setState({ uploading: false, error: err.message })
    }
    if (Result.status === 200) {
      // newCitys[selectedCity] = Result.data
      return this.setState((state) => {
        const updated = state.citys.map((city, i) => {
          if (city.RowKey === Result.data.RowKey) {
            return Result.data
          }
          return city
        })
        return { citys: updated, uploading: false, imageHash: Date.now() }
      })
      // return this.setState({ uploading: false, citys: newCitys })
    }
    this.setState({
      uploading: false,
      error: 'Es ist ein Fehler während des Uploads aufgetreten. Bitte versuchen sie es erneut.'
    })
  }
  selectCity (selectedCity) {
    this.setState({ selectedCity })
  }
  setBigImage (BigImage) {
    this.setState({ BigImage })
  }
  closeBigImage () {
    this.setState({ BigImage: null })
  }
  deselectCity () {
    if (this.state.uploading) {
      return false
    }
    this.setState({ selectedCity: -1 })
  }

  filterCitys () {
    const citys = this.state.filter
      ? this.state.citys.filter((city) => {
        return city.name.toLowerCase().includes(this.state.filter.toLowerCase())
      })
      : this.state.citys
    citys.sort((a, b) => {
      const alpha = this.state.sortByName
      const aProp = alpha ? a.name : new Date(a.Timestamp).getTime()
      const bProp = alpha ? b.name : new Date(b.Timestamp).getTime()
      if (aProp < bProp) {
        return -1
      }
      if (aProp > bProp) {
        return 1
      }
      return 0
    })
    if (this.state.sortOrderDesc) {
      return citys.reverse()
    }
    return citys
  }
  getFullSizeSwUrl (swUrl) {
    const parts = swUrl.split('.')
    parts[parts.length - 2] = `${parts[parts.length - 2]}-4x`
    return parts.join('.')
  }

  getCityCards (citys) {
    return citys.map((city, index) => {
      const _selectCity = () => {
        this.selectCity(index)
      }
      return (
        <Grid key={city.name + index} item xs={6} sm={2}>
          <Card>
            <CardHeader
              title={city.name}
              onClick={_selectCity}
              titleTypographyProps={{ variant: 'subtitle1' }}
              action={
                <IconButton onClick={_selectCity}>
                  <i className='fa fa-image' />
                </IconButton>
              }
            />
            {city.imageUrl && city.imageUrl !== '' ? (
              <CardMedia
                image={`${city.imageUrl}?${this.state.imageHash}`}
                onClick={() => {
                  this.setBigImage(this.getFullSizeSwUrl(city.imageUrl))
                }}
                title={city.name}
                style={{
                  height: 0,
                  paddingTop: '56.25%'
                }}
              />
            ) : (
              <CardContent>
                <i className='fa fa-5x fa-image' />
              </CardContent>
            )}
          </Card>
        </Grid>
      )
    })
  }

  render () {
    const filteredCitys = this.filterCitys(this.state.citys)
    const cityCards = this.getCityCards(filteredCitys)
    const city = this.state.selectedCity > -1 ? filteredCitys[this.state.selectedCity] : false
    return (
      <div>
        <Grid container style={this.state.isImageClone ? altColor : {}} spacing={16}>
          <Grid item xs={12}>
            <HeadText link text={this.props.headingText} />
            <TextField label='Suche' value={this.state.filter} onChange={this.changeFilter} />
            <Button
              size='medium'
              onClick={this.toggleByName}
              className={this.state.sortByName ? 'btn' : ''}
              color='primary'
              variant={this.state.sortByName ? 'contained' : 'outlined'}
            >
              Alphabetisch
            </Button>
            <Button
              size='medium'
              onClick={this.toggleOrderDesc}
              className={this.state.sortOrderDesc ? 'btn' : ''}
              color='primary'
              variant={this.state.sortOrderDesc ? 'contained' : 'outlined'}
            >
              Desc
            </Button>
          </Grid>
          {cityCards}
        </Grid>
        <Dialog fullWidth maxWidth='xl' open={Boolean(city)} onClose={this.deselectCity}>
          {' '}
          {/** TODO: FIX BG COLOR */}
          <Details
            city={city}
            onCloseBtn={this.deselectCity}
            uploading={this.state.uploading}
            setGeoImage={this.setGeoImage}
            setBigImage={this.setBigImage}
            isImageClone={this.isImageClone}
            altColor={altColor}
            imageHash={this.state.imageHash}
            closeError={this.closeError}
            error={this.state.error}
          />
        </Dialog>
        <Dialog fullWidth maxWidth='xl' open={Boolean(this.state.BigImage)} onClose={this.closeBigImage}>
          <Card>
            <CardHeader
              title={this.state.BigImage}
              titleTypographyProps={{ variant: 'subtitle1' }}
              action={
                <IconButton onClick={this.closeBigImage}>
                  <i className='fa fa-close' />
                </IconButton>
              }
            />
            <CardMedia
              component='img'
              src={this.state.BigImage}
              title={this.state.BigImage}
              height='100%'
              // style={{ height: '50%', paddingTop: '56.25%' }}
            />
          </Card>
        </Dialog>
      </div>
    )
  }
}

export default CityImages

const altColor = {
  background:
    'url("https://stmed.net/sites/default/files/communism-wallpapers-28524-2802760.jpg") no-repeat center center fixed'
}
