import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import NoRunnote from './noRunnote'
import RunnotesContainer from './runnotesInProgress'

import { getLastDockets } from '../../../../api/api-metric'

class Runnotes extends Component {
  constructor (props) {
    super(props)

    this.state = {
      notes: [],
      nonotes: [],
      finalnotes: [],
      progressnotes: [],
      readyforinstallnotes: [],
      pendingnotes: [],
      open: {
        noRunnote: false,
        runnotesinprogress: false,
        runnotesready: false,
        runnotespending: false
      }
    }
  }

  componentDidMount () {
    getLastDockets().then((res) => {
      const tempNoNotes = []
      const tempNotes = []
      const tempProgressNotes = []
      const tempReadyforInstallNotes = []
      const tempPendingNotes = []
      res.data.map((n) => {
        if (n.data === null) {
          tempNoNotes.push(n.id)
        } else {
          tempNotes.push(n)
        }
        return null
      })

      // sort notes
      tempNotes.sort((a, b) => {
        const numberA = a.PartitionKey.slice(5, a.PartitionKey.length)
        const numberB = b.PartitionKey.slice(5, b.PartitionKey.length)
        return parseInt(numberA) - parseInt(numberB)
      })

      let filterNotes
      const finalFilter = []

      for (let y = 0; y < tempNotes.length; y++) {
        filterNotes = tempNotes.filter(b => b.PartitionKey === tempNotes[y].PartitionKey).sort((a, b) => { return new Date(b.data.dateCreate) - new Date(a.data.dateCreate) })

        let filterNotesFinal
        for (let i = 0; i < filterNotes.length; i++) {
          const x = filterNotes[i].RowKey.search('montage')
          if (x === 0) {
            filterNotesFinal = filterNotes.slice(0, i + 1)
            y = y + filterNotes.length - 1
            i = filterNotes.length
            filterNotesFinal.reverse()
            finalFilter.push(filterNotesFinal)
          }
        }
      }

      finalFilter.map(n => {
        n.forEach((y, index) => {
          // in progress
          if (!y.data.Decrease_Check) {
            tempProgressNotes.push(y)
          }

          // Ready for install
          if (y.RowKey.search('montage') === 0 && n.length < 2) {
            if (y.data.Decrease_Check === true) {
              const temp = Object.assign({}, y)
              temp.RowKey = 'install-'
              tempReadyforInstallNotes.push(temp)
            }
          }
        })
        return null
      })

      finalFilter.map(n => {
        if (n[n.length - 1].RowKey.search('install-') === 0) {
          const tempN = JSON.parse(JSON.stringify(n[n.length - 1]))

          if (tempN.data.Decrease_Check) {
            tempN.RowKey = 'installAdmin-'
            tempPendingNotes.push(tempN)
          }
        } else if (n[n.length - 1].RowKey.search('deinstall-') === 0) {
          const tempN1 = JSON.parse(JSON.stringify(n[n.length - 1]))

          if (tempN1.data.Decrease_Check) {
            tempN1.RowKey = 'deinstallAdmin-'
            tempPendingNotes.push(tempN1)
          }
        } if (n[n.length - 1].RowKey.search('troubleshooting-') === 0) {
          const tempN2 = JSON.parse(JSON.stringify(n[n.length - 1]))

          if (tempN2.data.Decrease_Check) {
            tempN2.RowKey = 'montage-'
            tempPendingNotes.push(tempN2)
          }
        } else if (n[n.length - 1].RowKey.search('deinstallAdmin-') === 0) {
          const tempN3 = JSON.parse(JSON.stringify(n[n.length - 1]))
          if (tempN3.data.Decrease_Check) {
            if (tempN3.data.Proceed_Approved === 'FREIGEGEBEN') {
              tempN3.RowKey = 'montage-'
            } else {
              tempN3.RowKey = 'troubleshooting-'
            }
            tempPendingNotes.push(tempN3)
          }
        }

        return null
      })

      this.setState({
        nonotes: tempNoNotes,
        notes: res.data,
        finalnotes: tempNotes,
        progressnotes: tempProgressNotes,
        readyforinstallnotes: tempReadyforInstallNotes,
        pendingnotes: tempPendingNotes
      })
    })
  }

  handleOpen (value) {
    let tempName
    switch (value) {
      case '1':
        tempName = 'noRunnote'
        break
      case '2':
        tempName = 'runnotesinprogress'
        break
      case '3':
        tempName = 'runnotesready'
        break
      case '4':
        tempName = 'runnotespending'
        break
      default:
        break
    }

    this.setState({
      open: {
        [tempName]: true
      }
    })
  }

  render () {
    return (
      <div>
        <Paper>
          <div className='layout-head'>Laufzettel</div>
          <div>
            <NoRunnote data={this.state.nonotes} handleOpen={this.handleOpen.bind(this)} id='1' />
            <RunnotesContainer
              open={this.state.open.runnotesinprogress}
              headText='In Bearbeitung'
              data={this.state.progressnotes}
              handleOpen={this.handleOpen.bind(this)}
              id='2'
              cameraClubInfoList={this.props.cameraClubInfoList}
            />
            <RunnotesContainer
              open={this.state.open.runnotesready}
              headText='Bereit für Installation'
              data={this.state.readyforinstallnotes}
              handleOpen={this.handleOpen.bind(this)}
              id='3'
              cameraClubInfoList={this.props.cameraClubInfoList}
            />
            <RunnotesContainer
              open={this.state.open.runnotespending}
              headText='Anstehend'
              data={this.state.pendingnotes}
              handleOpen={this.handleOpen.bind(this)}
              id='4'
              cameraClubInfoList={this.props.cameraClubInfoList}
            />
          </div>
        </Paper>
      </div>
    )
  }
}

export default Runnotes
