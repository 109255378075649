import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'

class ErrorBox extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  handleClick (id) {
    window.open(window.location.origin + '/cameras/uid=' + id, '_blank')
  }

  render () {
    return (
      <div>
        <Paper>
          <div className='layout-head'>{this.props.headText}</div>
          <div className='errorBox'>
            {this.props.data.map((n, index) => {
              return (
                <div key={index} style={{ display: 'flex' }} onClick={this.handleClick.bind(this, n)}>
                  <div className='errorBox-number'>{index + 1}</div>
                  <div className='errorBox-list'>
                    swc
                    {n}
                  </div>
                </div>
              )
            })}
          </div>
        </Paper>
      </div>
    )
  }
}

export default ErrorBox
