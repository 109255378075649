import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  styler,
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  LineChart,
  Resizable

} from 'react-timeseries-charts'
import { TimeSeries, TimeRange } from 'pondjs'
import { getTelemetrySinc, getTelemetryByType } from '../../../../api/api-metric'

import './dashboard.css'

const style = styler([
  { key: 'cpu1', color: '#CA4040' },
  { key: 'cpu2', color: '#9467bd' },
  { key: 'cpu3', color: '#987951' },
  { key: 'cpu4', color: '#CC862A' }
])

const styleTherm = styler([
  { key: 'ao_therm', color: '#CA4040' },
  { key: 'cpu_therm', color: '#CA4040' },
  { key: 'gpu_therm', color: '#54afd8' },
  { key: 'pll_therm', color: '#987951' },
  { key: 'pmic_die', color: '#CC862A' },
  { key: 'tboard_tegra', color: '#ADD8E6' },
  { key: 'tdiode_tegra', color: '#93c000' }
])

const styleMemory = styler([
  { key: 'memory', color: '#C00000' },
  { key: 'memoryused', color: '#ADD8E6' }
])

const styleUpload = styler([
  { key: 'upload_rate', color: '#93c000' },
  { key: 'upload_delay', color: '#CA4040' }
])

class Graph extends Component {
  constructor (props) {
    super(props)

    this.state = {
      id: this.props.id,
      startTime: 0,
      loading: false,
      loadingCircle: false,
      timeRange: null,
      seriesData: {
        cpu: null,
        memory: null,
        upload: null,
        storage: null,
        therm: null
      },
      dataSize: null,
      dataTracker: null,
      dateTrackerDE: null,
      trackerValue: null,
      trackerEvent: null,
      checkup: false,
      checkupText: 'Checkup Anzeigen',
      thermBtn: ['ao_therm', 'cpu_therm', 'gpu_therm', 'pll_therm', 'pmic_die', 'tboard_tegra', 'tdiode_tegra'],
      thermBtnActiv: [
        { name: 'ao_therm', activ: true, color: '#CA4040' },
        { name: 'gpu_therm', activ: true, color: '#54afd8' },
        { name: 'pll_therm', activ: true, color: '#9467bd' },
        { name: 'pmic_die', activ: true, color: '#CC862A' },
        { name: 'tboard_tegra', activ: true, color: '#ADD8E6' },
        { name: 'tdiode_tegra', activ: true, color: '#93c000' }
      ],
      uploadBtn: ['upload_rate', 'upload_delay'],
      uploadBtnActiv: [
        { name: 'upload_rate', activ: true, color: '#93c000' },
        { name: 'upload_delay', activ: true, color: '#CA4040' }
      ]
    }

    this.handleTimeRangeChange = this.handleTimeRangeChange.bind(this)
    this.handleChartResize = this.handleChartResize.bind(this)
    this.handleTrackerChanged = this.handleTrackerChanged.bind(this)
  }

  async componentDidMount () {
    const sincDate = new Date(this.props.row.expectedStartTime)// new Date(this.props.row.expectedStartTime);
    const sincMatchId = this.props.row.RowKey
    const sincCamId = this.props.row.swcsID

    this.setState({ loadingCircle: true })

    getTelemetrySinc(sincDate, sincCamId, sincMatchId).then(res => {
      if (res.length > 0) {
        const tempData = res.filter(n => {
          return n.Type === 'upload'
        })

        this.handleGenerator(tempData)

        this.props.handleMatch({
          data: tempData[tempData.length - 1],
          match: this.props.row
        })
      } else {
        this.setState({ loading: true, loadingCircle: false })
        this.props.handleMatch(null)
      }
    })

    this.setState({
      id: this.props.id
    })
  }

  componentWillUpdate (nextProps, nextState) {
    const sincDate = new Date(nextProps.row.expectedStartTime)
    const sincMatchId = nextProps.row.RowKey
    const sincCamId = nextProps.row.swcsID

    if (nextState.id !== nextProps.id) {
      this.setState({
        seriesData: {
          cpu: null,
          memory: null,
          upload: null,
          storage: null
        },
        id: nextProps.id,
        loading: true,
        loadingCircle: true,
        checkup: false,
        checkupText: 'Checkup Anzeigen'
      })

      getTelemetrySinc(sincDate, sincCamId, sincMatchId).then(res => {
        if (res.length > 0) {
          const tempData = res.filter(n => {
            return n.Type === 'upload'
          })

          this.handleGenerator(tempData)

          this.props.handleMatch({
            data: tempData[tempData.length - 1],
            match: this.props.row
          })
        } else {
          this.setState({ loading: true, loadingCircle: false })
          this.props.handleMatch(null)
        }
      })
    }
  }

  handleGenerator (dataArrayFinal) {
    this.generatedCpu(dataArrayFinal)
    this.generatedRam(dataArrayFinal)
    this.generatedUploadSpeed(dataArrayFinal)
    this.generatedStorage(dataArrayFinal)
    this.generatedTherm(dataArrayFinal)
  }

  generatedStorage (res) {
    const name = 'storage_information'
    const pointList = []
    let maxValue = 0

    const tempRes = res.sort((a, b) => {
      return new Date(a.Timestamp) - new Date(b.Timestamp)
    })

    /// /////////////////////////////////////////////////////////////////////////////////////////////////
    const nameArray = Object.keys(tempRes[tempRes.length - 1].message.storage_information).map(n => {
      return n
    })
    /// /////////////////////////////////////////////////////////////////////////////////////////////////

    tempRes.map((n) => {
      const time = new Date(n.Timestamp).getTime()
      let a = 0
      const b = 0

      if (n.message && n.message[name] !== undefined && time) {
        if (n.message[name][nameArray[0]]) {
          a = n.message[name][nameArray[0]].free
        }

        if (n.message[name][nameArray[1]]) {
          a = n.message[name][nameArray[1]].free
        }

        pointList.push([time, a, b])

        if (maxValue < a) {
          maxValue = a

          if (maxValue < b) {
            maxValue = b
          }
        }
      }
      return null
    })

    const series = new TimeSeries({
      name: name,
      columns: ['time', 'mmcblk0p1_memory', 'mmcblk1p1_memory'],
      points: pointList
    })

    this.setState({
      seriesData: {
        ...this.state.seriesData,
        storage: series
      },
      seriesMax: {
        ...this.state.seriesMax,
        storage: Number(maxValue)
      }
    })
  }

  generatedCpu (res) {
    const name = 'cpu'
    const pointList = []

    const tempRes = res.sort((a, b) => {
      return new Date(a.Timestamp) - new Date(b.Timestamp)
    })

    let tempTime

    tempRes.map((n) => {
      const time = new Date(n.Timestamp).getTime()
      if (n.message && n.message[name] !== undefined && time) {
        if (tempTime + 40000 < time) {
          pointList.push([tempTime + 1000, 0, 0, 0, 0])
          pointList.push([time - 1000, 0, 0, 0, 0])
        }
        pointList.push([
          time,
          n.message[name].cpu_1,
          n.message[name].cpu_2,
          n.message[name].cpu_3,
          n.message[name].cpu_4
        ])
      }

      tempTime = time
      return null
    })

    const series = new TimeSeries({
      name: name,
      columns: ['time', 'cpu1', 'cpu2', 'cpu3', 'cpu4'],
      points: pointList
    })

    const range = new TimeRange(
      new Date(pointList[0][0]),
      new Date(pointList[pointList.length - 1][0])
    )

    this.setState({
      loading: false,
      loadingCircle: false,
      timeRange: range,
      seriesData: {
        cpu: series
      }
    })
  }

  generatedRam (res) {
    const pointList = []
    const name = 'memory_space'
    let maxValue = 0

    const tempRes = res.sort((a, b) => {
      return new Date(a.Timestamp) - new Date(b.Timestamp)
    })

    tempRes.map((n) => {
      const time = new Date(n.Timestamp).getTime()
      if (n.message && n.message[name] !== undefined && time) {
        // pointList.push([time - 100, 0, 0]);
        pointList.push([time, n.message[name].memory_used, n.message[name].memory_used + n.message[name].memory_free])
        // pointList.push([time + 100, 0, 0]);

        if (maxValue < (n.message[name].memory_used + n.message[name].memory_free)) {
          maxValue = Number(n.message[name].memory_used) + Number(n.message[name].memory_free)
        }
      }
      return null
    })

    const series = new TimeSeries({
      name: name,
      columns: ['time', 'memory', 'memoryused'],
      points: pointList
    })

    this.setState({
      seriesData: {
        ...this.state.seriesData,
        memory: series
      },
      seriesMax: {
        ...this.state.seriesMax,
        memory: Number(maxValue.toFixed(0))
      }
    })
  }

  generatedUploadSpeed (res) {
    const name = 'record_upload'
    const pointList = []
    let maxValue = 0

    const tempRes = res.sort((a, b) => {
      return new Date(a.Timestamp) - new Date(b.Timestamp)
    })

    tempRes.map((n) => {
      const time = new Date(n.Timestamp).getTime()
      let index, newTime, timeNumber

      if (n.Type === 'upload' && n.message && n.message[name] !== undefined && time) {
        if (n.message[name].segment.upload_delay) {
          index = n.message[name].segment.upload_delay.lastIndexOf('.')
          newTime = n.message[name].segment.upload_delay.slice(0, index).split(':')
          timeNumber = Number(newTime[0] * 3600) + Number(newTime[1] * 60) + Number(newTime[2])
          pointList.push([time, n.message[name].segment.upload_rate, timeNumber])

          if (maxValue < timeNumber) {
            maxValue = timeNumber
          }
        }
      }
      return null
    })

    const series = new TimeSeries({
      name: name,
      columns: ['time', 'upload_rate', 'upload_delay'],
      points: pointList
    })

    this.setState({
      seriesData: {
        ...this.state.seriesData,
        upload: series
      },
      seriesMax: {
        ...this.state.seriesMax,
        delay: maxValue
      }
    })
  }

  generatedTherm (res) {
    const name = 'thermal_data'
    const pointList = []

    const tempRes = res.sort((a, b) => {
      return new Date(a.Timestamp) - new Date(b.Timestamp)
    })

    tempRes.map((n) => {
      const time = new Date(n.Timestamp).getTime()

      if (n.message && n.message[name] !== undefined && time) {
        pointList.push([
          time,
          n.message[name].ao_therm,
          n.message[name].cpu_therm,
          n.message[name].gpu_therm,
          n.message[name].pll_therm,
          n.message[name].pmic_die,
          n.message[name].tboard_tegra,
          n.message[name].tdiode_tegra
        ])
      }
      return null
    })

    const series = new TimeSeries({
      name: name,
      columns: ['time', 'ao_therm', 'cpu_therm', 'gpu_therm', 'pll_therm', 'pmic_die', 'tboard_tegra', 'tdiode_tegra'],
      points: pointList
    })

    this.setState({
      seriesData: {
        ...this.state.seriesData,
        therm: series
      }
    })
  }

  handleTimeRangeChange (timerange) {
    let editCpu, editMemory, editUpload, editStorage, editTherm

    // Crop Series
    if (this.state.seriesData.cpuOld) {
      editCpu = this.state.seriesData.cpuOld
      editMemory = this.state.seriesData.memoryOld
      editUpload = this.state.seriesData.uploadOld
      editStorage = this.state.seriesData.storageOld
      editTherm = this.state.seriesData.thermOld
    } else {
      editCpu = this.state.seriesData.cpu
      editMemory = this.state.seriesData.memory
      editUpload = this.state.seriesData.upload
      editStorage = this.state.seriesData.storage
      editTherm = this.state.seriesData.therm
    }

    let editCpuCrop = editCpu.crop(timerange)
    let editMemoryCrop = editMemory.crop(timerange)
    let editUploadCrop = null
    let editStorageCrop = editStorage.crop(timerange)
    let editThermCrop = editTherm.crop(timerange)

    if (!this.state.checkup) {
      editUploadCrop = editUpload.crop(timerange)
    }

    if (editCpu.crop(timerange).count() <= 0) {
      editCpuCrop = this.state.seriesData.cpu
      editMemoryCrop = this.state.seriesData.memory
      editUploadCrop = this.state.seriesData.upload
      editThermCrop = this.state.seriesData.therm
    }

    if (editStorage.crop(timerange).count() <= 0) {
      editStorageCrop = this.state.seriesData.storage
    }

    if (editTherm.crop(timerange).count() <= 0) {
      editThermCrop = this.state.seriesData.therm
    }

    // Crop Series End
    this.setState({
      seriesData: {
        cpuOld: editCpu,
        memoryOld: editMemory,
        uploadOld: editUpload,
        storageOld: editStorage,
        thermOld: editTherm,
        cpu: editCpuCrop,
        memory: editMemoryCrop,
        upload: editUploadCrop,
        storage: editStorageCrop,
        therm: editThermCrop
      },
      timeRange: timerange
    })
  };

  handleChartResize (width) {
    this.setState({ dataSize: width })
  };

  handleTrackerChanged (t) {
    const parseDate = Date.parse(t)
    const dateNow = new Date(parseDate)

    if (t) {
      const cpuTime = this.state.seriesData.cpu.atTime(t)
      const thermTime = this.state.seriesData.therm.atTime(t)
      const storageTime = this.state.seriesData.storage.atTime(t)
      const memoryTime = this.state.seriesData.memory.atTime(t)
      let uploadTime
      let uploadRate, uploadDelay

      if (!this.state.checkup) {
        uploadTime = this.state.seriesData.upload.atTime(t)
        uploadRate = uploadTime.get('upload_rate')
        uploadDelay = uploadTime.get('upload_delay')
      }

      const eventTime = new Date(cpuTime.begin().getTime() + (cpuTime.end().getTime() - cpuTime.begin().getTime()) / 2)

      const valuesData = {
        cpu: {
          cpu1: cpuTime.get('cpu1'),
          cpu2: cpuTime.get('cpu2'),
          cpu3: cpuTime.get('cpu3'),
          cpu4: cpuTime.get('cpu4')
        },
        upload: {
          rate: uploadRate,
          delay: uploadDelay
        },
        therm: {
          ao_therm: thermTime.get('ao_therm'),
          cpu_therm: thermTime.get('cpu_therm'),
          gpu_therm: thermTime.get('gpu_therm'),
          pll_therm: thermTime.get('pll_therm'),
          pmic_die: thermTime.get('pmic_die'),
          tboard_tegra: thermTime.get('tboard_tegra'),
          tdiode_tegra: thermTime.get('tdiode_tegra')
        },
        storage: {
          mmcblk0p1_memory: storageTime.get('mmcblk0p1_memory'),
          mmcblk1p1_memory: storageTime.get('mmcblk1p1_memory')
        },
        memory: {
          memoryused: memoryTime.get('memoryused'),
          memory: memoryTime.get('memory')
        }
      }

      this.setState({
        dataTracker: eventTime,
        trackerValue: valuesData,
        trackerEvent: cpuTime,
        dateTrackerDE: dateNow.toLocaleString('de-DE')
      })
    } else {
      this.setState({ dataTracker: null, trackerValue: null, trackerEvent: null, dateTrackerDE: null })
    }
  };

  renderGraph (axisName, label, min, max, columns, series, style, type, format) {
    return (
      <Resizable>
        <ChartContainer
          // utc
          trackerPosition={this.state.dataTracker}
          onTrackerChanged={this.handleTrackerChanged}
          timeRange={this.state.timeRange}
          onTimeRangeChanged={this.handleTimeRangeChange}
          onChartResize={(width) => this.handleChartResize(width)}
          minDuration={100000}
        // enablePanZoom
        >
          <ChartRow height='130'>
            <YAxis
              showGrid
              id={axisName}
              label={label}
              min={min}
              max={max}
              width='60'
              type={type}
              format={format}
            />
            <Charts>
              <LineChart
                style={style}
                axis={axisName}
                series={series}
                columns={columns}
              />
            </Charts>
          </ChartRow>
        </ChartContainer>
      </Resizable>
    )
  }

  handleBtn (name, activ, config) {
    // name = thermBtnActiv
    const tempArry = this.state[name]
    const newArray = []

    this.state[name].map((n, index) => {
      if (config.name === n.name) {
        tempArry[index] = { name: config.name, activ: !tempArry[index].activ, color: n.color }
        tempArry.map(n => {
          if (n.activ) {
            newArray.push(n.name)
          }
          return null
        })

        this.setState({
          [name]: tempArry,
          [activ]: newArray
        })
      }
      return null
    })
  }

  handleCheckup () {
    if (this.state.checkup) {
      const sincDate = new Date(this.props.row.expectedStartTime)
      const sincMatchId = this.props.row.RowKey
      const sincCamId = this.props.row.swcsID

      this.setState({
        seriesData: {
          cpu: null,
          memory: null,
          upload: null,
          storage: null
        },
        id: this.props.id,
        loading: true,
        loadingCircle: true,
        checkup: false,
        checkupText: 'Checkup Anzeigen'
      })

      getTelemetrySinc(sincDate, sincCamId, sincMatchId).then(res => {
        if (res.length > 0) {
          const tempData = res.filter(n => {
            return n.Type === 'upload'
          })

          this.handleGenerator(tempData)

          this.props.handleMatch({
            data: tempData[tempData.length - 1],
            match: this.props.row
          })
        } else {
          this.setState({ loading: true, loadingCircle: false })
        }
      })
    } else {
      getTelemetryByType(this.props.row.swcsID, 'checkup').then(res => {
        if (res.length > 0) {
          this.setState({ checkup: true, checkupText: 'Zurück' })
          this.handleGenerator(res)
        } else {
          this.setState({ loading: true, loadingCircle: false })
        }
      })
    }
  }

  render () {
    if (this.state.loading) {
      return (
        <Paper>
          <button className='checkupBtn' onClick={this.handleCheckup.bind(this)}>{this.state.checkupText}</button>
          {this.state.loadingCircle ? (
            <div className='loading'>
              <CircularProgress size={100} thickness={3} />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                Keine Daten vorhanden!
            </div>)}
        </Paper>
      )
    }

    return (
      <Paper>
        <button className='checkupBtn' onClick={this.handleCheckup.bind(this)}>{this.state.checkupText}</button>

        <div>
          {this.state.trackerValue ? (
            <div className='telemetryValues-overlay'>
              <div className='tele-graph-timedate'>
                {this.state.dateTrackerDE && this.state.dateTrackerDE !== 'Invalid Date'
                  ? this.state.dateTrackerDE
                  : '--:--:---- , --:--:--'}
              </div>
              <div className='telemetryValues'>
                <span className='telemetryValues-tb'>Upload Rate: <span className='telemetryValues-sp'>{parseInt(this.state.trackerValue.upload.rate * 8 / 1024)} Mbits</span></span>
                <span className='telemetryValues-tb'>Upload Delay: <span className='telemetryValues-sp'>{parseInt(this.state.trackerValue.upload.delay)} sec</span></span>
                <span className='telemetryValues-tb'>CPU_1: <span className='telemetryValues-sp'>{parseInt(this.state.trackerValue.cpu.cpu1)} %</span></span>
                <span className='telemetryValues-tb'>CPU_2: <span className='telemetryValues-sp'>{parseInt(this.state.trackerValue.cpu.cpu2)} %</span></span>
                <span className='telemetryValues-tb'>CPU_3: <span className='telemetryValues-sp'>{parseInt(this.state.trackerValue.cpu.cpu3)} %</span></span>
                <span className='telemetryValues-tb'>CPU_4: <span className='telemetryValues-sp'>{parseInt(this.state.trackerValue.cpu.cpu4)} %</span></span>
                <span className='telemetryValues-tb'>Speicher 0p1: <span className='telemetryValues-sp'>{parseInt(this.state.trackerValue.storage.mmcblk0p1_memory)} GB</span></span>
                <span className='telemetryValues-tb'>Speicher 1p1: <span className='telemetryValues-sp'>{parseInt(this.state.trackerValue.storage.mmcblk1p1_memory)} GB</span></span>
                <span className='telemetryValues-tb'>ao_therm: <span className='telemetryValues-sp'>{this.state.trackerValue.therm.ao_therm} °C</span></span>
                <span className='telemetryValues-tb'>cpu_therm: <span className='telemetryValues-sp'>{this.state.trackerValue.therm.cpu_therm} °C</span></span>
                <span className='telemetryValues-tb'>gpu_therm: <span className='telemetryValues-sp'>{this.state.trackerValue.therm.gpu_therm} °C</span></span>
                <span className='telemetryValues-tb'>pll_therm: <span className='telemetryValues-sp'>{this.state.trackerValue.therm.pll_therm} °C</span></span>
                <span className='telemetryValues-tb'>pmic_die: <span className='telemetryValues-sp'>{this.state.trackerValue.therm.pmic_die} °C</span></span>
                <span className='telemetryValues-tb'>tboard_tegra: <span className='telemetryValues-sp'>{this.state.trackerValue.therm.tboard_tegra} °C</span></span>
                <span className='telemetryValues-tb'>tdiode_tegra: <span className='telemetryValues-sp'>{this.state.trackerValue.therm.tdiode_tegra} °C</span></span>
              </div>
            </div>
          ) : null}
        </div>

        <br />

        {this.state.seriesData.upload && !this.state.checkup ? (
          <div>
            <div className='forSeriesBtn'>
              {this.state.uploadBtnActiv.map((n, index) => {
                if (n !== 'time') {
                  return (
                    <button key={index} className='seriesBtn' style={n.activ ? { backgroundColor: n.color } : { backgroundColor: n.color, opacity: '0.4' }} onClick={this.handleBtn.bind(this, 'uploadBtnActiv', 'uploadBtn', n)}>{n.name}</button>
                  )
                }
                return null
              })}
            </div>
            <Resizable>
              <ChartContainer
                // utc
                trackerPosition={this.state.dataTracker}
                onTrackerChanged={this.handleTrackerChanged}
                timeRange={this.state.timeRange}
                onTimeRangeChanged={this.handleTimeRangeChange}
                onChartResize={(width) => this.handleChartResize(width)}
                minDuration={100000}
              // enablePanZoom
              >
                <ChartRow height='130'>
                  <YAxis
                    showGrid
                    tickCount={6}
                    id='upload-axis'
                    label='Upload Geschwindigkeit'
                    min={0}
                    max={10000}
                    width='60'
                    type='linear'
                    format={function (n) { return (n / 1000) + ' MBit' }}
                  />
                  <YAxis
                    showGrid
                    tickCount={6}
                    id='upload-axis2'
                    label='Delay'
                    min={0}
                    max={this.state.seriesMax.delay}
                    width='60'
                    type='linear'
                    format={function (n) {
                      const sum = (n / 60)
                      if (n >= 60) {
                        return sum.toFixed(1) + ' min'
                      }
                      return (n.toFixed(1)) + ' sec'
                    }}
                  />
                  <Charts>
                    <LineChart
                      style={styleUpload}
                      axis='upload-axis'
                      series={this.state.seriesData.upload}
                      columns={this.state.uploadBtn.indexOf('upload_rate') !== -1 ? ['upload_rate'] : null}
                    />
                    <LineChart
                      style={styleUpload}
                      axis='upload-axis2'
                      series={this.state.seriesData.upload}
                      columns={this.state.uploadBtn.indexOf('upload_delay') !== -1 ? ['upload_delay'] : null}
                    />
                  </Charts>

                </ChartRow>
              </ChartContainer>
            </Resizable>
            {/* this.renderGraph(
              'upload-axis',
              'Upload Geschwindigkeit',
              0,
              10000,
              this.state.uploadBtn,
              this.state.seriesData.upload,
              styleUpload,
              'linear',
              function (n) { return (n / 1000) + ' Mb' }
            ) */}
          </div>
        ) : null}

        {this.state.seriesData.therm ? (
          <div>
            <div className='forSeriesBtn'>
              {this.state.thermBtnActiv.map((n, index) => {
                if (n !== 'time') {
                  return (
                    <button key={index} className='seriesBtn' style={n.activ ? { backgroundColor: n.color } : { backgroundColor: n.color, opacity: '0.4' }} onClick={this.handleBtn.bind(this, 'thermBtnActiv', 'thermBtn', n)}>{n.name}</button>
                  )
                }
                return null
              })}
            </div>
            {this.renderGraph(
              'therm-axis',
              'Temperatur',
              0,
              120,
              this.state.thermBtn,
              this.state.seriesData.therm,
              styleTherm,
              'linear',
              function (n) { return n + '°C' }
            )}
          </div>
        ) : null}

        {this.state.seriesData.storage ? (
          this.renderGraph(
            'storage-axis',
            'Freier Speicher',
            0,
            this.state.seriesMax.storage,
            ['time', 'mmcblk0p1_memory', 'mmcblk1p1_memory'],
            this.state.seriesData.storage,
            null,
            'linear',
            function (n) { return (n / 1000) + ' GB' }
          )
        ) : null}

        {this.state.seriesData.cpu ? (
          this.renderGraph(
            'cpu-axis',
            'CPU Auslastung',
            0,
            100,
            ['cpu1', 'cpu2', 'cpu3', 'cpu4'],
            this.state.seriesData.cpu,
            style,
            'power',
            function (n) { return n + '%' }
          )
        ) : null}

        {this.state.seriesData.memory ? (
          this.renderGraph(
            'memory-axis',
            'RAM Auslastung',
            0,
            10000,
            ['time', 'memoryused', 'memory'],
            this.state.seriesData.memory,
            styleMemory,
            'linear',
            function (n) { return (n / 1000) + ' GB' }
          )
        ) : null}
      </Paper>
    )
  }
}

export default Graph
