import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import Info from './modules/info'
import VideoPlayer from '../static/videoPlayer'
import discovery from '@soccerwatch/discovery'
import EditOverlay from './tableDialog/editColum'
import moment from 'moment'

import { getLazyLoading, header } from '../../api/helper'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'

import 'react-datepicker/dist/react-datepicker.css'

const config = {
  tableName: 'clipsTable',
  tableUrl: '/clips/overview/',
  tableDialogTitle: 'Clip',
  tableToolbar: true,
  tableAdd: false,
  columns: [
    { name: 'RowKey', title: 'RowKey', width: 160 },
    { name: 'name', title: 'name', width: 150 },
    { name: 'teamAUId', title: 'Team A', width: 140 },
    { name: 'teamBUId', title: 'Team B', width: 140 },
    { name: 'leagues', title: 'Leagues', width: 140 },
    { name: 'city', title: 'city', width: 140 },
    { name: 'referenceDate', title: 'referenceDate', width: 180 },
    { name: 'priority', title: 'priority' },
    { name: 'active', title: 'active' },
    { name: 'eventType', title: 'eventType', value: 'number' },
    { name: 'level', title: 'level', value: 'number' },
    { name: 'matchIds', title: 'matchIds' },
    { name: 'metaTags', title: 'metaTags' },
    { name: 'clipStream', title: 'clipStream' },
    { name: 'Timestamp', title: 'Timestamp', width: 180 },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }, { label: 'System', value: ['RowKey', 'state'] }]
}

class Clips extends Component {
  constructor(props) {
    super(props)

    const now = new Date()
    now.setDate(now.getDate() - 14)

    this.state = {
      rows: [],
      tempId: 0,
      tempMatch: [],
      check: false,
      clipFromSince: now.getTime(),
      clipToSince: new Date().getTime(),
      loadingRows: true
    }

    this.handleGetCall = this.handleGetCall.bind(this)
  }

  _isMounted = false

  onSelectId = (id, multiSelect = undefined) => {
    if (id != null) {
      if (multiSelect !== undefined) {
        this.setState({ tempId: id, multiSelect })
      } else {
        const match = this.state.rows[id]
        const matchId = JSON.parse(this.state.rows[id].matchIds)[0]
        let checkPrevTag
        if (matchId !== undefined) {
          const result = this.state.rows.filter(row => {
            const z = JSON.parse(row.matchIds)[0]
            if (z === matchId) {
              return row
            }
          })
          result.sort((a, b) => {
            return new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime()
          })
          const indexRow = result.indexOf(match)
          if (Number(indexRow) >= 1) {
            checkPrevTag = result[indexRow - 1]
          }
        }

        this.setState({ tempId: id, prevTag: checkPrevTag })
      }
    }
  };

  componentDidMount() {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleMounted() {
    return this._isMounted
  }

  handleSetFromSince(date) {
    this.setState({ clipFromSince: new Date(date) })
  }

  handleSetToSince(date) {
    this.setState({ clipToSince: new Date(date) })
  }

  handleGetCall(check) {
    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const to = moment(this.state.clipToSince).add(1, 'day').toISOString()

    const configArray = [{
      url: discovery.API_CLIP + '/Highlight/simpleInfosTill/' + to + '/',
      minEntrys: undefined,
      auth: header,
      handle: this.handleGetClipList.bind(this),
      number: 4
    }]

    const dateTo = new Date(this.state.clipToSince).getTime()
    const dateFrom = new Date(this.state.clipFromSince).getTime()
    getLazyLoading(configArray, this.handleMounted.bind(this), { dateTo: dateTo, dateFrom: dateFrom })
  }

  handleGetClipList(res, checkLast) {
    // Sort and ADD Id by Rows
    res = res.map((clip, index) => {
      return ({
        tableId: index,
        RowKey: clip.id,
        name: clip.name,
        teamAUId: clip.teamAUId,
        teamBUId: clip.teamBUId,
        leagues: clip.leagues,
        priority: String(clip.priority),
        Timestamp: clip.Timestamp,
        eventType: clip.eventType,
        level: clip.level,
        city: (clip.city || []).reduce((prev, cur) => {
          return prev + cur + ','
        }, ''),
        matchIds: JSON.stringify(clip.matchIds),
        metaTags: clip.metaTags.join(', '),
        referenceDate: clip.referenceDate,
        clipStream: clip.clipStream,
        active: String(clip.active || false)
      })
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

  render() {
    // Check clip url
    var tempClipUrl
    if (this.state.rows[this.state.tempId] && this.state.rows[this.state.tempId].clipStream) {
      tempClipUrl = this.state.rows[this.state.tempId].clipStream
    }

    return (
      <div>
        <HeadText text={this.props.headingText} />
        <Grid container spacing={24}>
          <Grid item lg={5} xs={12} sm={6}>
            <Info
              rowinfo={this.state.rows[this.state.tempId]}
              rows={this.state.rows}
              notification={this.props.notification}
              clipFromSince={this.state.clipFromSince}
              clipToSince={this.state.clipToSince}
              handleSetFromSince={this.handleSetFromSince.bind(this)}
              handleSetToSince={this.handleSetToSince.bind(this)}
              handleGetCall={this.handleGetCall}
              prevTag={this.state.prevTag}
            />
          </Grid>
          <Grid item lg={7} xs={12} sm={6}>
            <VideoPlayer url={tempClipUrl} />
          </Grid>
          <Grid item lg={12} xs={12} sm={12}>
            <Table
              tableConfig={config}
              tableRows={this.state.rows}
              loadingData={this.state.loadingRows}
              onSelectId={this.onSelectId}
              resetData={this.handleGetCall}
              // handleEditRow={this.handleEditRow}
              // handleCreateRow={this.handleCreateRow}
              // handleDeleteRow={this.handleDeleteRow}
              // addColum={AddColum}  // needs for individually overlay design by Create
              editColum={EditOverlay} // needs for individually overlay design by Edit
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Clips
