import React from 'react'
import markerIconB from '../../../../icon/map-marker-black.png'
import markerIconMid from '../../../../icon/map-marker-yellow2.png'
import markerIconHigh from '../../../../icon/map-marker-litered.png'
import markerIconHighest from '../../../../icon/map-marker-red.png'
import marker_icon_ina_test from '../../../../icon/map-marker-greay.png'

import { Marker, InfoWindow } from 'react-google-maps'
import { getSponsorFor } from '../../../api/api-additional'
import { Button } from '@material-ui/core'

import '../map.css'

// const clubStateColor = 'http://maps.google.com/mapfiles/kml/paddle/wht-blank.png';

class Markerz extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false,
      lat: this.props.lat,
      lng: this.props.lng,
      sponsorList: [],
      hoverInfo: false,
      CSVtemp: []
    }

    this.onHandle = this.onHandle.bind(this)
  }

  onHandle () {
    this.setState({
      isOpen: !this.state.isOpen
    })

    this.props.csv.map((csv, index) => {
      if (csv.cam_ID === String(this.props.info.RowKey)) {
        return this.props.handleId(csv, this.props.info.RowKey, this.state.isOpen)
      }

      return null
    })
  }

  componentDidMount () {
    getSponsorFor(this.props.info.uid).then((res) => {
      this.setState({ sponsorList: res.data })
    })

    this.props.csv.map((csv, index) => {
      if (csv.cam_ID === String(this.props.info.RowKey)) {
        this.setState({ CSVtemp: csv })
      }
      return null
    })
  }

  sponGenerator (name, max) {
    const spon = []

    for (let i = 0; i < this.state.sponsorList[max]; i++) {
      if (i < this.state.sponsorList[name]) {
        spon.push(<div className='sponsor-boxed'>{name}</div>)
      } else {
        spon.push(<div className='sponsor-boxed-true'>X</div>)
      }
    }

    return spon
  }

  componentDidUpdate (prevProps) {
    const tempField = prevProps.searchField
    const tempClub = this.props.info.name
    let temp

    if (tempField !== undefined && tempClub !== undefined) {
      temp = tempClub.toLowerCase().indexOf(tempField.toLowerCase())
    } else if (tempClub === undefined) {
      temp = -1
    }

    if (prevProps.searchField !== '' && prevProps.searchField !== String(this.props.info.RowKey) && temp === -1) {
      this.setState({ searchField: false }) // invisible
    } else {
      this.setState({ searchField: true }) // visible
    }

    if (prevProps.closeMarker !== this.props.info.RowKey) {
      this.setState({
        isOpen: false
      })
    }
  }

  createSponsorBox = (value) => {
    const xlSpon = this.sponGenerator('xl', 'xlMax')
    const lSpon = this.sponGenerator('l', 'lMax')
    const mSpon = this.sponGenerator('m', 'mMax')
    const sSpon = this.sponGenerator('s', 'sMax')

    switch (value) {
      case 'xl':
        return (
          <div className='sponsor'>
            <ul>
              {xlSpon.map((value, index) => {
                return <li key={index}>{value}</li>
              })}
            </ul>
          </div>
        )
      case 'l':
        return (
          <div className='sponsor'>
            <ul>
              {lSpon.map((value, index) => {
                return <li key={index}>{value}</li>
              })}
            </ul>
          </div>
        )
      case 'm':
        return (
          <div className='sponsor'>
            <ul>
              {mSpon.map((value, index) => {
                return <li key={index}>{value}</li>
              })}
            </ul>
          </div>
        )
      case 's':
        return (
          <div className='sponsor'>
            <ul>
              {sSpon.map((value, index) => {
                return <li key={index}>{value}</li>
              })}
            </ul>
          </div>
        )
      default:
        break
    }
  };

  render () {
    let thump = this.props.info.thumbnail
    let iconMarker = markerIconB
    const state = this.props.info.systemState
    const lifecircleState = this.props.info.lifecircleState
    const prio = this.state.CSVtemp.task_priority

    if (thump === undefined || thump === 'platzerhalter' || thump === '/assets/img/clubq.jpg') {
      thump = 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
    }

    /**
     * larst Prio for Icon-color
     */

    if (state === 'NOT_EXISTING' || lifecircleState === 'RETIRED' || lifecircleState === 'TESTSYSTEM' || lifecircleState === 'DEV') {
      if (prio === 'MID') {
        iconMarker = markerIconMid
      } else if (prio === 'HIGH') {
        iconMarker = markerIconHigh
      } else if (prio === 'HIGHEST') {
        iconMarker = markerIconHighest
      }
    }

    if (state === 'ACTIVE') {
      iconMarker = markerIconB
      if (prio === 'MID') {
        iconMarker = markerIconMid
      } else if (prio === 'HIGH') {
        iconMarker = markerIconHigh
      } else if (prio === 'HIGHEST') {
        iconMarker = markerIconHighest
      }
    }

    if (state === 'NO_POWER' || lifecircleState === 'PENDING') {
      iconMarker = markerIconMid
      if (prio === 'MID') {
        iconMarker = markerIconMid
      } else if (prio === 'HIGH') {
        iconMarker = markerIconHigh
      } else if (prio === 'HIGHEST') {
        iconMarker = markerIconHighest
      }
    }

    if (state === 'DEFECT' || state === 'CONNECTION_LOST') {
      iconMarker = markerIconHighest
    }

    return (this.props.filterA === true && iconMarker === markerIconB) ||
      (this.props.filterB === true && iconMarker === markerIconMid) ||
      (this.props.filterC === true && iconMarker === markerIconHigh) ||
      (this.props.filterD === true && iconMarker === markerIconHighest) ||
      ((this.props.filterE === true &&
        this.state.CSVtemp.cam_status === 'TESTSYSTEM' &&
        this.state.CSVtemp.task_priority === '') ||
        (this.props.filterE === true &&
          this.props.info.lifecircleState === 'TESTSYSTEM' &&
          iconMarker === marker_icon_ina_test)) ? (
            <Marker
          position={{ lat: this.state.lat, lng: this.state.lng }}
          onClick={this.onHandle}
          onMouseOver={() => this.setState({ hoverInfo: true })}
          onMouseOut={() => this.setState({ hoverInfo: false })}
          icon={{
            url: iconMarker
              }}
          visible={this.state.searchField}
        >
          {(this.state.isOpen || this.state.hoverInfo) && (
                <InfoWindow onCloseClick={this.onHandle}>
              <div className='InfoWindow'>
                    <div className='InfoWindow-base'>
                  <div className='base-logo'>
                        <img src={thump} alt='img' width={45} height={45} />
                      </div>
                  <div className='base-name'>
                        {this.props.info.name}
                        <div className='base-id'>
                    ID: {this.props.info.RowKey} / ClubId: {this.props.info.ownerClubId}
                    </div>
                        <div className='base-id'>{this.props.info.location}</div>
                      </div>
                </div>
                    <div className='InfoWindow-Container'>
                  <div className='InfoWindow-head inline'>Status:</div>
                  <div className='inline'> {this.props.info.systemState}</div>
                </div>
                    <div className='InfoWindow-sponsor'>
                  <div className='InfoWindow-head'>Sponsoreninfo:</div>
                  <div className='sponsor-container'>
                        {this.createSponsorBox('xl')}
                        {this.createSponsorBox('l')}
                        {this.createSponsorBox('m')}
                        {this.createSponsorBox('s')}
                      </div>
                </div>
                    <div className='InfoWindow-sponsor'>
                  <div className='InfoWindow-head'>Routen Planer:</div>
                  <div>
                        <Button
                      className='routeBtn'
                      onClick={() => {
                        this.setState({ isOpen: false })
                        return this.props.handleChangeStart(this.props.info.latitude + ', ' + this.props.info.longitude)
                          }}
                    >
                      {' '}
                    start{' '}
                    </Button>
                        <Button
                      className='routeBtn'
                      onClick={() => {
                        this.setState({ isOpen: false })
                        return this.props.handleChangeMid(this.props.info.latitude + ', ' + this.props.info.longitude)
                          }}
                    >
                      {' '}
                    mid{' '}
                    </Button>
                        <Button
                      className='routeBtn'
                      onClick={() => {
                        this.setState({ isOpen: false })
                        return this.props.handleChangeEnd(this.props.info.latitude + ', ' + this.props.info.longitude)
                          }}
                    >
                      {' '}
                    Ende{' '}
                    </Button>
                      </div>
                </div>
                  </div>
            </InfoWindow>
          )}
        </Marker>
      ) : null
  }
}

export default Markerz
