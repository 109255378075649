import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import Info from './modules/info'
import Log from './modules/log'
import discovery from '@soccerwatch/discovery'
import DelayGraph from './modules/delayGraph'
import AddColum from './tableDialog/addColum'
import VideoPlayer from '../static/videoPlayer'
import Paper from '@material-ui/core/Paper'
import Plot from './modules/plot'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DatePicker from 'react-datepicker'

import { getLazyLoading, getLazyLoadingNext, header } from '../../api/helper'
import { postMetaId, getMetaBy } from '../../api/api-video'
import { outputTemplates, renderConfiguration, backendVersion,taggingState, eventType, videoFormat, videoProcessing, contentType, useStateFlow, videoStates, archiveState } from '../static/dropdownValues'
import { Button } from '@material-ui/core'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'

const config = {
  tableName: 'gameTable',
  tableUrl: '/games/overview/',
  tableDialogTitle: 'Spiel',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'RowKey', title: 'Spiel ID', value: 'number', operation: 'equal', editable: false },
    // * Field state is an abstraction for endusers. Admins need a real State
    { name: 'state', title: 'Status', width: 150, dropdown: true, dropdownValue: videoStates, setOnlyOldState: true,  tooltip: 'Status des Spieles' },
    { name: 'archiveState', title: 'archiveState', width: 150, editable: false, tooltip: 'Status der Archivierung.' },
    { name: 'pendingArchiveState', title: 'pendingArchiveState', width: 150, dropdown: true, dropdownValue: archiveState, tooltip: 'Angeforderter Status der Archivierung.ACHTUNG: Der Wechsel von delete Zuständen in live zustände triggert kein erneutes Rendern. In diesem Fall sollte hier zuerst der live state gewählt werden und anschließend das rerendering angestoßen werden.  . Sobald der Request bearbeitet wurde, wird der Status geändert. Stream Archieve beinhaltet ebenfalls grid archieve' },
    { name: 'keepArchiveState', title: 'keepArchiveState', value: 'boolean', width: 50, editable: true, tooltip: 'Deaktiviert das automatische, zeitbasierte Setzen der Archievierungsrequests. kann verwendet werden um alte Aufnahmen im aktiven Status zu halten, die z.B für KI Trainings oder Coachingtool Beispiele genutzt werden' },
    { name: 'useStateFlow', title: 'useStateFlow ', width: 100, dropdown: true, dropdownValue: useStateFlow },
    { name: 'swcsID', title: 'Kamera ID', operation: 'equal', editable: true, tooltip: 'ID der Kamera, die das Spiel aufzeichnet' },
    { name: 'fieldCorners', title: 'fieldCorners', value: 'fieldCorners', tooltip: 'Name der Feldmaske, die verwendet wird' },
    { name: 'framerate', title: 'framerate', editable: true, value: 'number', tooltip: 'Experimentele Einstellung für die Framerate des Ausgabestreams. Per default bei 25; kann zu Performanceproblemen im Livestream führen.' },
    { name: 'webOverlayUrl', title: 'webOverlayUrl', editable: true, tooltip: 'web_overlay_url parameter für die KI um overlays in den stream zu rendern. Hierfür muss die folgende URL mit der matchId eingegeben werden :  https://fake-player.web.app/?id=<matchID> ' },
    { name: 'hardwarePlatform', title: 'hardwarePlatform', operation: 'equal', /* editable: false, */ width: 140, tooltip: 'Kameraversion (z.b. K1, K2, K2M)' },
    { name: 'active', title: 'active', value: 'boolean', tooltip: 'Legt fest ob das Spiel auf der Plattfrom erscheinen soll' },
    { name: 'tts', title: 'tts', tooltip: 'TimetToStream dieser Wert wird normalerweise von der KI gesetzt wenn ein Bildsignal angekommen ist' },
    { name: 'cameraBitrate', title: 'cameraBitrate', value: 'number', width: 140, tooltip: 'Bitrate der Kamera welcher zur Cloud gesendet wird in Kbit/s' },
    { name: 'overwriteStreamBitrate', title: 'streamBitrate', value: 'number', width: 140, tooltip: 'Bitrate welche von der KI ausgegeben wird für den userStream in Kbit/s' },
    { name: 'startTime', title: 'startTime', width: 200, editable: false, tooltip: 'StartTime in lesbarem Format (das gleiche wie ExpectedStartTime)' },
    { name: 'prohibitAudioComments', title: 'prohibitAudioComments', value: 'boolean', width: 50, editable: true, tooltip: 'Wenn gesetzt, kann kein Audiokommentar bei diesem Spiel aufgenommen werden' },
    { name: 'hideScoreboard', title: 'hideScoreboard', value: 'boolean', width: 100, editable: true, tooltip: 'Wenn gesetzt, wird das Scoreboard nicht angezeigt' },
    { name: 'hideScoreBoardHighlight', title: 'hideScoreBoardHighlight', value: 'boolean', width: 100, editable: true, tooltip: 'Wenn gesetzt, wird das Scoreboard im Highlight nicht anzeigt' },
    { name: 'hideEmbed', title: 'hideEmbed', value: 'boolean', width: 50, editable: true, tooltip: 'Wenn gesetzt, ist der Embed-Tab auf der Plattform nicht sichtbar' },
    { name: 'oneAdPerSlot', title: 'oneAdPerSlot', value: 'boolean', width: 50, editable: true },
    { name: 'backendVersion', title: 'backendVersion', width: 50, editable: true, dropdown: true, dropdownValue: backendVersion },
    { name: 'error', title: 'error' },
    { name: 'streamOwner', title: 'streamOwner', width: 200 },
    { name: 'canceledReason', title: 'canceledReason' },
    { name: 'manualTagging', title: 'manualTagging',value: 'boolean', width: 50, editable: true  },
    { name: 'Ref_CC_Manual_Tagging', title: 'Ref_CC_Manual_Tagging',value: 'string', width: 50, editable: false  },
    { name: 'Ref_CC_Tagging_State', title: 'Ref_CC_Tagging_State', width: 200, tooltip: 'Aktueller Stand des Taggings.',editable: false,defaultValue:'AH' },
    { name: 'Ref_Tag_Events', title: 'Ref_Tag_Events', width: 50, tooltip: 'Alle Event Typen die für dieses Spiel hinterlegt wurden',editable: false },
    { name: 'clubAId', title: 'clubAId', width: 200, operation: 'equal', tooltip: 'ID von Club A' },
    { name: 'clubAName', title: 'clubAName', width: 200, tooltip: 'Name von Club A' },
    { name: 'clubATeam', title: 'clubATeam', width: 200, tooltip: 'Name des Teams, welches von Club A spielt' },
    { name: 'clubATeamId', title: 'clubATeamId', width: 200, tooltip: 'ID des Teams welches von Club A spielt' },
    { name: 'teamAUId', title: 'teamAUId', width: 200 },
    { name: 'teamAAge', title: 'teamAAge', tooltip: 'Hier wird die Altersklasse des Heimvereins eingetragen' },
    { name: 'clubAShirtColor', title: 'clubAShirtColor', value: 'colorPicker', tooltip: 'Trikot-Farbe von Club A' },
    { name: 'clubBId', title: 'clubBId', width: 200, tooltip: 'ID von Club B' },
    { name: 'clubBName', title: 'clubBName', width: 200, tooltip: 'Name von Club B' },
    { name: 'clubBTeam', title: 'clubBTeam', width: 200, tooltip: 'Name des Teams, welches von Club B spielt' },
    { name: 'clubBTeamId', title: 'clubBTeamId', width: 200, tooltip: 'ID des Teams welches von Club B spielt' },
    { name: 'teamBUId', title: 'teamBUId', width: 200 },
    { name: 'teamBAge', title: 'teamBAge', tooltip: 'Hier wird die Altersklasse der Gastverein eingetragen' },
    { name: 'clubBShirtColor', title: 'clubBShirtColor', value: 'colorPicker', tooltip: 'Trikot-Farbe von Club B' },
    { name: 'gender', title: 'Gender', width: 200 },
    { name: 'durationMin', title: 'durationMin', value: 'number', tooltip: 'Länge der geplanten Aufnahme' },
    { name: 'expectedStartTime', title: 'expectedStartTime', value: 'datetimeValueOf', width: 200, tooltip: 'StartTime in UnixTimestamp' },
    { name: 'playDate', title: 'playDate' },
    { name: 'field', title: 'field', tooltip: 'Die Adresse des Feldes auf dem gespielt wird' },
    { name: 'league', title: 'league', width: 150, tooltip: 'Die Liga, in der das Spiel stattfindet' },
    { name: 'thumbnail', title: 'thumbnail', width: 150, tooltip: 'Das Thumbnail welches auf der Platform anzeigt werden soll' },
    // { name: 'views', title: 'views' },
    { name: 'did', title: 'did', width: 150 },
    { name: 'sportRadarId', title: 'sportRadarId', width: 150, editable: true },
    { name: 'expectSportRadarEntries', title: 'expectSportRadarEntries', value: 'boolean', width: 50, editable: false },
    { name: 'priority', title: 'priority', tooltip: 'Priorität des Spieles' },
    { name: 'cup', title: 'cup' },
    { name: 'cupGroup', title: 'cupGroup' },
    { name: 'uselabels', title: 'uselabels' },
    { name: 'renderConfiguration', title: 'renderConfiguration', width: 200, dropdown: true, dropdownValue: renderConfiguration, tooltip: 'Render Konfiguration' },
    { name: 'useForCalibration', title: 'useForCalibration', value: 'boolean', tooltip: 'Wenn gesetzt, wird die Kamera mit diesem Spiel neu kalibriert' },
    { name: 'privateActive', title: 'privateActive', value: 'boolean', tooltip: 'Wenn gesetzt, wird das Spiel nicht auf der Plattform gezeigt, ist aber über die ID aufrufbar' },
    { name: 'platformIgnore', title: 'platformIgnore', value: 'boolean' },
    { name: 'adFree', title: 'adFree', value: 'boolean', tooltip: 'Wenn gesetzt, wird in diesem Spiel keine Werbung angezeigt. Weder PreRolls, noch Lotti' },
    { name: 'instreamAdFree', title: 'instreamAdFree', value: 'boolean' },
    { name: 'maxPreRolls', title: 'maxPreRolls', value: 'number', tooltip: 'Anzahl der maximal gezeigten PreRolls' },
    { name: 'connection', title: 'connection', width: 150, editableValueCheck: ['rtmp://', 'gs://', 's3://', 'srt://', '['] },
    // { name: 'videoType', title: 'videoType', width: 150, dropdown: true, dropdownValue: videoType, tooltip: 'VERALTET : Kombination aus Sportart und KI Modus. Nutzt dafür aber jetzt videoFormat,videoProcessing,eventType und contentType' },
    { name: 'eventType', title: 'eventType', width: 150, dropdown: true, dropdownValue: eventType, tooltip: 'Sportart oder Event Type' },
    { name: 'videoFormat', title: 'videoFormat', width: 150, dropdown: true, dropdownValue: videoFormat, tooltip: 'Anlieferung des Bildsignals ( K1,K2,K2M , ip cameras, APPS oder externe streams' },
    { name: 'videoProcessing', title: 'videoProcessing', width: 150, dropdown: true, dropdownValue: videoProcessing, tooltip: 'Zu verwendente KI. Sollte dem des eventTypen entsprechen, kann aber abweichen ( VR-Stream (videoProcessing) von Type Handball(eventType) zb.)' },
    { name: 'contentType', title: 'contentType', width: 150, dropdown: true, dropdownValue: contentType, tooltip: 'Art der Aufzeichnung ( Training, Übertragung, Test' },
    { name: 'overwriteWithOutputTemplate', title: 'OutputTemplate', width: 150, dropdown: true, dropdownValue: outputTemplates },
    { name: 'watermark', title: 'watermark', width: 150, tooltip: 'Wasserzeichen welches im Stream zu sehen ist' },
    { name: 'infoMessage', title: 'infoMessage', width: 150 },
    { name: 'contractId', title: 'contractId', width: 150 },
    // { name: 'expectedAvailabilityTime', title: 'expectedAvailabilityTime', value: 'datetime', width: 170 },
    { name: 'blendingOnServer', title: 'blendingOnServer', value: 'boolean' },
    { name: 'paywall', title: 'paywall', value: 'boolean', tooltip: 'Wenn gesetzt, ist die PayWall aktiv' },
    { name: 'vrStream', title: 'vrStream', width: 200 },
    { name: 'userStream', title: 'userStream', width: 200 },
    { name: 'userStreamAdaptive', title: 'userStreamAdaptive', width: 200 },
    { name: 'gridStream', title: 'gridStream', width: 200, tooltip: 'Rohdaten für die Aufnahme. Sollten im normalfall nicht angefasst werden' },
    { name: 'titleOverwriteA', title: 'titleOverwriteA', width: 200 },
    { name: 'titleOverwriteB', title: 'titleOverwriteB', width: 200 },
    { name: 'titleOverwriteC', title: 'titleOverwriteC', width: 200 },
    { name: 'competitionId', title: 'competitionId', width: 200 },
    { name: 'videoBufferSecs', title: 'videoBufferSecs', value: 'number', width: 120 },
    { name: 'use_latest_calibration', title: 'use_latest_calibration', value: 'boolean', width: 200 },
    { name: 'tenant', title: 'tenant', value: 'tagInput', label: 'tenant', toCreate: false, width: 180 },
    { name: 'Ref_CC_Ads', title: 'Ref_CC_Ads', width: 150, editable: false, tooltip: 'Liste der Ad Ids die bei diesem Spiel ausgespielt werden. Kombiniert alle Werbereferencen von Städten, Vereinen, Ligen, Spielen etc.' },
    { name: 'Ref_CC_Priority_Score', title: 'Ref_CC_Priority_Score', width: 150, editable: false, tooltip: 'Die berechnete Priorität für diese Begegnung. Je kleiner desto höher die Priorität. Spiele mit einer Prio unter 1 werden auf der Startseite angezeigt. Prio kleiner 2 nur dann, wenn nicht genügend Prio < 1 Spiele vorhanden sind.' },
    { name: 'Ref_CC_Tenant', title: 'Ref_CC_Tenant', width: 150, editable: false, tooltip: 'Tenant (Kunden) die diesem Eintrag zugeordnet sind' },
    { name: 'Ref_Region', title: 'Ref_Region', width: 150, editable: false, tooltip: 'Region die diesem Spiel zugeordnet ist' },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [
    { label: 'Alles', value: [''] },
    { label: 'System', value: ['RowKey', 'state'] },
    {
      label: 'Priority',
      value: [
        'swcsID',
        'active',
        'tts',
        'error',
        'clubAId',
        'clubAName',
        'clubATeamId',
        'clubBId',
        'clubBName',
        'clubBTeamId',
        'durationMin',
        'expectedStartTime',
        'field',
        'thumbnail',
        'views',
        'did'
      ]
    }
  ]
}

class Games extends Component {
  constructor (props) {
    super(props)

    const until = new Date()
    until.setDate(until.getDate() + 10)

    this.state = {
      rows: [],
      tempId: 0,
      check: false,
      loadingRows: true,
      delayOpen: false,
      lastToken: undefined,
      singleRows: [],
      from: new Date(),
      until: until,
      dateSearch: false,
      dateSearchUrl: undefined
    }

    this.handleId = this.handleId.bind(this)
    this.handleDelayGraph = this.handleDelayGraph.bind(this)
    this.handleEditColum = this.handleEditColum.bind(this)
    this.handleMergeRow = this.handleMergeRow.bind(this)
    this.tableChild = React.createRef()
  }

  _isMounted = false

  handleId (id) {
    if (id != null) {
      this.setState({ tempId: id, delayOpen: false })
    }
  };

  /**
   * Api-call get GameList
   */
  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleMounted () {
    return this._isMounted
  }

  handleGetCall (check, nextRound, intervalStop, url) {
    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [{
      url: url || discovery.API_VIDEO + '/metas/',
      minEntrys: undefined,
      auth: header,
      handle: this.handleGetMatchList.bind(this),
      number: 4,
      intervalStop: intervalStop || 10
    }]

    if (nextRound) {
      getLazyLoadingNext(configArray, this.handleMounted.bind(this), this.state.rows, this.state.lastToken)
      this.setState({ lastToken: undefined })
    } else {
      getLazyLoading(configArray, this.handleMounted.bind(this))
    }
  }

  handleNextGetCall () {
    if (this.state.dateSearch) {
      this.setState({ check: false, loadingRows: true })
      this.handleGetCall(null, true, null, this.state.dateSearchUrl)
    } else {
      this.setState({ check: false, loadingRows: true, dateSearch: false, dateSearchUrl: undefined })
      this.handleGetCall(null, true)
    }
  }

  handleAllGetCall () {
    if (this.state.dateSearch) {
      this.setState({ check: false, loadingRows: true })
      this.handleGetCall(null, true, -1, this.state.dateSearchUrl)
    } else {
      this.setState({ check: false, loadingRows: true, dateSearch: false, dateSearchUrl: undefined })
      this.handleGetCall(null, true, -1)
    }
  }

  onlyUnique (value, index, self) {
    return self.indexOf(value) === index
  }

  handleGetMatchList (res, checkLast, lastToken) {
    if (this.state.singleRows.length > 0) {
      res = res.concat(this.state.singleRows)
      res = res.filter(this.onlyUnique)
    }

    // ADD tableId by Rows
    res.map((row, index) => {
      row.RowKey = Number(row.RowKey)
      row.tableId = index
      row.state = row.realState || row.state
      row.expectedStartTime = Number(row.expectedStartTime)
      row.platformIgnore = row.platformIgnore ? row.platformIgnore : false
      row.paywall = row.paywall ? row.paywall : false
      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false,
        lastToken: lastToken
      })
    } else {
      this.setState({
        rows: res,
        lastToken: lastToken
      })
    }
  }

  handleDelayGraph () {
    this.setState({
      delayOpen: !this.state.delayOpen
    })
  }

  async handleEditColum (data) {
    let checkPostStatus = false

    if (data.json.expectedStartTime && data.row.expectedStartTime !== data.json.expectedStartTime) {
      data.json.customStartTime = true
    }

    if (data.json.eventType) {
      data.json.videoType = data.json.eventType
    }

    await postMetaId(data.json, String(data.row.RowKey)).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
      }
    })
    return checkPostStatus
  }

  handleMergeRow (newRow) {
    const newRows = this.state.rows.map(row => {
      if (String(row.RowKey) === String(newRow.RowKey)) {
        row.active = newRow.active !== undefined ? newRow.active : row.active
        row.state = newRow.state !== undefined ? newRow.state : row.state
        row.priority = newRow.priority !== undefined ? newRow.priority : row.priority
        row.canceledReason = newRow.canceledReason !== undefined ? newRow.canceledReason : row.canceledReason
        return row
      }
      return row
    })

    this.setState({ rows: newRows })
  }

  hanldeGetSingleVideo = async () => {
    this._isMounted = false
    this.setState({
      loadingRows: false
    })

    await getMetaBy(this.state.input).then(res => {
      if (res.status === 200) {
        let arr = this.state.singleRows
        arr = arr.filter(a => a.RowKey !== res.data.RowKey)
        arr.push(res.data)
        const temp = this.state.rows.filter(x => x.RowKey !== Number(res.data.RowKey))
        temp.push(res.data)
        this.tableChild.current.changeFilter([{ columnName: 'RowKey', operation: 'equal', value: Number(this.state.input) }])

        temp.map((row, index) => {
          row.RowKey = Number(row.RowKey)
          row.tableId = index
          row.state = row.realState || row.state
          row.expectedStartTime = Number(row.expectedStartTime)
          return row
        })

        this.setState({
          rows: temp,
          singleRows: arr
        })
      }
    })

    this._isMounted = true
  }

  handleClickOpen = () => {
    this.setState({ dialogOpen: true })
  }

  handleClose = () => {
    this.setState({ dialogOpen: false })
  }

  handleDateChange = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  handleGetDateDataCall = () => {
    this.handleClose()
    const from = new Date(this.state.from).toISOString()
    const until = new Date(this.state.until).toISOString()
    const url = discovery.API_VIDEO + '/metas/' + from + '/' + until + '/'
    this.setState({
      dateSearch: true,
      dateSearchUrl: url
    })
    this.handleGetCall(true, null, null, url)
  }

  render () {
    let tempInfo = []
    let soloRow, vrStream
    const stateTemp = this.state.rows[this.state.tempId] && this.state.rows[this.state.tempId].state
    const checkDelayGraph = stateTemp && (stateTemp.toLowerCase() === 'done' || stateTemp.toLowerCase() === 'live')

    if (this.state.rows && this.state.rows[this.state.tempId]) {
      soloRow = this.state.rows[this.state.tempId]
      tempInfo = [
        soloRow.active,
        soloRow.RowKey,
        soloRow.state,
        soloRow.swcsID,
        soloRow.storage,
        soloRow.cloneIp,
        soloRow.userStream,
        soloRow.gridStream
      ]
    }

    if (this.state.rows.length < 0) {
      this.state.rows.splice(0, this.state.rows.length)
    }

    // Check clip url
    let tempClipUrl, checkPanorama
    if (this.state.rows[this.state.tempId] && (this.state.rows[this.state.tempId].userStream || this.state.rows[this.state.tempId].userStreamAdaptive)) {
      tempClipUrl = this.state.rows[this.state.tempId].userStreamAdaptive || this.state.rows[this.state.tempId].userStream
      checkPanorama = this.state.rows[this.state.tempId].videoType
      vrStream = this.state.rows[this.state.tempId].vrStream
    }

    return (
      <div>
        <HeadText text={this.props.headingText} />
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          fullWidth
        >
          <DialogTitle id='form-dialog-title'>Nach Datum suchen</DialogTitle>
          <DialogContent style={{ height: 200 }}>

            <div>
              <div style={{ color: 'rgba(0, 0, 0, 0.54)', padding: 0, fontSize: '12px', margin: '4px 0px 4px' }}>
                <span>Von</span>
              </div>
              <DatePicker
                className='table-datepicker overlay-datepicker'
                selected={new Date(this.state.from).toISOString()}
                onChange={(event) => this.handleDateChange('from', event)}
                dateFormat='dd.MM.yyyy'
                popperPlacement='left'
                popperModifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 0]
                    }
                  }
                ]}
                showYearDropdown
              />
            </div>
            <div>
              <div style={{ color: 'rgba(0, 0, 0, 0.54)', padding: 0, fontSize: '12px', margin: '4px 0px 4px' }}>
                <span>Bis</span>
              </div>
              <DatePicker
                className='table-datepicker overlay-datepicker'
                selected={new Date(this.state.until).toISOString()}
                onChange={(event) => this.handleDateChange('until', event)}
                dateFormat='dd.MM.yyyy'
                popperPlacement='left'
                popperModifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 0]
                    }
                  }
                ]}
                showYearDropdown
              />
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Abbrechen
            </Button>
            <Button onClick={this.handleGetDateDataCall} color='primary'>
              Suchen
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={24}>
          <Grid item lg={3} xs={12} sm={6}>
            <Info
              resetData={this.handleGetCall.bind(this)}
              rowinfo={this.state.loadingRows === false ? this.state.rows[this.state.tempId] : undefined}
              notification={this.props.notification}
              openDelay={this.handleDelayGraph}
              handleMergeRow={this.handleMergeRow}
            />
          </Grid>
          <Grid item lg={3} xs={12} sm={6}>
            {checkPanorama !== 'panorama' &&
              <VideoPlayer url={this.state.loadingRows === false ? tempClipUrl : undefined} />}

            {(checkPanorama === 'panorama' || vrStream) && (
              <Paper className='game-layout'>
                {checkPanorama !== 'panorama' ? '' : (
                  <div className='layout-head'>VIDEO</div>
                )}
                <Button
                  fullWidth
                  className='btn'
                  color='primary'
                  variant='contained'
                  onClick={() => { window.open(`https://coachingtool.soccerwatch.tv/publicView/${this.state.rows[this.state.tempId].RowKey}/vr`) }}
                >
                  VR Stream
                </Button>
              </Paper>
            )}
          </Grid>
          <Grid item lg={3} xs={12} sm={6}>
            <Log videoInfo={this.state.rows[this.state.tempId]} data={this.state.loadingRows === false ? tempInfo : undefined} />
          </Grid>
          <Grid item lg={3} xs={12} sm={6}>
            <Plot matchId={this.state.loadingRows === false ? this.state.rows[this.state.tempId] && this.state.rows[this.state.tempId].matchId : undefined} />
          </Grid>
          {this.state.delayOpen && checkDelayGraph ? (
            <Grid item lg={12} xs={12} sm={12}>
              <DelayGraph rowinfo={this.state.rows[this.state.tempId]} />
            </Grid>
          ) : null}
          <Grid item lg={12} xs={12} sm={12}>
            <Table
              resetData={this.handleGetCall.bind(this)}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              param={this.props.match}
              loadingData={this.state.loadingRows}
              handleEditRow={this.handleEditColum}
              addColum={AddColum}
              ref={this.tableChild}
            />
            <div style={{ float: 'right', width: '200px', marginTop: '-12px', marginRight: '30px', height: '24px', display: 'flex' }}>

              <>
                <Button disabled={!this.state.lastToken} color='primary' variant='contained' className='camera-btn' fullWidth onClick={this.handleNextGetCall.bind(this)}>mehr</Button>
                <Button disabled={!this.state.lastToken} color='primary' variant='contained' className='camera-btn' fullWidth onClick={this.handleAllGetCall.bind(this)}>alles</Button>
              </>

            </div>

            <div style={{ width: '400px', margin: '10px', display: 'flex', height: '30px', marginTop: '-50px', marginLeft: '22px' }}>
              <TextField
                className='textField-table'
                placeholder='RowKey'
                value={this.state.input}
                onChange={(event) => { this.setState({ input: event.target.value }) }}
                margin='none'
              />
              <Button
                fullWidth
                className='btn'
                color='primary'
                variant='contained'
                onClick={() => this.hanldeGetSingleVideo()}
                // disabled={this.state.loadingRows}
              >
                get video
              </Button>

              <Button
                style={{ marginLeft: 12 }}
                fullWidth
                className='btn'
                color='primary'
                variant='contained'
                onClick={() => this.handleClickOpen()}
                disabled={this.state.loadingRows}
              >
                Zeitraum
              </Button>

            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Games
