import React, { Component } from 'react';
import HeadText from '../../modules/static/tableHeading';
import Grid from '@material-ui/core/Grid';
import Table from '../../modules/static/table';

//import { getCellularList, getCookieLog } from '../../api/api';

const config = {
  tableName: 'gameTable',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'Id', title: 'Stick Id' },
    { name: 'SIM', title: 'SIM' },
    { name: 'Telephone', title: 'Telefon' },
    { name: 'InstallationDate', title: 'InstallationDate' },
    { name: 'IMEI_Stick', title: 'IMEI Stick' },
    { name: 'IMSI_SIM', title: 'IMSI_SIM' },
    { name: 'Mac', title: 'MAC' },
    { name: 'Modul_Typ', title: 'Modul Typ' },
    { name: 'Modul_hw_sw', title: 'Modul Hw and So' },
    { name: 'Provider_Contact', title: 'Provider Contact' },
    { name: 'APN', title: 'APN' }
  ],
  defaultHiddenColumnNames: [
    { label: 'Alle', value: [''] },
    { label: 'blaa', value: ['IMSI_SIM', 'APN', 'IMEI_Stick', 'Mac', 'Modul_Typ', 'Modul_hw_sw', 'Provider_Contact'] }
  ],
  defaultColumnWidths: [
    { columnName: 'Id', width: 80 },
    { columnName: 'SIM', width: 140 },
    { columnName: 'Telephone', width: 120 },
    { columnName: 'InstallationDate', width: 100 },
    { columnName: 'IMEI_Stick', width: 140 },
    { columnName: 'IMSI_SIM', width: 140 },
    { columnName: 'Mac', width: 140 },
    { columnName: 'Modul_Typ', width: 120 },
    { columnName: 'Modul_hw_sw', width: 210 },
    { columnName: 'Provider_Contact', width: 140 },
    { columnName: 'APN', width: 140 }
  ]
};

class Cellular extends Component {
  constructor(props) {
    super(props);

    this.state = {
      matches: [],
      rows: [],
      tempId: '0'
    };
  }

  handleId = (id) => {
    if (id != null) {
      this.setState({ matches: [], tempId: id });
    }
  };

  /**
   * Api-call get Cellular
   */
  componentDidMount() {
    this.handleGetCall();
  }

  handleGetCall() {
    /*getCellularList(getCookieLog()).then((res) => {
      this.setState({ matches: res.data });
    });*/
  }

  render() {
    if (this.state.matches.length < 0) {
      this.state.matches.splice(0, this.state.matches.length);
    }

    this.state.matches.map((n, index) => {
      this.state.rows.push({
        id: index,
        PartitionKey: n.PartitionKey,
        RowKey: parseInt(n.RowKey, 10),
        APN: n.APN,
        IMEI_Stick: n.IMEI_Stick,
        IMSI_SIM: n.IMSI_SIM,
        Id: n.Id,
        Mac: n.Mac,
        Modul_Typ: n.Modul_Typ,
        Modul_hw_sw: n.Modul_hw_sw,
        Provider_Contact: n.Provider_Contact,
        SIM: n.SIM,
        Telephone: n.Telephone,
        InstallationDate: n.InstallationDate
      });
      return null;
    });

    return (
      <div>
        <HeadText text={this.props.headingText} />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              resetData={this.handleGetCall.bind(this)}
              onSelectId={this.handleId}
              tabledata={config}
              tablerows={this.state.rows}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Cellular;
