import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Loading from '../../modules/static/loading'
import TableDialog from './table/tableDialog'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import TablerIdHanlde from './table/tableIdHandle'
import queryString from 'query-string'
import Select from '@material-ui/core/Select'
import ExportCsvCameraModule from './table/docketSearch'
import _ from 'lodash'

import { CSVLink } from 'react-csv'
import {
  FilteringState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  EditingState,
  SelectionState,
  IntegratedSelection,
  DataTypeProvider
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
  ColumnChooser,
  TableColumnVisibility,
  TableEditColumn,
  TableSelection,
  TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui'
import { Template, TemplatePlaceholder, Plugin, TemplateConnector } from '@devexpress/dx-react-core'
import { Cookies } from 'react-cookie'

import { RowsProvider } from './table/rows-provider'

const editColumnMessages = {
  addCommand: 'NEU',
  editCommand: 'Edit',
  deleteCommand: 'Entfernen',
  commitCommand: 'Speichern',
  cancelCommand: 'Abbrechen'
}

const pagingPanelMessages = {
  showAll: 'Alle',
  rowsPerPage: 'Zeilen pro Seite'
}

// this formattet boolean values to string
const BooleanFormatter = ({ value }) => value ? 'true' : 'false'
const BooleanEditor = ({ value, onValueChange }) => (
  <Select
    native
    value={value !== undefined ? value : ''}
    onChange={event => onValueChange(event.target.value)}
    style={{ width: '100%' }}
  >
    <option value='' />
    <option value>true</option>
    <option value={false}>false</option>
  </Select>
)
const BooleanTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    editorComponent={BooleanEditor}
    {...props}
  />
)

class TableLayout extends Component {
  constructor (props) {
    super(props)

    this.state = {
      config: {
        pageSizes: [10, 20, 50],
        pageSize: 10
      },
      booleanType: this.props.tableConfig.columns.map(n => {
        if (n.value === 'boolean') {
          return n.name
        }
        return undefined
      }).filter(n => { return n !== undefined }),
      dialogAddorEdit: true,
      rows: this.props.tableRows,
      selection: [0],
      tempId: 0,
      hiddencolum: [''],
      sorting: [],
      filters: this.props.filters,
      multiSelect: this.props.multiSelect || false,
      filterX: [],
      stopSaveVisi: false,
      defaultFilters: [],
      sortedRows: []
    }

    this.csvLink = React.createRef()

    this.getRowId = (row) => row.tableId
    this.changePageSize = (size) => this.setState({ config: { ...this.state.config, pageSize: size } })

    this.ClickRowComponent = this.ClickRowComponent.bind(this)
    this.changeAddedRows = this.changeAddedRows.bind(this)
    this.changeEditingRowIds = this.changeEditingRowIds.bind(this)
    this.changeMultiSelection = this.changeMultiSelection.bind(this)
  }

  componentDidMount () {
    document.title = this.props.tableConfig.tableDialogTitle + ' - Admintool Staige.tv'

    if (this.props.param !== undefined) {
      const values = queryString.parse(this.props.param.params.rows)
      const paramTemp = []
      Object.keys(values).map((n) => {
        if (n === 'openNotes') {
          if (this.props.handleNotesOpen !== undefined) {
            this.props.handleNotesOpen()
          }
        }

        return this.props.tableConfig.columns.map((x) => {
          if (x.name.toLowerCase() === n.toLowerCase()) {
            paramTemp.push({ columnName: x.name, value: values[n] || x.defaultValue })
          }
          return null
        })
      })
      this.setState({ defaultFilters: paramTemp })
    }

    // Load TableVisibility
    if (this.props.tableConfig.tableToolbar === true) {
      const cookies = new Cookies()
      cookies.get('saveColum-' + this.props.tableConfig.tableName)
      if (cookies.cookies['saveColum-' + this.props.tableConfig.tableName] !== undefined) {
        const tempCooki = JSON.parse(cookies.cookies['saveColum-' + this.props.tableConfig.tableName])
        this.setState({ hiddencolum: tempCooki })
      }
    }
  }

  componentWillUnmount () {
    // Save TableVisibility
    if (this.state.stopSaveVisi === false) {
      const cookies = new Cookies()
      cookies.set('saveColum-' + this.props.tableConfig.tableName, JSON.stringify(this.state.hiddencolum), { path: '/' })
    }
  }

  ClickRowComponent ({ row, ...restProps }) {
    return (
      <Table.Row
        {...restProps}
        onClick={() => {
          this.props.onSelectId(row.tableId)
        }}
      />
    )
  }

  changeMultiSelection (selection) {
    const oldSelection = this.state.selection
    const diffA = selection.filter((itm) => {
      return !oldSelection.includes(itm)
    })
    const diffB = oldSelection.filter((itm) => {
      return !selection.includes(itm)
    })
    const diff = diffA.concat(diffB)
    if (diff.length) {
      this.props.onSelectId(diff[0], selection)
    }
    this.setState({ selection })
  }

  handleIdFiltering = (value) => {
    this.setState({ selection: [value] })
    this.props.onSelectId(value)
  };

  commitChanges ({ added, changed }) {
    let { rows } = this.state
    if (added) {
      const startingAddedId = rows.length - 1 > 0 ? rows[rows.length - 1].tableId + 1 : 0
      rows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row
        }))
      ]
    }
    if (changed) {
      rows = rows.map((row) => (changed[row.tableId] ? { ...row, ...changed[row.tableId] } : row))
    }
    this.setState({ rows })
  }

  /*
   *  Open AddRow-Dilaog
   */
  changeAddedRows (addedRows) {
    this.setState({ dialogAddorEdit: true })
    this.refs.child.handleClickOpen()
  }

  /*
   *  Open Editing-Dilaog
   */
  changeEditingRowIds (editingRowIds) {
    var tempId = editingRowIds[editingRowIds.length - 1]
    this.setState({ editingRowIds, dialogAddorEdit: false, tempId: tempId, selection: [tempId] })
    this.refs.child.handleClickOpen()
  }

  changeRow (data, addCheck, removeRow) {
    // assign the editedRow in rows

    if (removeRow) {
      let rows = this.state.rows.filter(row => row.tableId !== removeRow.tableId)
      rows = rows.map((row, index) => {
        row.tableId = index
        return row
      })
      const mainSort = this.state.sorting
      this.setState({
        rows: rows,
        sorting: mainSort
      })
      return null
    }

    if (addCheck) {
      this.props.resetData()
    } else {
      const testid = data.row.tableId
      const rows = this.state.rows
      Object.keys(data.json).map(n => {
        rows[testid][n] = data.json[n]
        return null
      })

      // Reset LogoImg //////////////////////////////////////////////////////
      var el = document.getElementById('img-' + data.row.RowKey)
      if (el) {
        var timestamp = new Date().getTime()
        var queryString = '?t=' + timestamp
        el.src = rows[testid].thumbnail + queryString
      }
      /// ///////////////////////////////////////////////////////////////////

      const mainSort = this.state.sorting
      this.setState({
        rows: rows,
        sorting: [{ columnName: 'id', direction: 'asc' }]
      })
      this.setState({
        sorting: mainSort
      })
    }
  }

  handleChange = (name) => (event) => {
    const split = event.target.value.split(',')
    const temps = []

    for (let i = 0; i < split.length; i++) {
      temps.push(split[i])
    }

    this.setState({
      hiddencolum: temps,
      stopSaveVisi: true
    })
  };

  /**
   * Reload the Table, Checkt after handleReloadSite() on new props.
   * Filter option
   */
  componentDidUpdate (prevProps) {
    this.changeSelection = (selection) => {
      var temp

      if (this.state.selection.length > 0) {
        temp = selection[1]
      } else {
        temp = selection[0]
      }

      this.setState({ selection: [temp] })
      this.props.onSelectId(temp)
    }

    if (this.props.tableRows !== prevProps.tableRows) {
      const match = this.state.rows[this.state.selection[0]]
      let RowKey
      if (match) {
        RowKey = match.RowKey
      }

      this.props.tableRows.map(i => {
        if (RowKey !== undefined) {
          if (Number(i.RowKey) === Number(RowKey)) {
            this.setState({ selection: [i.tableId], tempId: i.tableId })
            this.props.onSelectId(i.tableId)
          }
        }
      })

      this.setState({
        rows: this.props.tableRows
      })
    }

    if (this.props.filters !== prevProps.filters) {
      this.setState({
        filters: this.props.filters
      })
    }
  }

  /**
   * Reload Table
   */
  handleReloadSite = () => {
    const filters = this.state.filterX.concat(this.state.filters).concat(this.state.defaultFilters).filter((f) => !!f)
    this.props.resetData(true)
    this.setState({ rows: [], defaultFilters: filters })
  }

  changeSorting (sorting) {
    this.setState({ sorting: sorting })
  }

  changeFilter (filter) {
    let tempFilter = ''
    const historyObj = window.history

    /**
     * Equal Filtering / Check of Number or String
     */
    if (filter.length > 0) {
      filter.map((n) => {
        this.props.tableConfig.columns.map((x) => {
          if (x.name === n.columnName) {
            if (x.value === 'number') {
              n.value = Number(n.value)
            }
            if (x.operation === 'equal') {
              n.operation = 'equal'
            }
          }
          return x
        })
        return n
      })
    }

    this.setState({ filters: filter, filtersX: filter })

    filter.map((n, index) => {
      if (index === filter.length - 1) {
        return (tempFilter = tempFilter + n.columnName + '=' + n.value)
      } else {
        return (tempFilter = tempFilter + n.columnName + '=' + n.value + '&')
      }
    })

    historyObj.replaceState(null, null, this.props.tableConfig.tableUrl + tempFilter.toLowerCase())
  }

  handleVisiblity (hiddencolum) {
    // Save TableVisibility
    if (this.state.stopSaveVisi === false) {
      const cookies = new Cookies()
      cookies.set('saveColum-' + this.props.tableConfig.tableName, JSON.stringify(hiddencolum), { path: '/' })
    }
    this.setState({
      hiddencolum: hiddencolum,
      stopSaveVisi: false
    })
  }

  setSortedRows = (rows) => {
    const checkEqualRows = _.isEqual(this.state.sortedRows.sort(), rows.sort())
    if (!checkEqualRows) {
      this.setState({
        sortedRows: rows,
        setGetterRows: false
      })
    }
  }

  fetchSortedRows = () => {
    this.setState({
      setGetterRows: true
    })

    setTimeout(function () {
      this.setState({
        setGetterRows: false
      })
      this.csvLink.current.link.click()
    }.bind(this), 500)
  }

  handleSetNeedsVerification = async (row) => {
    const check = await this.props.handleEditRow({ row, json: { SLNeedsVerification: false } })

    if (check) {
      const tempRows = this.state.tempRows.filter((n) => n.tableId !== row.tableId)
      const rows = this.state.rows.map((n) => {
        if (n.tableId === row.tableId) {
          n.SLNeedsVerification = false
        }
        return n
      })
      this.setState({
        tempRows: tempRows,
        rows: rows
      })
    }
  }

  changeSortingNeedsVerification = () => {
    if (this.state.sortingNeedsVerification) {
      this.setState({
        sortingNeedsVerification: false
      })
    } else {
      let rows = [...this.state.rows]
      rows = rows.filter(function (e) { return e.SLNeedsVerification })
      const tempRows = rows.map(row => {
        const needsVerification = (
          <a
            target='_blank'
            rel='noopener noreferrer'
            className='needsVerification-btn'
            // href={`/licenses/`}
            onClick={() => this.handleSetNeedsVerification(row)}
          >
              Freigeben
          </a>
        )
        return { ...row, needsVerification: needsVerification }
      })

      this.setState({
        tempRows: tempRows,
        sortingNeedsVerification: true
      })
    }
  }

  render () {
    const { selection } = this.state

    const config = []
    this.props.tableConfig.columns.map(row => {
      if (row.disabled !== true) {
        config.push(row)
      }

      return null
    })

    const resize = this.props.tableConfig.columns.map(col => {
      return { columnName: col.name, width: col.width || 100 }
    })

    if (this.state.sortingNeedsVerification) {
      config.unshift({ name: 'needsVerification', title: 'NeedsVerification', toCreate: false, editable: false, width: 140 })
      // resize.unshift({ columnName: 'needsVerification', width: 140 })
    }

    if (!this.state.rows.length && this.props.loadingData) return <Loading />
    const tableColumns = this.props.tableConfig.columns || []
    for (var i = 0, l = this.state.rows.length; i < l; i++) {
      var items = this.state.rows[i]
      var keys = Object.keys(items)
      for (var j = 0, k = keys.length; j < k; j++) {
        const config = tableColumns.find((c) => {
          return c.name === keys[j]
        })

        if (items[keys[j]] instanceof Array && items[keys[j]].length > 0 && keys[j] !== 'contingentAbos' && (config && config.value !== 'tagInput' && config.value !== 'tagInputCity' && config.value !== 'objectGeneric')) {
          this.state.rows[i][keys[j]] = items[keys[j]].join(',')
        }
      }
    }

    return (
      <Paper>
        <Grid ref={this.myRef} rows={!this.state.sortingNeedsVerification ? this.state.rows : this.state.tempRows} columns={config} getRowId={this.getRowId}>
          <PagingState defaultCurrentPage={0} defaultPageSize={10} onPageSizeChange={this.changePageSize} />
          <SortingState
            defaultSorting={[]}
            sorting={this.state.sorting}
            onSortingChange={this.changeSorting.bind(this)}
          />
          <PagingPanel pageSizes={this.state.config.pageSizes} messages={pagingPanelMessages} />
          <BooleanTypeProvider for={this.state.booleanType} />
          <EditingState
            onCommitChanges={this.commitChanges}
            onAddedRowsChange={this.changeAddedRows}
            onEditingRowIdsChange={this.changeEditingRowIds}
          />
          <FilteringState
            defaultFilters={this.state.defaultFilters}
            filters={this.state.filters}
            onFiltersChange={this.changeFilter.bind(this)}
          />
          <IntegratedFiltering />
          {this.state.setGetterRows ? <RowsProvider onRowsUpdate={this.setSortedRows} /> : null}
          <IntegratedSorting />
          <IntegratedPaging />
          <SelectionState
            selection={selection}
            onSelectionChange={this.state.multiSelect ? this.changeMultiSelection : this.changeSelection}
          />
          {this.props.hasRowOnclick ? <Table rowComponent={this.ClickRowComponent} /> : <Table />}

          {this.state.sortingNeedsVerification ? null : <TableColumnResizing defaultColumnWidths={resize} />}
          <TableHeaderRow showSortingControls />
          <TableColumnVisibility
            defaultHiddenColumnNames={this.state.hiddencolum}
            hiddenColumnNames={this.state.hiddencolum}
            onHiddenColumnNamesChange={this.handleVisiblity.bind(this)}
          />
          <TableFilterRow />

          {
            <Template name='root'>
              <TemplateConnector>
                {({ rows: filteredRows }) => {
                  let temp

                  if (filteredRows[0] !== undefined) {
                    temp = filteredRows[0].tableId
                  } else {
                    temp = 0
                  }

                  return (
                    <div>
                      <TablerIdHanlde handleIdFiltering={this.handleIdFiltering.bind(this)} handleId={temp} />
                      <TemplatePlaceholder />
                    </div>
                  )
                }}
              </TemplateConnector>
            </Template>
          }

          {this.props.tableConfig.tableToolbar ? (
            <Plugin name='Toolbar'>
              <Template name='header'>
                <div className='toolbar-table'>
                  <div className='toolbar-btn-left'>
                    <div className='btn-reload'>
                      {!this.props.loadingData ? (
                        <Button ariant='fab' onClick={this.handleReloadSite.bind(this)}>
                          <i className='fa fa-refresh' style={{ fontSize: '1.45em' }} />
                        </Button>
                      ) : (
                        <Button disabled ariant='fab' onClick={this.handleReloadSite.bind(this)}>
                          <i className='fa fa-refresh rotateItem' style={{ fontSize: '1.45em' }} />
                        </Button>
                      )}
                    </div>
                    <div className='btn-reload' style={{ marginLeft: '12px' }}>
                      <div>
                        {this.props.csvExportCamera ? (
                          <>
                            <ExportCsvCameraModule
                              tableConfig={this.props.tableConfig}
                              rows={this.state.rows}
                              setGetterRows={this.state.setGetterRows}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              ariant='fab'
                              disabled={this.state.rows.length <= 0 || this.state.setGetterRows}
                              onClick={this.fetchSortedRows}
                            >
                              <i className='fa fa-download' style={{ fontSize: '1.45em' }} />
                            </Button>
                            <CSVLink
                              data={this.state.sortedRows}
                              filename={this.props.tableConfig.tableName + '.csv' || 'table.csv'}
                              ref={this.csvLink}
                              className='hidden'
                              target='_blank'
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <div className='btn-reload' style={{ marginLeft: '12px' }}>
                      <div>
                        <Button
                          ariant='fab'
                          disabled={this.props.loadingData}
                          onClick={this.changeSortingNeedsVerification}
                        >
                          <i className={this.state.sortingNeedsVerification ? 'fa fa-calendar-check-o' : 'fa fa-calendar-minus-o'} style={{ fontSize: '1.45em' }} />
                        </Button>

                      </div>
                    </div>

                    <div className='tableColumnVisibility'>
                      <TextField
                        id='select-currency-native'
                        select
                        className=''
                        value={this.state.defaultHiddenColumn}
                        onChange={this.handleChange('currency')}
                        SelectProps={{
                          native: true
                        }}
                        margin='normal'
                      >
                        {this.props.tableConfig.defaultHiddenColumnNames.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </div>
                  </div>
                  <div className='toolbar-btn-right'>
                    <div>
                      <TemplatePlaceholder name='toolbarContent' />
                    </div>
                  </div>
                </div>
              </Template>
              <ColumnChooser />
            </Plugin>
          ) : (
            ''
          )}

          <TableDialog
            tableConfig={this.props.tableConfig}
            /* tableRows={this.props.tableRows} */
            tableRow={this.state.rows[this.state.tempId]}
            switch={this.state.dialogAddorEdit}
            handleEditRow={this.props.handleEditRow}
            handleCreateRow={this.props.handleCreateRow}
            handleDeleteRow={this.props.handleDeleteRow}
            addColum={this.props.addColum}
            editColum={this.props.editColum}
            ref='child'
            changeRow={this.changeRow.bind(this)}
            loadingCall={this.props.loadingCall}
            uploadProcess={this.props.uploadProcess}
            reloadData={this.handleReloadSite}
          />

          {this.props.tableConfig.tableAdd ? (
            <TableEditColumn showAddCommand showEditCommand width={80} messages={editColumnMessages} />
          ) : (
            <TableEditColumn showEditCommand width={80} messages={editColumnMessages} />
          )}
          {this.state.multiSelect ? <IntegratedSelection /> : null}

          {this.state.multiSelect ? (
            <TableSelection showSelectionColumn showSelectAll />
          ) : (
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
          )}
        </Grid>
        {this.props.loadingData ? <LinearProgress /> : null}
      </Paper>
    )
  }
}

export default TableLayout
