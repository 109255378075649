import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import { getMatchDelay } from '../../../api/api-delay'
import { TimeSeries, TimeRange } from 'pondjs'
import { ChartContainer, Resizable, ChartRow, YAxis, Charts, LineChart, styler } from 'react-timeseries-charts'

const style = styler([
  { key: 'grid', color: '#0D98BA', opacity: 1 },
  { key: 'user', color: '#800020', opacity: 1 }
])

class DelayBox extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error: false,
      series: undefined,
      range: undefined,
      max: 0,
      loading: true,
      btn: {
        stream: true,
        user: true
      }
    }
  }

  async componentDidMount () {
    let gridStream
    let userStream

    await getMatchDelay('gridStream', this.props.rowinfo.RowKey).then(res => {
      gridStream = res.data
    })

    await getMatchDelay('userStream', this.props.rowinfo.RowKey).then(res => {
      userStream = res.data
    })

    if (gridStream !== undefined & userStream !== undefined) {
      let points = []
      let temp = 0
      let tempMinMax = 0
      let tempMin = 0

      Object.keys(gridStream).forEach((itm) => {
        let time = new Date(null, null, null, null, itm, null, null).getTime()

        if (userStream[itm] !== undefined) {
          points.push([time, gridStream[itm], userStream[itm]])
          temp = userStream[itm]
        } else {
          points.push([time, gridStream[itm], temp])
        }
      })

      points.forEach(n => {
        if (tempMinMax < n[1]) {
          tempMinMax = n[1]
        }
        if (tempMinMax < n[2]) {
          tempMinMax = n[2]
        }

        if (tempMin > n[2]) {
          tempMin = n[2]
        }
        if (tempMin > n[2]) {
          tempMin = n[2]
        }
      })

      if (points.length > 0) {
        const series = new TimeSeries({
          name: 'stream',
          columns: ['time', 'grid', 'user'],
          points
        })

        const range = new TimeRange(
          new Date(points[0][0]),
          new Date(points[points.length - 1][0])
        )

        this.setState({
          series: series,
          range: range,
          error: false,
          max: tempMinMax,
          min: tempMin,
          loading: false
        })
      }
    } else {
      this.setState({
        error: true,
        loading: false
      })
    }
  }

  renderNoData () {
    return (
      <Typography component='div'>
        <div>
          <div className='telemetryPlot'>
            <p>Es liegen bisher noch keine Daten vor</p>
          </div>
        </div>
      </Typography>
    )
  }

  render () {
    const { series, range, tracker } = this.state
    let dateTime = new Date(tracker).toLocaleTimeString('de-DE')

    let btn = []

    if (this.state.btn.stream) {
      btn.push('grid')
    }
    if (this.state.btn.user) {
      btn.push('user')
    }

    if (this.state.loading) {
      return (
        <Paper style={{ display: 'flex', justifyContent: 'center', paddingTop: '76px', height: '116px' }}>
          <CircularProgress />
        </Paper>
      )
    }

    return (
      <Paper>
        {range ? (
          <div>
            <div className='layout-head' style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px 30px 5px 0px' }}>
              <button className={this.state.btn.stream ? 'seriesBtnDelay' : 'seriesBtnDelay-o'} style={{ backgroundColor: '#0D98BA' }} onClick={() => { this.setState({ btn: { stream: !this.state.btn.stream, user: this.state.btn.user } }) }}>Kamera Stream</button>
              <button className={this.state.btn.user ? 'seriesBtnDelay' : 'seriesBtnDelay-o'} style={{ backgroundColor: '#800020' }} onClick={() => { this.setState({ btn: { user: !this.state.btn.user, stream: this.state.btn.stream } }) }}>User Stream</button>
            </div>
            <Resizable>
              <ChartContainer
                title={tracker !== null && tracker !== undefined ? ('' + dateTime) : ('')}
                titleStyle={{ fill: '#555', fontWeight: 500 }}
                timeRange={range}
                format='%H:%M:%S'
                paddingRight={10}
                enablePanZoom
                onTimeRangeChanged={(timerange) => this.setState({ range: timerange })}
                onTrackerChanged={(tracker) => this.setState({ tracker })}
                trackerPosition={this.state.tracker}
                timeAxisTickCount={4}
              >
                <ChartRow height='150'>
                  <YAxis
                    id='stream'
                    min={this.state.min - 1}
                    max={this.state.max}
                    width='60'
                    label='Delay'
                    showGrid
                    // tickCount={3}
                    format={function (n) { return Number(n) + ' Min' }}
                  />
                  <Charts>
                    <LineChart
                      axis='stream'
                      series={series}
                      columns={btn}
                      style={style}
                    />
                  </Charts>
                </ChartRow>
              </ChartContainer>
            </Resizable>
          </div>
        ) : (
          this.renderNoData()
        )}
      </Paper>
    )
  }
}

export default DelayBox
