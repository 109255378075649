import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { apiList, getAuthorization, callAs } from './helper'
rax.attach()

export const getCompetitionInfo = async (id) => {
  return axios.get(discovery.API_COMPETITION + '/competition/' + id + callAs('admin'), { headers: { Authorization: await getAuthorization() } })
}

export const getCompetitions = async () => {
  return apiList(discovery.API_COMPETITION + '/competitions/', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const updateCompetition = async (json) => {
    return axios.post(discovery.API_COMPETITION + '/competition/' + json.RowKey + callAs('admin'), JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
  }
  
export const createCompetition = async (json) => {
  return axios.post(discovery.API_COMPETITION + '/competition/' + callAs('admin'), JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getLeagueInfo = async (id) => {
  return axios.get(discovery.API_COMPETITION + '/league/' + id + callAs('admin'), { headers: { Authorization: await getAuthorization() } })
}


export const getLeague = async () => {
  return apiList(discovery.API_COMPETITION + '/leagues/', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const updateLeague = async (json) => {
    return axios.post(discovery.API_COMPETITION + '/league/' + json.RowKey + callAs('admin'), JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
  }
  
export const createLeague = async (json) => {
  return axios.post(discovery.API_COMPETITION + '/league/' + callAs('admin'), JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}
