import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import VirtualTable from './table'

import { getTelemetryByType } from '../../../../api/api-metric'

class Systemtest extends Component {
  constructor (props) {
    super(props)

    this.state = {
      tableData: []
    }
  }

  componentDidMount () {
    this.apiCall(this.props.rowinfo.RowKey)
  }

  apiCall (RowKey) {
    getTelemetryByType(RowKey, 'systemtest').then((res) => {
      this.setState({
        tableData: res
      })
    })
  }

  render () {
    return (
      <Typography component='div'>
        <VirtualTable data={this.state.tableData} />
      </Typography>
    )
  }
}

export default Systemtest
