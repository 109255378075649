import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import CameraChart from './module/cameraChart'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import ErrorBox from './module/errorBox'
import ErrorBoxVariable from './module/errorBoxVariable'
import Setting from './module/settings/index'
import Search from './module/searchBox'
import Runnotes from './module/runnotes/index'
import PrioMatch from './module/priomatches/index'
import CamProblemList from './module/camProblemList'
import Slider from 'react-slick'
import Loading from '../static/loading'
import Guard from './module/guard'

import { getCameraSystemList } from '../../api/api-camera'
import { getClubs } from '../../api/api-club'
import { getAllTests } from '../../api/api-guard'

import {
  getDetectCameraActiveCheck,
  getDetectCameraOffline,
  getDetectCameraAwaitingGrid,
  getDetectCameraTtsCheck,
  getDetectNoPlantMatch
} from '../../api/api-server'

import { getLazyLoading, getLazyLoadingNext, header } from '../../api/helper'
import discovery from '@soccerwatch/discovery'

import './dashboard.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function SampleNextArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  )
}

class Home extends Component {
  constructor (props) {
    super(props)

    this.state = {
      counter: undefined,
      counterTest: undefined,
      counterState: undefined,
      counterPending: undefined,
      counterDev: undefined,
      connectionLost: undefined,
      noPower: undefined,
      cameraSystemList: undefined,
      guardTests: undefined,
      camOffline: [],
      camAwaitGrid: [],
      camTtsCheck: [],
      camActiveCheck: [],
      camLastError: [],
      camNoPlantMatch: [],
      ttsMatchCounter: 5,
      activeMatchCounter: 5,
      showSearch: false,
      loading: true
    }
  }

  handleCounterCamTestState (data) {
    const counter = { number: 0, active: 0, connection_lost: 0, defect: 0, pending: 0, no_power: 0, testsystem: 0 }
    data.map((n) => {
      // check lifecircleState DEV & PENDING
      const checkLifecircleState = n.lifecircleState === 'DEV' || n.lifecircleState === 'PENDING'
      // check systemState
      const checkSystemState = n.systemState !== 'NOT_EXISTING'

      if (checkLifecircleState && checkSystemState) {
        counter.number = counter.number + 1
        if (n.systemState !== undefined) {
          const name = n.systemState.toLowerCase()

          counter[name] = counter[name] + 1
        }
      }
      return null
    })
    return counter
  }

  handleCounterCamState (data, lifecircle) {
    const counter = { number: 0, active: 0, initial: 0, not_existing: 0, connection_lost: 0, defect: 0, pending: 0, no_power: 0, active_idle: 0, active_capturing: 0 }
    data.map((n) => {
      // check lifecircleState
      const checkLifecircleState = n.lifecircleState === lifecircle

      if (checkLifecircleState) {
        const name = n.systemState.toLowerCase()
        const task = n.currentTask.toLowerCase()
        if (name === 'active' && task === 'idle') {
          counter.number = counter.number + 1
          counter[name + '_idle'] = counter[name + '_idle'] + 1
        } else if (name === 'active' && task === 'capturing') {
          counter.number = counter.number + 1
          counter[name + '_capturing'] = counter[name + '_capturing'] + 1
        } else {
          counter.number = counter.number + 1
          counter[name] = counter[name] + 1
        }
      }
      return null
    })
    return counter
  }

  handleSearch (value) {
    if (value.status === 'INVALID_API_KEY') {
      this.setState({
        camLastError: [],
        showSearch: false
      })
    } else {
      this.setState({
        camLastError: value,
        showSearch: true
      })
    }
  }

  componentDidMount () {
    this.timer = setInterval(() => this.handleApiCall(), 120000)
    this.setState({
      setIntervalDash: this.timer
    })
    this.handleApiCall()
  }

  componentWillUnmount () {
    clearInterval(this.state.setIntervalDash)
  }

  camLost (name, res) {
    // CONNECTION_LOST
    const data = Array.from(res.filter(n => {
      return n.systemState === name
    }).sort(function (a, b) {
      return new Date(a.offlineSince) - new Date(b.offlineSince)
    }))

    data.map(n => {
      const dateOff = new Date(n.offlineSince)
      const dateNow = new Date()
      // get total seconds between the times
      var delta = Math.abs(dateOff - dateNow) / 1000
      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400)
      delta -= days * 86400
      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24
      delta -= hours * 3600
      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60
      delta -= minutes * 60

      let dateDif
      if (days > 0) {
        dateDif = days + 'd ' + hours + 'h ' + minutes + 'm'
      } else if (hours > 0) {
        dateDif = hours + 'h ' + minutes + 'm'
      } else if (minutes > 0) {
        dateDif = minutes + 'm'
      }

      n.offlineDifference = dateDif + ' (' + dateOff.toLocaleString() + ')'
      n.offlineDifferenceDays = days

      return null
    })

    return data
  }

  async handleApiCall () {
    let getCameraSystemListArray = {}
    let camOffline, camTtsCheck, camAwaitGrid, camActiveCheck, camNoPlantMatch, guardTests

    await this.getvideoList()

    if (this.props.swisscom) {
      await getCameraSystemList().then((res) => {
        // const counterTest = this.handleCounterCamTestState(res)
        const counterState = this.handleCounterCamState(res, 'PRODUCTIVE')
        const counterTest = this.handleCounterCamState(res, 'TESTSYSTEM')

        // CONNECTION_LOST & NO_POWER
        let connectionLost = this.camLost('CONNECTION_LOST', res)
        connectionLost = connectionLost.filter(itm => {
          return itm.lifecircleState === 'PRODUCTIVE' || itm.lifecircleState === 'TESTSYSTEM'
        })
        const noPower = this.camLost('NO_POWER', res)

        getCameraSystemListArray = {
          counterTest: counterTest,
          counterState: counterState,
          connectionLost: connectionLost,
          noPower: noPower,
          cameraSystemList: res
        }
      })

      let clubList
      await getClubs().then(res => {
        clubList = res
      })

      this.setState({
        counterTest: getCameraSystemListArray.counterTest,
        counterState: getCameraSystemListArray.counterState,
        connectionLost: getCameraSystemListArray.connectionLost,
        loading: false,
        clubs: clubList
      })
    } else {
      await getCameraSystemList().then((res) => {
        // const counterTest = this.handleCounterCamTestState(res)
        const counterState = this.handleCounterCamState(res, 'PRODUCTIVE')
        const counterTest = this.handleCounterCamState(res, 'TESTSYSTEM')
        const counterPending = this.handleCounterCamState(res, 'PENDING')
        const counterDev = this.handleCounterCamState(res, 'DEV')

        // CONNECTION_LOST & NO_POWER
        let connectionLost = this.camLost('CONNECTION_LOST', res)
        connectionLost = connectionLost.filter(itm => {
          return itm.lifecircleState === 'PRODUCTIVE' || itm.lifecircleState === 'TESTSYSTEM'
        })
        const noPower = this.camLost('NO_POWER', res)

        getCameraSystemListArray = {
          counterTest: counterTest,
          counterState: counterState,
          counterPending: counterPending,
          counterDev: counterDev,
          connectionLost: connectionLost,
          noPower: noPower,
          cameraSystemList: res
        }
      })

      // getLazyLoading(configArray, this.handleMounted.bind(this))

      /* await getDetectCameraOffline().then((res) => {
        if (res.data.status === 'INVALID_API_KEY') {
          res.data = []
        }

        camOffline = res.data
      }) */

      camOffline = []

      /* await getDetectCameraAwaitingGrid().then((res) => {
        if (res.data.status === 'INVALID_API_KEY') {
          res.data = []
        }
        camAwaitGrid = res.data
        this.setState({
          camAwaitGrid: res.data
        })
      }) */

      camAwaitGrid = []

      /* await getDetectCameraTtsCheck(this.state.ttsMatchCounter).then((res) => {
        if (res.data.status === 'INVALID_API_KEY') {
          res.data = []
        }

        camTtsCheck = res.data
      }) */

      camTtsCheck = []

      /* await getDetectCameraActiveCheck(this.state.activeMatchCounter).then((res) => {
        if (res.data.status === 'INVALID_API_KEY') {
          res.data = []
        }

        camActiveCheck = res.data
      }) */

      camActiveCheck = []

      /* await getDetectNoPlantMatch(this.state.activeMatchCounter).then((res) => {
        if (res.data === {}) {
          res.data = []
        }
        if (res.data.status === 'INVALID_API_KEY') {
          res.data = []
        }

        camNoPlantMatch = res.data
      }) */
      camNoPlantMatch = []
      await getAllTests().then(res => {
        if (res.data.status === 'INVALID_API_KEY') {
          res.data.data = undefined
        }

        guardTests = res.data.data
      }).catch((err) => {
        console.log('Error: ', err)
      })

      guardTests = []

      let clubList
      await getClubs().then(res => {
        clubList = res
      })

      const cameraClubInfoList = await this.updateClubs(clubList, getCameraSystemListArray.cameraSystemList)

      this.setState({
        counterTest: getCameraSystemListArray.counterTest,
        counterState: getCameraSystemListArray.counterState,
        counterPending: getCameraSystemListArray.counterPending,
        counterDev: getCameraSystemListArray.counterDev,
        connectionLost: getCameraSystemListArray.connectionLost,
        noPower: getCameraSystemListArray.noPower,
        cameraSystemList: getCameraSystemListArray.cameraSystemList,
        camOffline: camOffline,
        camAwaitGrid: camAwaitGrid,
        camTtsCheck: camTtsCheck,
        camActiveCheck: camActiveCheck,
        camNoPlantMatch: camNoPlantMatch,
        guardTests: guardTests || undefined,
        loading: false,
        clubs: clubList,
        cameraClubInfoList: cameraClubInfoList
      })
    }
  }

  getvideoList = async () => {
    const configArray = [{
      url: discovery.API_VIDEO + '/metas/',
      minEntrys: undefined,
      auth: header,
      handle: this.handleGetMatchList.bind(this),
      number: 4,
      intervalStop: 10
    }]

    await getLazyLoadingNext(configArray, (res) => { return res }, [], '')
  }

  handleGetMatchList (res, checkLast, lastToken) {
    if (checkLast) {
      this.setState({
        videoList: res || []
      })
    }
  }

  async updateClubs (clubs, camList) {
    clubs.sort((a, b) => {
      if (a.searchName < b.searchName) return -1
      if (a.searchName > b.searchName) return 1
      return 0
    })

    const tempHasSystem = []
    const finalHasSystem = []

    clubs.map((n) => {
      if (n.hasSystem === true) {
        tempHasSystem.push({ RowKey: n.RowKey, name: n.name })
      }
      return null
    })

    const temp = camList
    tempHasSystem.map((n) => {
      temp.map((x) => {
        if (n.RowKey === x.ownerClubId && x.ownerClubId !== '0') {
          finalHasSystem.push({ id: x.RowKey, name: n.name })
        }
        return null
      })
      return null
    })

    return finalHasSystem
  }

  handleCounterChild (name, value) {
    this.setState({
      [name]: value
    })

    if (name === 'ttsMatchCounter') {
      getDetectCameraTtsCheck(value).then((res) => {
        this.setState({
          camTtsCheck: res.data
        })
      })
    }

    if (name === 'activeMatchCounter') {
      getDetectCameraActiveCheck(value).then((res) => {
        this.setState({
          camActiveCheck: res.data
        })
      })
    }
  }

  render () {
    var settings = {
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    return (
      this.state.loading ? (<Loading paper={false} />) : (
        <div>
          <HeadText link text={this.props.headingText} />
          {this.props.swisscom ? (
            <>
              {this.state.counterTest !== undefined ? (
                <div style={{ margin: '0px -10px', paddingBottom: '14px' }}>
                  <Grid item xs={12} sm={12}>
                    {this.state.counterTest !== undefined ? (
                      <CameraChart headText='TESTSYSTEM' counter={this.state.counterTest} />
                    ) : null}
                  </Grid>
                </div>
              ) : (null)}
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12}>
                  <Grid container direction='column' spacing={24}>
                    {this.state.connectionLost ? (
                      <Grid item xs={12} sm={12}>
                        <CamProblemList headText='Connection Lost' type='connection_lost' data={this.state.connectionLost} clubs={this.state.clubs} />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {this.state.counterTest !== undefined || this.state.counterState !== undefined ? (
                <div style={{ margin: '0px -10px', paddingBottom: '14px' }}>
                  <Slider {...settings}>
                    <Grid item xs={12} sm={12}>
                      {this.state.counterState !== undefined ? (
                        <div style={{ padding: '10px' }}>
                          <CameraChart headText='PRODUCTIVE' counter={this.state.counterState} />
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {this.state.counterTest !== undefined ? (
                        <div style={{ padding: '10px' }}>
                          <CameraChart headText='TESTSYSTEM' counter={this.state.counterTest} />
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {this.state.counterPending !== undefined ? (
                        <div style={{ padding: '10px' }}>
                          <CameraChart headText='PENDING' counter={this.state.counterPending} />
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {this.state.counterDev !== undefined ? (
                        <div style={{ padding: '10px' }}>
                          <CameraChart headText='DEV' counter={this.state.counterDev} />
                        </div>
                      ) : null}
                    </Grid>
                  </Slider>
                </div>
              ) : (null)}

              <Grid container spacing={24}>
                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                  <Grid item xs={12} sm={4}>
                    <Grid item xs={12} sm={12} style={{ paddingBottom: '20px' }}>
                      <Setting
                        cameraSystemList={this.state.cameraSystemList}
                        clubs={this.state.clubs}
                        handleCounter={this.handleCounterChild.bind(this)}
                        headText='Weitere Einstellungen'
                        ttsMatchCounter={this.state.ttsMatchCounter}
                        activeMatchCounter={this.state.activeMatchCounter}
                        handleSearch={this.handleSearch.bind(this)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ paddingBottom: '20px' }}>
                      <ErrorBoxVariable
                        headText={'TTS Check ' + this.state.camTtsCheck.length}
                        data={this.state.camTtsCheck}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ErrorBoxVariable
                        headText={'Active Check ' + this.state.camActiveCheck.length}
                        data={this.state.camActiveCheck}
                      />
                    </Grid>
                  </Grid>
                </Hidden>
                <Grid item xs={12} sm={4}>
                  <Grid container direction='column' spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <Runnotes cameraClubInfoList={this.state.cameraClubInfoList} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <PrioMatch />
                    </Grid>
                    {this.state.guardTests ? (
                      <Grid item xs={12} sm={12}>
                        <Guard data={this.state.guardTests} />
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={12}>
                      <ErrorBox
                        headText={'Kein geplantes Spiel ' + this.state.camNoPlantMatch.length}
                        data={this.state.camNoPlantMatch}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ErrorBox headText={'Connections Error ' + this.state.camOffline.length} data={this.state.camOffline} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ErrorBox
                        headText={'AwaitingGrid Error ' + this.state.camAwaitGrid.length}
                        data={this.state.camAwaitGrid}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container direction='column' spacing={24}>
                    {this.state.showSearch ? (
                      <Grid item xs={12} sm={12}>
                        <Search headText={'Suche ' + this.state.camLastError.matches.length} data={this.state.camLastError} />
                      </Grid>
                    ) : null}
                    {this.state.connectionLost ? (
                      <Grid item xs={12} sm={12}>
                        <CamProblemList headText='Connection Lost' type='connection_lost' data={this.state.connectionLost} clubs={this.state.clubs} />
                      </Grid>
                    ) : null}
                    {this.state.noPower ? (
                      <Grid item xs={12} sm={12}>
                        <CamProblemList headText='No Power' type='no_power' data={this.state.noPower} clubs={this.state.clubs} />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Hidden only={['xs']}>
                  <Grid item xs={12} sm={4}>
                    <Grid item xs={12} sm={12} style={{ paddingBottom: '20px' }}>
                      <Setting
                        cameraSystemList={this.state.cameraSystemList}
                        clubs={this.state.clubs}
                        handleCounter={this.handleCounterChild.bind(this)}
                        headText='Weitere Einstellungen'
                        ttsMatchCounter={this.state.ttsMatchCounter}
                        activeMatchCounter={this.state.activeMatchCounter}
                        handleSearch={this.handleSearch.bind(this)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ paddingBottom: '20px' }}>
                      <ErrorBoxVariable
                        headText={'TTS Check ' + this.state.camTtsCheck.length}
                        data={this.state.camTtsCheck}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ErrorBoxVariable
                        headText={'Active Check ' + this.state.camActiveCheck.length}
                        data={this.state.camActiveCheck}
                      />
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </>
          )}

        </div>
      )
    )
  }
}

export default Home
