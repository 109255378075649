import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

export const CameraInfo = ({
  swcsID,
  trelloTickets,
  hardwarePlatform,
  cameraStatus
}) => (
  <div className='content-camera'>
    <div className='camera-headtext'>
      Kamera
    </div>
    <table className='infobox-table'>
      <tbody>
      <tr>
        <th>Kamera ID:</th>
        <td
          style={{ cursor: 'pointer', color: '#2888B7' }}
          onClick={() => { window.open('/cameras/uid=' + swcsID) }}
        >{swcsID}</td>
      </tr>
      <tr>
        <th>Hardware:</th>
        <td>{hardwarePlatform}</td>
      </tr>
      <tr>
        <th style={{ paddingBottom: '30px' }}>
          Kamerastatus:
        </th>
        <td style={{ paddingBottom: '26px' }}>
          {cameraStatus || <CircularProgress style={{ width: '18px', height: '18px' }} />}
        </td>
      </tr>
      <tr>
        {trelloTickets.length === 0 ? (
          <th>Keine Probleme bekannt</th>
        ) : <th>Bekannte Probleme:</th>}
      </tr>
      </tbody>
    </table>
    {trelloTickets.map((ticket, index) => {
      return (
        <div className='camera-ticket' key={index}>
          <div>{ticket.board} -</div>
          <div
            title={ticket.name}
            className='ticket-btn'
          >
            <a target='_blank' href={ticket.link}>
              {ticket.name}
            </a>
          </div>
        </div>
      )
    })}
  </div>
)
