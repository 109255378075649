import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'
rax.attach()

export const setSubscription = async function setSubscription (UserId, ClubId, validUntil) {
  const body = {
    validUntil
  }
  return axios.post(`${discovery.API_PAYMENT}/manual/subscription/${UserId}/${ClubId}`, body, { headers: { Authorization: await getAuthorization() } })
}

export const getPaywallTransactions = async function setSubscription (gameId) {
  return axios.get(`${discovery.API_PAYMENT}/paywall/transaction/${gameId}`, { headers: { Authorization: await getAuthorization() } })
}

export const getPaywallTransactionsRefund = async function setSubscription (id) {
  return axios.get(`${discovery.API_PAYMENT}/paywall/transaction/refund/${id}`, { headers: { Authorization: await getAuthorization() } })
}
