import React, { Component } from 'react'
import HeadText from '../static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import AiswSpinner from '../../common/AiswSpinner'
import DatePicker from 'react-datepicker'
import BBULog from '../bbu-log'
import Input from '@material-ui/core/Input'

import { firestore } from '../../common/firebase'
import { collection, getDocs } from 'firebase/firestore'

import './logs.css'

class LogTabs extends Component {
  constructor(props) {
      super(props)
      this.child = React.createRef()
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      this.state = {
        currentLogType: '',
        currecntRowKey: '',
        from: yesterday,
        till: new Date(),
        logTables: [],
        allLogs: false
      }
    }

    componentDidMount() {
      this.getLogTables()
    }

    getLogTables = async () => {
      const c = collection(firestore, 'Cloudlog')
      const data = await getDocs(c)
      const logTables = data.docs.map(d => d.data().type)
      logTables.push('Camera')
      logTables.push('Club')
      this.setState({ logTables, currentLogType: logTables[0] })
    }

    handleTableChange = (e) => {
      this.setState({ currentLogType: e.currentTarget.value })
    }

    handleRowKeyChange = (e) => {
      this.setState({ currecntRowKey: e.currentTarget.value })
    }

    handleSetFrom = (from) => {
      this.setState({ from })
    }

    handleSetTill = (till) => {
      this.setState({ till })
    }

    handleAllLogs = () => {
      this.setState({
        allLogs: !this.state.allLogs
      })
    }

    render() {
      return (
        <div className="logContainer">
          <HeadText text={this.props.headingText} />
          <Grid container spacing={24}>
            <Grid item xs={12}>
              {this.state.logTables.length !== 0 ?
                <div className="formRow">
                  <Select native value={this.state.currentLogType} onChange={this.handleTableChange}>
                    {this.state.logTables.map((tableName, index) => {
                      return (
                        <option key={tableName} value={tableName}>
                            {tableName}
                        </option>
                      )
                    })}
                  </Select>
                  <div className='formRow-input-id'>
                    <div style={{ fontSize: 16  }}>Id</div>
                    <Input
                      style={{ width: '100px', height: '24px' }}
                      label={'ID'}
                      value={this.state.currecntRowKey}
                      onChange={this.handleRowKeyChange}
                    />
                  </div> 


                  {this.state.allLogs ? null : (
                    <div className='formRow-input'>
                      <div>
                        Von <DatePicker showTimeSelect dateFormat='dd.MM.yyyy - hh:mm' selected={this.state.from} onChange={this.handleSetFrom} />
                      </div>
                      <div>
                        Bis <DatePicker showTimeSelect dateFormat='dd.MM.yyyy - hh:mm' selected={this.state.till} onChange={this.handleSetTill} />
                      </div>
                    </div>
                  )}
                 
               
                  <Button onClick={this.handleAllLogs} style={{ marginLeft: 'auto' }}>
                    {this.state.allLogs ? 'Logs nach Datum Anzeigen' : 'Alle Logs Anzeigen'}
                  </Button>
                </div> : <AiswSpinner />
              }

              <div className='Content'>      
                <BBULog type={this.state.currentLogType} rowkey={this.state.currecntRowKey} allLogs={this.state.allLogs} from={this.state.from.getTime()} till={this.state.till.getTime()} ></BBULog>
              </div>

            </Grid>
          </Grid>
        </div>
      )
    }
}

export default LogTabs
