import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Checkbox, FormControlLabel } from '@material-ui/core'

class HighlightDownload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      loading: false,
      rerender: true
    }
  }

  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open
      })

      if (!this.props.open) {
        setTimeout(() => {
          this.setState({
            loading: false,
            rerender: true
          })
        }, 200)
      }
    }
  }

  handleDownload = async () => {
    const rerenderUrl = `https://convert.soccerwatch.tv/${this.props.matchId}/summary/true`
    const url = 'https://convert.soccerwatch.tv/' + this.props.matchId

    if (this.state.rerender) {
      this.setState({
        loading: true
      })
      await fetch(rerenderUrl).then(res => {
        console.log(res)
      }).catch(error => {
        console.log(error)
      })

      this.setState({
        rerender: false,
        loading: false
      })
    }

    window.open(url, '_blank')
    this.props.onClose()
  }

  handleRerenderDownload () {
    this.setState({
      rerender: !this.state.rerender
    })
  }

  render() {
    const { open, loading } = this.state
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.props.onClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          disableBackdropClick={loading}
          maxWidth='xs'
          fullWidth          
        >
          {loading && <CircularProgress size={24} className='buttonProgress' style={{ position: 'absolute', top: '16px', right: '16px' }} />}
          <DialogTitle id='alert-dialog-title'>{'Download Highlight'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.rerender}
                    onChange={this.handleRerenderDownload.bind(this)}
                    color='primary'
                  />
                }
                label='Neu Rendern'
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={this.props.onClose} color='primary'>
              Abbrechen
            </Button>
            <Button disabled={loading} onClick={this.handleDownload} color='primary' autoFocus>
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default HighlightDownload
