import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'

rax.attach()

export const getSearchCity = async (searchValue) => {
  return axios.get(discovery.API_SEARCH + `/search?query=${searchValue}&kind=cityall&fuzzy=true&match=name`, { headers: { Authorization: await getAuthorization() } })
  // return axios.get(discovery.API_FANPAGE + '/cities' + '?q=' + searchValue + '&page=1', { headers: { Authorization: await getAuthorization() } })
}
