import React, { Component } from 'react'
import HeadText from '../static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../static/table'
import discovery from '@soccerwatch/discovery'
import { header, getLazyLoading } from '../../api/helper'

import './license.css'

import { Snackbar, Alert } from '@material-ui/core'
import { storageLayerTableInfo } from '../static/config/storageLayerTableConfig'
import { createLicense, deleteLicense, updateLicense } from '../../api/api-user'
import CustomLicenseDialog from './tableDialog/CustomLicenseDialog'

const config = {
  tableName: 'LicenseTable',
  tableUrl: '/license/',
  tableDialogTitle: 'License',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'RowKey', title: 'RowKey', operation: 'equal', editable: false, width: 90 },
    { name: 'PartitionKey', title: 'User Id', toCreate: true, operation: 'equal', editable: false, width: 90 },
    { name: 'type', title: 'Type', value: 'string', toCreate: true, width: 140 },
    { name: 'subType', title: 'Sub-Type', value: 'string', toCreate: true, width: 140 },
    { name: '_clubId', title: 'clubId', value: 'string', editable: false },
    { name: 'dataString', title: 'Data', value: 'string', editable: false, width: 200 },
    { name: '_contractId', title: 'contractId', value: 'string', editable: false },
    { name: 'DELETE', title: 'Delete', width: 70 },
    { name: 'activeUntil', title: 'Active Until', value: 'date', toCreate: true, width: 140 },
    { name: 'validUntil', title: 'Valid Until', value: 'date', toCreate: true, width: 90 },
    { name: 'paymentId', title: 'PaymentId', value: 'string', toCreate: true, width: 90 },
    { name: 'paymentType', title: 'Payment Type', value: 'string', toCreate: true, width: 90 },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class License extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clubs: [],
      rows: [],
      tempId: 0,
      check: false,
      loadingRows: true,
      loadingCall: false,
      error: undefined
    }

    this.handleGetLicenceList = this.handleGetLicenceList.bind(this)
    this.handleEditColum = this.handleEditColum.bind(this)
    this.handleAddColum = this.handleAddColum.bind(this)
    this.handleMounted = this.handleMounted.bind(this)
    this.handleGetCall = this.handleGetCall.bind(this)
    this.handleId = this.handleId.bind(this)
  }

  _isMounted = false;

  handleId(id) {
    if (id != null) {
      this.setState({ clubs: [], tempId: id })
    }
  };

  /**
   * Api-call get ClubList
   */
  componentDidMount() {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleMounted() {
    return this._isMounted
  }

  handleDelete = (Row) => async () => {
    const confirm = await window.confirm(`${Row.type} ${Row.RowKey} wirklich löschen?`)
    // console.log('Delete', Row.RowKey, Row.PartitionKey, Row)
    if (confirm) {
      deleteLicense(Row.RowKey, Row.PartitionKey)
    }
    this.handleGetCall()
  }

  handleGetLicenceList(res, checkLast) {
    const showUserLicenses = this.props.match && this.props.match.params && this.props.match.params.UserId
    // Sort and ADD Id by Rows
    res = res.sort((a, b) => {
      // return null
      return a.type.localeCompare(b.type)
      // return Number(a.RowKey) - Number(b.RowKey)
    }).map((row, index) => {
      index === 0 && console.log(row)
      row.tableId = index
      row.dataString = JSON.stringify(row.data)
      row._contractId = <a style={{ color: 'blue' }} href={`/contracts/rowkey=${row.contractId}`} target="_blank">{row.contractId}</a>
      row._clubId = row.data.club
      row.DELETE = <button onClick={this.handleDelete(row)}>Delete</button>
      return row
    }).filter((l) => {
      return !showUserLicenses || (showUserLicenses && l.type !== 'PaywallAccess' && l.type !== 'ClubOwnerLicense')
    })
    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

  handleGetCall(check) {
    if (check) {
      this.state.clubs.splice(0, this.state.clubs.length)
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }
    let url = this.props.match && this.props.match.params && this.props.match.params.UserId
      ? `${discovery.API_USER}/licensOfUser/${this.props.match.params.UserId}`
      : `${discovery.API_USER}/allLicenses/`
    if (this.props.licenseType) {
      url = `${discovery.API_USER}/licensesAllByType/${this.props.licenseType}/`
    }
    const configArray = [
      {
        url,
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetLicenceList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  async handleEditColum(data) {
    // Verification Logic in CustomLicenseDialog
    return this.saveData(data, false)
  }

  async handleAddColum(data) {
    // Verification Logic in CustomLicenseDialog
    return this.saveData(data, true)
  }

  async saveData(data, isCreate) {
    this.setState({ loadingCall: true })
    // console.log('Adding Data', data, 'NewData:', isCreate)
    const res = isCreate ? await createLicense(data) : await updateLicense(data)
    this.handleGetCall()
    this.setState({ loadingCall: false })
    return res
  }

  handleSnackbarClose = () => {
    this.setState({ error: undefined })
  }

  render() {
    return (
      <div>
        <HeadText text={this.props.headingText} />

        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              resetData={this.handleGetCall}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              param={this.props.match}
              loadingData={this.state.loadingRows}
              handleEditRow={this.handleEditColum}
              handleCreateRow={this.handleAddColum}
              loadingCall={this.state.loadingCall}
              addColum={CustomLicenseDialog}
              editColum={CustomLicenseDialog}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.error}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={this.state.error}
          action={this.handleSnackbarClose}
        />
      </div>
    )
  }
}

export default License
