import React, { Component, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TagInput from '../tagInput/TagInput'
// import Grid from '@material-ui/core/Grid'
// import TextField from '@material-ui/core/TextField'
// import Select from '@material-ui/core/Select'
// import moment from 'moment'
import { getTimeReport } from '@soccerwatch/common'
import './contractContingents.css'
import AiswSpinner from '../../../../../common/AiswSpinner'
import { getAllVideosOfContract } from '../../../../../api/api-video'
// import { TransferWithinAStationOutlined } from '@material-ui/icons'

const MONTH = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function SUM(sum, V) {
  return sum + V.durationMin
}

function MatchList(props) {
  const summedMinutes = props.videos.reduce(SUM, 0)
  return <div className="videoListContainer">
    <div className="videoListHeadline">{props.headline ? props.headline : 'Videos'}</div>
    <hr />
    <div className="videoList">
      {props.videos.map(v => {
        return <div className="videoListEntry">
          <div className="videoData">
            {new Date(v.expectedStartTime).toLocaleDateString("de")} - <a href={"/games/overview/rowkey=" + v.RowKey} target="_blank"><span className='highlight'>{v.RowKey}</span></a> - {v.durationMin}Min</div>
          <div className="videoSubData">
            Created {new Date(v.SLCreated).toLocaleDateString("de")} by <a href={"/user/uid=" + v.SLCreatedBy} target="_blank"><span className='highlight cutContent'>{v.SLCreatedBy}</span></a>
          </div>
        </div>
      })}
    </div>
    {props.videos.length &&
      <>
        <hr />
        <div className='summedMinutes'>{summedMinutes} Min</div>
      </>
    }
  </div>
}

function MatchLists(props) {
  return props.isOpen && <div className="matchLists">
    <MatchList headline="covered" videos={props.covered} />
    <MatchList headline="uncovered" videos={props.uncovered} />
  </div>

}

function ContingentList(props) {
  const [isOpen, setIsOpen] = useState(false)
  const [subIsOpen, setSubIsOpen] = useState(false)
  if (!props.data.covered || !props.data.uncovered) {
    console.error("Missing Data", props)
  }
  const headline = props.headline || "Missing Headline"
  const subHeadline = props.subHeadline || "Missing Subheadline"
  const covered = props.data.covered || []
  const coveredMinutes = covered.reduce(SUM, 0)
  const uncovered = props.data.uncovered || []
  const uncoveredMinutes = uncovered.reduce(SUM, 0)
  const contingentName = props.contingentName || "E_Contingent"
  const timeLeft = props.data.timeAvailable - props.data.timeUsed
  const timeAvailable = props.data.timeAvailable
  return <div className="ContingentList">
    <div className="ContingentListHeadline listHeadline" onClick={() => { setIsOpen(!isOpen) }}>
      <div className="ContingentListTitle">{headline} {isOpen ? '-' : '+'}</div>
      <div className="ContingentNameStats">
        <div className="Covered/Uncovered">
          Covered Videos:<span className={covered.length > 0 ? "green highlight" : "highlight"}>{covered.length} ({coveredMinutes} Min)</span> Uncovered: <span className={uncovered.length > 0 ? "red highlight" : "highlight"}>{uncovered.length} ({uncoveredMinutes} Min)</span>
        </div>
        <div className="TimeLeft">
          {contingentName}:
          {
            timeAvailable > 0 ? <>
              <progress value={timeLeft} max={timeAvailable}></progress> {timeLeft}/{timeAvailable} Min
            </> : "N/A"
          }
        </div>

      </div>
    </div>
    <MatchLists isOpen={isOpen} covered={covered} uncovered={uncovered} />
    {props.children && props.children.length && <div className="subList">
      <div className="subListHeadline listHeadline" onClick={() => { setSubIsOpen(!subIsOpen) }}>{subHeadline} {subIsOpen ? '-' : '+'}</div>
      <div className="subList">
        {subIsOpen && props.children}
      </div>
    </div>
    }
  </div>
}

function TimeReport(props) {
  return <ContingentList headline="Free" data={props.data.result} subHeadline="Years" contingentName="Free_Time">
    {/* Children are only Rendered if isOpen on ContingentList is true */}
    {
      props.data.mapsByYear && Object.entries(props.data.mapsByYear).map(([keyYear, valYear]) => {
        return <ContingentList key={keyYear} headline={keyYear} subHeadline="Months" data={valYear.result} contingentName="Yearly_Time" >
          {Object.entries(valYear.mapsByMonth).map(([key, val]) => {
            return <div key={keyYear + '_' + key} className='Month'>
              <div className="MonthHeadline">{[MONTH[key]]}</div>
              <ContingentList headline={"Transferable"} data={val || {}} contingentName="Monthly_Time" />
              <ContingentList headline={"Single Use"} data={val.singleUseContingent || {}} contingentName="Monthly_Time(SU)" />
            </div>
          })}
        </ContingentList>
      })
    }
  </ContingentList>

}

class ContractContingents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openOverlay: false,
      loading: false,
      videos: [],
      year: undefined,
      month: undefined,
      monthSingle: undefined,
      free: undefined,
      timeReport: undefined,
      showDetails: false
    }
    this.loadContractVideos()
  }

  loadContractVideos = async () => {
    this.setState({ loading: true })
    if (!this.props.editRow.RowKey) {
      console.error('Can not get Videos of Contract without RowKey')
      return
    }
    const today = new Date()
    const contingents = this.props.editRow.contingentAbos
    const videos = await getAllVideosOfContract(this.props.editRow.RowKey)
    const timeReport = getTimeReport(videos, contingents, today)
    const free = timeReport.result
    let year = timeReport.mapsByYear[today.getFullYear()]
    const month = year.mapsByMonth[today.getMonth()]
    const monthSingle = month.singleUseContingent
    year = year.result
    this.setState({ loading: false, free, year, month, monthSingle, timeReport })
  }

  handleOpen = () => {
    this.setState({ openOverlay: true })
  }

  handleClose = () => {
    this.setState({ openOverlay: false })
  }

  toggleDetails = () => {
    const showDetails = !this.state.showDetails
    this.setState({ showDetails })
  }

  render() {
    return (
      <div>
        <Dialog fullWidth open={this.state.openOverlay} onClose={this.handleClose} aria-labelledby='alert-dialog-title'>
          <DialogTitle id='alert-dialog-title'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {this.props.editRow.RowKey}:{this.props.editRow.name}
            </div>
          </DialogTitle>
          <DialogContent>
            {this.state.loading && <AiswSpinner />}
            {!this.state.loading &&
              <div className='contingents'>
                {this.state.monthSingle && !!this.state.monthSingle.timeAvailable &&
                  <div>
                    MonthSingle: <progress value={this.state.monthSingle.timeAvailable - this.state.monthSingle.timeUsed} max={this.state.monthSingle.timeAvailable} />  {this.state.monthSingle.timeAvailable - this.state.monthSingle.timeUsed} / {this.state.monthSingle.timeAvailable} Minutes left
                  </div>
                }
                {this.state.month && !!this.state.month.timeAvailable &&
                  <div>
                    Month: <progress value={this.state.month.timeAvailable - this.state.month.timeUsed} max={this.state.month.timeAvailable} /> {this.state.month.timeAvailable - this.state.month.timeUsed} / {this.state.month.timeAvailable} Minutes left
                  </div>}
                {this.state.year && !!this.state.year.timeAvailable &&
                  <div>
                    Year: <progress value={this.state.year.timeAvailable - this.state.year.timeUsed} max={this.state.year.timeAvailable} /> {this.state.year.timeAvailable - this.state.year.timeUsed} / {this.state.year.timeAvailable} Minutes left
                  </div>}
                {this.state.free && !!this.state.free.timeAvailable &&
                  <div>
                    Free: <progress value={this.state.free.timeAvailable - this.state.free.timeUsed} max={this.state.free.timeAvailable} /> {this.state.free.timeAvailable - this.state.free.timeUsed} / {this.state.free.timeAvailable} Minutes left
                  </div>}
                <div className="listHeadline" onClick={this.toggleDetails}>Details</div>
                {this.state.showDetails && this.state.timeReport && <TimeReport data={this.state.timeReport} />
                }
                {(!this.state.free || !this.state.free.timeAvailable) && (!this.state.year || !this.state.year.timeAvailable) && (!this.state.month || !this.state.month.timeAvailable) && (!this.state.monthSingle || !this.state.monthSingle.timeAvailable) &&
                  <div>No available Contingents in Contract</div>
                }
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button disabled={this.state.loading} onClick={this.handleClose} color='primary'>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          onClick={() => this.handleOpen()}
          color='primary'
          variant='contained'
          className='camera-btn'
          style={{ alignSelf: 'center' }}
        >
          Kontingente Anzeigen
        </Button>
      </div>
    )
  }
}

export default ContractContingents
