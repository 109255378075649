import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import CircularProgress from '@material-ui/core/CircularProgress'

const MyResponsiveBar = (data) => (
  <ResponsiveBar
    data={data}
    maxValue={120}
    keys={['start', 'end']}
    indexBy='RowKey'
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    groupMode='grouped'
    colors={/* { scheme: 'nivo' } */ (color) => {
      if (color.id === 'start') {
        return color.data.startColor
      } else {
        return color.data.endColor
      }
    }}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Match ID´s',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Zeit (min)',
      legendPosition: 'middle',
      legendOffset: -40
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', '1.3']] }}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    animate
    motionStiffness={90}
    motionDamping={15}
  />
)

export const UploadInfo = ({ uploadInfo, checkMatchDone }) =>
  <div className='content-upload'>
    <div className='upload-headtext'>
      Verzögerungen Upload
    </div>

    {uploadInfo === undefined ? (
      <div className='upload-content'>
        <CircularProgress style={{ width: '40px', height: '40px' }} />
      </div>
    ) : null}

    {checkMatchDone ? (
      uploadInfo && uploadInfo.length > 0 ? (
        <div className='upload-helptext'>
          <p>Die blauen Balken geben die geschätzte Verzögerung des kommenden Spiels in Minuten an.</p>
          <p>Daneben werden die Verzögerungen der letzten Spielen angezeigt.</p>
          <p>Es wird jeweils die Verzögerung zu Start und Ende des Spiels angegeben.</p>
        </div>
      ) : null
    ) : (
      uploadInfo && uploadInfo.length > 0 ? (
        <div className='upload-helptext'>
          <p>Die Übersicht zeigt Verzögerungen bei vergangenen Spielen in Minuten an.</p>
          <p>Es wird jeweils die Verzögerung zu Start und Ende des Spiels angezeigt.</p>
        </div>
      ) : null
    )}

    {uploadInfo && uploadInfo.length > 0 ? (
      <div className='upload-graph'>
        {MyResponsiveBar(uploadInfo)}
      </div>
    ) : null}

    {uploadInfo && uploadInfo.length === 0 ? (
      <div className='upload-content'>
        Keine Daten vorhanden
      </div>
    ) : null}

  </div>
