import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'

rax.attach()

export const getAllTests = async () => {
  return axios.get(discovery.API_GUARD + '/guardTestsFull/', { headers: { Authorization: await getAuthorization() } })
}
