import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { apiList, apiListStatus, apiListFilterTelemetry, getAuthorization } from './helper'

rax.attach()

export const getCameraStatusLog = async (cameraId, number) => {
  return apiList(discovery.API_METRIC + '/cameraStatusLog/' + cameraId, number, { headers: { Authorization: await getAuthorization() } })
}

export const getTelemetryByType = async (cameraId, type) => {
  return apiList(discovery.API_METRIC + '/telemetryByType/' + cameraId + '/' + type + '/', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const getTelemetryCheckup = async (cameraId) => {
  return apiList(discovery.API_METRIC + '/telemetryByType/' + cameraId + '/checkup', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const getTelemetryStreaming = async (cameraId) => {
  return apiList(discovery.API_METRIC + '/telemetryByType/' + cameraId + '/streaming', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const getTelemetrySystemtest = async (cameraId) => {
  return apiList(discovery.API_METRIC + '/telemetryByType/' + cameraId + '/system_test', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const getLog = async (videoId) => {
  return apiList(discovery.API_METRIC + '/log/' + videoId, undefined, { headers: { Authorization: await getAuthorization() } })
}

export const getLogMinEntry = async (videoId, minEntry) => {
  return apiList(discovery.API_METRIC + '/log/' + videoId, minEntry, { headers: { Authorization: await getAuthorization() } })
}

export const getDocket = async (pk) => {
  return apiListStatus(discovery.API_METRIC + '/docket/' + pk, undefined, { headers: { Authorization: await getAuthorization() } })
}

export const getLastDockets = async () => {
  return apiListStatus(discovery.API_METRIC + '/newestDockets/', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const deleteDocket = async (pk, rk) => {
  return axios.delete(discovery.API_METRIC + '/deleteDocket/' + pk + '/' + rk + '/', { headers: { Authorization: await getAuthorization() } })
}

export const postDocket = async (json, pk) => {
  return axios.post(discovery.API_METRIC + '/docket/' + pk + '/', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getTelemetrySinc = async (since, id, matchId) => {
  if (since < new Date('2019-03-11T00:00:00').getTime()) {
    return []
  }

  return apiListFilterTelemetry(
    `${discovery.API_METRIC}/telemetrySince/${id}/${since}/`,
    { headers: { Authorization: await getAuthorization() } },
    matchId
  )
}

export const getTelemetryCeckupToday = async (id) => {
  let response = []
  const since = new Date().setHours(0, 0, 0, 0)

  try {
    response = (
      await axios.get(
        `${discovery.API_METRIC}/telemetrySince/${id}/${since}/`,
        { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } }
      )).data
    return response.data
  } catch (err) {
    return Promise.reject(err)
  }
}
