import React from 'react'

import './AiswSpinner.css'

export const AiswSpinner = (props) => {
  return (
    <div className={`s-spinner ${props.className}`}>
      <div className='s-spinner__left' />
      <div className='s-spinner__right' />
    </div>
  )
}

export default AiswSpinner
