import React from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign, faInfo, faComment } from '@fortawesome/free-solid-svg-icons'

export const createCard = (match, index, onClick) => {
  moment.locale('de')
  const time = moment(match.startTime).format('LT')
  const date = moment(match.startTime).format('L')

  return (
    <div className='inner-box' key={index} onClick={() => { onClick(match.index) }}>
      <div title='Match ID' className='box-matchId'>
        ID: {match.RowKey}
      </div>

      <div className='box-info'>
        <div title='Status' className='info-status' style={{ backgroundColor: match.cardColor }}>
          <FontAwesomeIcon icon={faInfo} />
        </div>

        {match.priority ? (
          <div title='Priorität' className='info-paywall'>
            <p>{match.priority}</p>
          </div>
        ) : null}
        {match.paywall ? (
          <div title='Paywall' className='info-paywall'>
            <FontAwesomeIcon icon={faEuroSign} />
          </div>
        ) : null}
      </div>
      <div className='box-head'>
        <div title={match.clubAName} className='head-club'>
          {match.clubAName} <br /> <div className='club-id'>{match.clubAId}</div>
        </div>
        vs
        <div title={match.clubBName} className='head-club'>
          {match.clubBName}  <br /> <div className='club-id'>{match.clubBId}</div>
        </div>
      </div>
      <div className='box-content'>
        {match.videoType ? match.videoType : '...'}
      </div>
      <div className='box-footer'>
        {date} - {time}Uhr

      </div>
      {match.newMsgNumber ? (
        <div className='box-msgCounter'>
          <div className={match.newMsg ? 'msgCounter-puls' : 'msgCounter-inner'}>
            <FontAwesomeIcon icon={faComment} /> {match.newMsgNumber}
          </div>
        </div>
      ) : null}
    </div>
  )
}
