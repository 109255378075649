import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import OpenInBrowser from '@material-ui/icons/OpenInBrowser'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextFormatIcon from '@material-ui/icons/TextFormat'
import TextFormatOutlined from '@material-ui/icons/WrapText'
import discovery from '@soccerwatch/discovery'
import { getLazyLoading, getLazyLoadingNext, header } from '../../../api/helper'
import { isBoolean } from 'lodash'

import '../game.css'
import moment from 'moment'

class Log extends Component {
  constructor (props) {
    super(props)

    this.state = {
      id: 0,
      logs: [],
      checkLog: false,
      open: false,
      openFormatText: {}
    }
  }

  _isMounted = false

  componentDidMount () {
    this._isMounted = true
  }

  handleMounted = () => {
    return this._isMounted
  }

  async componentDidUpdate () {
    if (this.props.data !== undefined && this.props.data[1] && this.state.id !== this.props.data[1]) {
      this.setState({
        id: this.props.data[1],
        logs: [],
        checkLog: false,
        lastToken: undefined
      })
      this.handleGetData()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleGetData (nextRound, intervalStop, url) {
    const configArray = [{
      url: url || discovery.API_METRIC + '/log/' + this.props.data[1] + '/',
      minEntrys: undefined,
      auth: header,
      handle: this.handleGetMatchList,
      number: 4,
      intervalStop: intervalStop || 0
    }]

    if (nextRound) {
      getLazyLoadingNext(configArray, this.handleMounted, this.state.logs, this.state.lastToken)
      this.setState({ lastToken: undefined })
    } else {
      getLazyLoading(configArray, this.handleMounted)
    }
  }

  onlyUnique (value, index, self) {
    return self.indexOf(value) === index
  }

  handleGetMatchList = (res, checkLast, lastToken) => {
    if (this.state.logs.length > 0) {
      res = res.concat(this.state.logs)
      res = res.filter(this.onlyUnique)
    }

    res = res.filter(itm => itm.PartitionKey === String(this.props.data[1])).sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp))

    let sortLogs = res.reverse()
    sortLogs = sortLogs.map(itm => {
      if (itm.msg) {
        itm.msg = String(itm.msg)
      }
      return itm
    })

    if (this.props.data[1] === this.state.id) {
      if (checkLast === true) {
        this.setState({
          logs: sortLogs,
          loadingRows: false,
          lastToken: !isBoolean(lastToken) ? lastToken : undefined,
          checkLog: true
        })
      } else {
        this.setState({
          logs: sortLogs,
          lastToken: !isBoolean(lastToken) ? lastToken : undefined,
          checkLog: true
        })
      }
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false, openFormatText: {} })
  }

  hanldeFormatPosition = (index) => {
    let value = true
    if (this.state.openFormatText[index]) {
      value = false
    }

    const openFormatText = { ...this.state.openFormatText, [index]: value }
    this.setState({
      openFormatText
    })
  }

  isJsonString (str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  renderTable = (className, textFormat = false, overlay = false) => {
    return (
      <div className={this.state.checkLog ? className : null}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> ID </TableCell>
              <TableCell> Zeitpunkt </TableCell>
              <TableCell> typ </TableCell>
              <TableCell> Status </TableCell>
              {
                overlay ? (
                  <TableCell style={{ padding: 0 }} />
                ) : null
              }
              <TableCell> Message </TableCell>
              <TableCell> Error / Other </TableCell>
              <TableCell> tts </TableCell>
              <TableCell> Uploadrate </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.logs.map((n, index) => {
              const cleanMsg = this.isJsonString(n.msg) ? JSON.parse(n.msg) : n.msg
              let msg = JSON.stringify(cleanMsg, undefined, 2)
              // let msg = ''
              if (!textFormat || !this.state.openFormatText[index]) {
                msg = n.msg
              }

              return (
                <TableRow
                  key={n.RowKey} style={{
                    whiteSpace: 'break-spaces',
                    wordBreak: 'normal'
                  }}
                >
                  <TableCell style={{ verticalAlign: 'top', padding: 12 }}>{n.id}</TableCell>
                  <TableCell style={{ verticalAlign: 'top', padding: 12 }}>{n.Timestamp}</TableCell>
                  <TableCell style={{ verticalAlign: 'top', padding: 12 }}>{n.type}</TableCell>
                  <TableCell style={{ verticalAlign: 'top', padding: 12 }}>{n.state}</TableCell>
                  {
                    overlay ? (
                      <TableCell style={{ verticalAlign: 'top', padding: 0 }}>
                        <IconButton aria-label='open' onClick={() => this.hanldeFormatPosition(index)}>
                          {!this.state.openFormatText[index] ? (<TextFormatIcon fontSize='small' />) : (<TextFormatOutlined fontSize='small' />)}
                        </IconButton>
                      </TableCell>
                    ) : null
                  }
                  <TableCell style={{ verticalAlign: 'top', padding: 12 }}>
                    {msg}
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top', padding: 12 }}>
                    {n.other}
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top', padding: 12 }}>{n.tts}</TableCell>
                  <TableCell style={{ verticalAlign: 'top', padding: 12 }}>{n.uploadRate}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }

  handleNextGetCall = () => {
    this.handleGetData(true)
  }

  handleAllGetCall = () => {
    this.handleGetData(true, -1)
  }

  render () {
    let disabledVmLogs = true
    let href = ''
    if (this.props.videoInfo) {
      const then = moment(this.props.videoInfo.expectedStartTime).subtract(10, 'minutes').toISOString()
      const to = moment(this.props.videoInfo.expectedStartTime).add(this.props.videoInfo.durationMin + 60, 'minutes').toISOString()
      if (moment().isAfter(then)) {
        disabledVmLogs = false
        href = `https://console.cloud.google.com/logs/query;query=resource.type%3D%22gce_instance%22%0A%22clone-${this.props.videoInfo.RowKey}%22;timeRange=${then}%2F${to}?project=aisw-ww-ai`
      }
    }

    return (
      <Paper className='game-layout'>
        <div className='layout-head' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '20px' }}>
          <div style={{ width: '33%', textAlign: 'left' }}>
            <IconButton aria-label='open' onClick={this.handleClickOpen}>
              <OpenInBrowser fontSize='small' />
            </IconButton>
          </div>

          <div style={{ width: '33%' }}>
            LOGS
          </div>
          <div style={{ width: '33%', textAlign: 'right' }}>
            <IconButton
              disabled={disabledVmLogs}
              aria-label='open'
              onClick={() => window.open(href, '_blank')}
              style={{ fontSize: 14, fontWeight: 'bold' }}
            >
              VM Logs
            </IconButton>
          </div>
        </div>
        {this.state.checkLog && this.props.data !== undefined ? (
          this.renderTable('layout-table', false)
        ) : (
          ''
        )}

        <Dialog
          fullWidth
          maxWidth='xl'
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='LOGS OPEN'
        >
          <DialogTitle id='LOGS OPEN'>LOGS   ID: {this.state.id}</DialogTitle>
          <DialogContent>
            {this.state.open ? this.renderTable(undefined, true, true) : null}
          </DialogContent>
          <DialogActions>
            {this.state.lastToken !== undefined ? (
              <Button
                onClick={this.handleAllGetCall}
                color='primary'
              >
                Alle Logs
              </Button>
            ) : null}

            {this.state.lastToken !== undefined ? (
              <Button
                onClick={this.handleNextGetCall}
                color='primary'
              >
                Mehr Logs
              </Button>
            ) : null}

            <Button
              onClick={this.handleClose}
              color='primary'
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    )
  }
}

export default Log
