import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'

class Loading extends Component {
  render () {
    return (
      <div>
        {this.props.paper === false ? (
          <div className='loading'>
            <CircularProgress size={100} thickness={3} />
          </div>
        ) : (
          <Paper>
            <div className='loading'>
              <CircularProgress size={100} thickness={3} />
            </div>
          </Paper>
        )}
      </div>
    )
  }
}

export default Loading
