import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import ReactPlayer from 'react-player'

class InfoContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openVideo: false
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.rowinfo !== prevProps.rowinfo) {
      let media = false
      if (this.props.rowinfo && this.props.rowinfo.mediaType === 'video') {
        media = true
      }
      this.setState({
        url: this.props.rowinfo !== undefined ? this.props.rowinfo.mediaUrl : undefined,
        openVideo: media
      })
    }
  }

  render () {
    return (
      <Paper className='game-layout' style={{ backgroundColor: '#1e1e24' }}>
        <div className='layout-head' style={{ backgroundColor: '#fff' }}>
          Vorschau
        </div>

        <div style={{ maxWidth: '100%', margin: 'auto' }}>
          {this.props.rowinfo ? (
            <div className='prevbox'>
              <div className='prevbox-header'>
                {this.state.openVideo ? (
                  <ReactPlayer
                    width='100%'
                    height='170px'
                    url={this.state.url}
                    controls
                  />
                ) : (
                  <img
                    src={this.props.rowinfo.mediaUrl || 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_noimg_288x73.png'}
                    alt='no-thumbnail'
                    style={{ width: '100%' }}
                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_noimg_288x73.png' }}
                  />
                )}

              </div>
              <div className='prevbox-content'>
                <div className='prevbox-content-name'>{this.props.rowinfo.title}</div>
                <div className='prevbox-content-desc'>{this.props.rowinfo.subTitle || '...'}</div>
              </div>
            </div>

          ) : (null)}
        </div>
      </Paper>
    )
  }
}

export default InfoContainer
