import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import ReactPlayer from 'react-player'

import './static.scss'
import { Cookies } from 'react-cookie'
import { Checkbox, Switch } from '@material-ui/core'

class VideoPlayer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      soundVolume: undefined,
      playerSize: undefined,
      tempVideo: undefined,
      autoPlay: true
    }
  }

  componentDidMount () {
    const cookies = new Cookies()
    this.setState({
      autoPlay: cookies.get('autoPlay') === 'true'
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.url !== prevProps.url) {
      var tempVideo = this.props.url
      let soundVolume = 1
      let playerSize = '350px'
      var xmlHttp
      if (tempVideo !== undefined) {
        xmlHttp = new XMLHttpRequest() // eslint-disable-line
        xmlHttp.open('GET', tempVideo, false)

        try {
          xmlHttp.send(null)
        } catch (err) {
          tempVideo = ''
        }

        if (xmlHttp.status >= 400) {
          playerSize = '350px'
          const random = Math.floor(Math.random() * 1000 + 1)
          if (random === 999) {
            // "Best 3 minutes of my live"
            tempVideo = 'https://www.youtube.com/watch?v=DLzxrzFCyOs'
            soundVolume = 0.1
          } else {
            soundVolume = 0.01
            tempVideo = 'https://www.youtube.com/watch?v=4YKpBYo61Cs'
            // Turn on those extra Boosters !
            if (new Date().getHours() > 20 || new Date().getHours() < 7) {
              tempVideo = 'https://youtu.be/A0FZIwabctw?t=48'
              soundVolume = 0.1
            }
          }
        } else {
          soundVolume = 1
          playerSize = ''
        }
      }

      this.setState({
        soundVolume: soundVolume,
        playerSize: playerSize,
        tempVideo: tempVideo
      })
    }
  }

  handleSwitchAutoPlay = () => {
    const cookies = new Cookies()
    cookies.set('autoPlay', !this.state.autoPlay)
    this.setState({
      autoPlay: !this.state.autoPlay
    })
  }

  render () {
    const { soundVolume, tempVideo, playerSize } = this.state

    return (
      <Paper className='game-layout'>
        <div className='layout-head-video'>
          <div />
          <div>
            VIDEO
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column-reverse', marginTop: '-8px' }}>
            <div style={{ fontSize: '10px', marginTop: '-14px', marginRight: '12px' }}>Autoplay</div>
            <Switch
              margin='dense'
              checked={this.state.autoPlay}
              onChange={this.handleSwitchAutoPlay}
            />
          </div>

        </div>
        {tempVideo !== undefined ? (
          <ReactPlayer
            volume={soundVolume}
            controls
            width='100%'
            height={playerSize}
            url={tempVideo}
            playing={this.state.autoPlay}
            config={{
              file: {
                attributes: {
                  preload: 'none'
                }
              }
            }}
          />
        ) : null}
      </Paper>
    )
  }
}
export default VideoPlayer
