import React, { Component } from 'react'
import HeadText from '../static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../static/table'
import discovery from '@soccerwatch/discovery'
import moment from 'moment'

import { addOrEditDefaultlist, deleteDefaultlist } from '../../api/api-blacklist'
import { header, getLazyLoading } from '../../api/helper'
import { storageLayerTableInfo } from '../static/config/storageLayerTableConfig'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { circularProgressCenter } from '../static/loadingProgress'
import { postMetaId } from '../../api/api-video'

export const typeArray = [
  { value: 'club', label: 'club' },
  { value: 'competition', label: 'competition' },
  { value: 'team', label: 'team' }
]

const config = {
  tableName: 'defaultlist',
  tableUrl: '/defaultlist/',
  tableDialogTitle: 'Defaultlist',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'buttonOverlay', title: 'Zukünftige Aufnahmen', width: 170 },
    { name: 'RowKey', title: 'RowKey', width: 200, toEditable: false, toCreate: false },
    { disabled: true, name: 'type', title: 'type', width: 200, toEditable: false, toCreate: true, dropdown: true, dropdownValue: typeArray },
    { disabled: true, name: 'typeId', title: 'id', width: 200, toEditable: false, toCreate: true },
    { name: 'defaultState', title: 'defaultState', value: 'boolean', width: 150 },
    { name: 'defaultActive', title: 'defaultActive', value: 'boolean', width: 150 },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class Defaultlist extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      videos: [],
      tempId: '0',
      check: false,
      loadingCall: false,
      activVideos: false
    }
  }

  _isMounted = false;

  handleId = (id) => {
    if (id != null) {
      this.setState({ tempId: id })
    }
  };

  /**
   * Api-call get all GenericList
   */
  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleMounted = () => {
    return this._isMounted
  }

  handleGetCall = (check) => {
    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: discovery.API_BLACKLIST + '/defaults/',
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetAdList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  handleGetAdList = (res, checkLast) => {
    // Sort and ADD Id by Rows
    res.sort((a, b) => {
      return Number(a.RowKey) - Number(b.RowKey)
    }).map((row, index) => {
      row.tableId = index

      row.buttonOverlay = (
        <a
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={() => this.handleClickOpen(row)}
        >
          Zukünftige Aufnahmen
        </a>
      )

      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

   handleEditColum = async (data) => {
     this.setState({ loadingCall: true })
     let checkPostStatus = false

     data.json.RowKey = data.row.RowKey

     await addOrEditDefaultlist(data.json).then((res) => {
       if (res.status === 200) {
         checkPostStatus = true
       }
     })

     this.setState({ loadingCall: false })
     return checkPostStatus
   }

  handleDeleteColum = async (data) => {
    let checkPostStatus = false
    await deleteDefaultlist(data.row.RowKey).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
      }
    })
    return checkPostStatus
  }

  handleAddColum = async (data) => {
    if (data.json.type === undefined || data.json.typeId === undefined) {
      return false
    }

    data.json.RowKey = data.json.type + '-' + data.json.typeId.trim()
    data.json.type = undefined
    data.json.typeId = undefined

    this.setState({ loadingCall: true })
    let checkPostStatus = false

    if (data.json.RowKey) {
      await addOrEditDefaultlist(data.json).then((res) => {
        if (res.status === 200) {
          checkPostStatus = true
        }
      })
    }

    this.setState({ loadingCall: false })
    return checkPostStatus
  }

  handleClickOpen = (obj) => {
    const type = obj.RowKey.split('-')[0]
    const id = obj.RowKey.split('-')[1]
    this.handleGetDataCall(type, id)

    this.setState({
      open: true,
      tempInfoObjekt: obj,
      tempInfoObjektLoading: true,
      checkbox: []
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
      tempInfoObjekt: undefined,
      tempInfoObjektLoading: false
    })
  }

  handleGetDataCall = (type, id) => {
    const now = new Date()
    let urlType
    switch (type) {
      case 'club':
        urlType = 'clubA'
        break
      case 'team':
        urlType = 'teamA'
        break
      case 'competition':
        urlType = 'competitionId'
        break
      default:
        break
    }

    const configArray = [
      {
        url: discovery.API_VIDEO + `/metaBy/${urlType}/since/${id}/${now.getTime()}`,
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetVideoList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  handleGetVideoList = (res, checkLast) => {
    // Sort and ADD Id by Rows
    res.sort((a, b) => {
      return Number(a.RowKey) - Number(b.RowKey)
    })

    if (checkLast === true) {
      this.setState({
        videos: res,
        tempInfoObjektLoading: false,
        checkbox: []
      })
    } else {
      this.setState({
        videos: res
      })
    }
  }

  handleActiveDeactiveVideos = (value) => {
    this.setState({
      activVideos: value,
      checkbox: []
    })
  }

  handleCheckboxVideos = (id, value) => {
    let checkbox = this.state.checkbox

    if (value) {
      checkbox.push({ RowKey: id, value: value })
    } else {
      checkbox = checkbox.filter(vid => vid.RowKey !== id)
    }

    this.setState({
      checkbox: checkbox
    })
  }

  handleEditVideo = async (active) => {
    const checkbox = this.state.checkbox
    const body = active ? { state: 'done', active: false } : { state: 'created', active: true }

    for (let index = 0; index < checkbox.length; index++) {
      const video = checkbox[index]

      await postMetaId(body, String(video.RowKey)).then((res) => {

      })
    }

    this.setState({
      tempInfoObjektLoading: true,
      checkbox: [],
      videos: []
    })

    const type = this.state.tempInfoObjekt.RowKey.split('-')[0]
    const id = this.state.tempInfoObjekt.RowKey.split('-')[1]
    this.handleGetDataCall(type, id)
  }

  renderOverlayDialog = () => {
    let id, type

    if (this.state.tempInfoObjekt) {
      type = this.state.tempInfoObjekt.RowKey.split('-')[0]
      id = this.state.tempInfoObjekt.RowKey.split('-')[1]
    }

    return (
      <>
        <DialogTitle id='customized-dialog-title' onClose={this.handleClose}>
          Geplante Aufnahmen in der Zukunft - {type}: {id}
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button disabled={this.state.tempInfoObjektLoading || !this.state.activVideos} onClick={() => this.handleActiveDeactiveVideos(false)} color='primary' variant='contained'>
              Deaktivierte Aufnahmen
            </Button>
            <Button disabled={this.state.tempInfoObjektLoading || this.state.activVideos} onClick={() => this.handleActiveDeactiveVideos(true)} color='primary' variant='contained'>
              Aktive Aufnahmen
            </Button>
          </div>
        </DialogContent>
        <DialogContent>
          {this.state.tempInfoObjektLoading ? circularProgressCenter(40) : (
            this.state.videos.map((video, index) => {
              if (this.state.activVideos ? !video.active : video.active) {
                return null
              }

              let checkbox = false
              this.state.checkbox.map(check => {
                if (check.RowKey === video.RowKey) {
                  checkbox = check.value
                }
              })

              return (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Checkbox
                    checked={checkbox}
                    onChange={() => this.handleCheckboxVideos(video.RowKey, !checkbox)}
                    value='activeCheck'
                    color='primary'
                  />
                  <div>
                    <div style={{ fontSize: 14 }}>{moment(video.expectedStartTime).format('DD.MM.YYYY HH:mm')}</div>
                    <div style={{ fontSize: 12 }}>{video.RowKey}</div>
                  </div>
                  <div style={{ marginLeft: 20, fontSize: 12 }}>
                    {video.clubAName} vs  {video.clubBName}
                  </div>
                </div>
              )
            })
          )}
        </DialogContent>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button disabled={this.state.checkbox && this.state.checkbox.length === 0} onClick={() => this.handleEditVideo(this.state.activVideos)} color='primary' variant='contained'>
              {this.state.activVideos ? 'Videos Deaktiveren' : 'Videos Aktiveren'}
            </Button>

            {this.state.checkbox && this.state.checkbox.length !== 0 ? (
              <div style={{ fontSize: 10, textAlign: 'center' }}>
                Bitte beachte, dass wenn du die Aufnahmen {this.state.activVideos ? 'deaktivierst' : 'aktivierst'}, die werte auf
                <br /> {this.state.activVideos ? 'state: done, active: false' : 'state: created, active: true'}
                <br /> geändert werden
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color='primary'>
            Benden
          </Button>
        </DialogActions>
      </>
    )
  }

  render () {
    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby='customized-dialog-title'
          open={this.state.open}
          disableBackdropClick
        >
          {this.renderOverlayDialog()}
        </Dialog>
        <HeadText text={this.props.headingText} />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              resetData={this.handleGetCall}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              handleEditRow={this.handleEditColum}
              handleDeleteRow={this.handleDeleteColum}
              handleCreateRow={this.handleAddColum}
              loadingCall={this.state.loadingCall}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Defaultlist
