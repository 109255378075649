import React, { Component } from 'react';
import './aiSearch.css'
//import './dashboard.css'
class AISearch extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef(); // Erstelle ein Ref für den Container
    this.loadWidget = this.loadWidget.bind(this);
  }

  loadWidget() {
    //if (!document.querySelector('script[src="https://cloud.google.com/ai/gen-app-builder/client?hl=de_DE"]')) {
      const script = document.createElement('script');
      script.src = 'https://cloud.google.com/ai/gen-app-builder/client?hl=de_DE';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      script.onload = () => {
        console.log("load")
        if (this.containerRef.current) {
          const searchWidget = document.createElement('gen-search-widget');
          searchWidget.setAttribute('configId', 'e22ea086-88a8-4120-99a5-0e5b75e88d66');
          searchWidget.setAttribute('location', 'eu');
          searchWidget.setAttribute('triggerId', 'searchWidgetTrigger');
          this.containerRef.current.appendChild(searchWidget);
          
        }
      };
    //}
  }

  componentDidMount() {
    this.loadWidget();
  }

  render() {
    return (
      <div className="container">
      <div ref={this.containerRef} className="reader"></div>
      <input className="search-input" placeholder="Neue Frage stellen" id="searchWidgetTrigger" />
      <div className="query-examples">
            <button className="query-button">Wie viel kostet eine Kamera?</button>
            <button className="query-button">Welche Ports müssen offen sein?</button>
            <button className="query-button">Wie viel Mbit braucht die Kamera?</button>
            <button className="query-button">Auf welcher Höhe wird die Kamera installiert</button>
      <p class="validation-notice">Die generierten Antworten basieren auf den bereitgestellten Informationen und können noch abweichen. Prüfe die Antwort anhand der referenzierten Dokumente</p>
        </div>
      </div>
    )
  }
}

export default AISearch;
