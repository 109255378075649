import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
/* const defaultProps = {
  initialPage: 1,
  pageSize: 10
} */

class Pagination extends React.Component {
  constructor (props) {
    super(props)
    this.state = { pager: {} }
  }

  componentWillMount () {
    // set page if items array isn't empty
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    let check = true
    // reset page if items array has changed
    if (this.props.items !== prevProps.items) {
      this.setPage(this.state.pager.currentPage)
      check = false
    }

    if (this.state.pager.totalItems !== prevState.pager.totalItems && check) {
      // console.log('d', this.state.pager)
      this.setPage(this.state.pager.currentPage)
    }
  }

  setPage (page) {
    var { items, pageSize } = this.props
    var pager = this.state.pager

    if (page < 1 || page > pager.totalPages) {
      page = 1
    }

    // get new pager object for specified page
    pager = this.getPager(items.length, page, pageSize)

    // get new page of items from items array
    var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1)

    // update state
    this.setState({ pager: pager })

    // set totalItems
    this.props.onSetTotalItems(pager.totalItems)

    // call change page function in parent component
    this.props.onChangePage(pageOfItems)
  }

  getPager (totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1

    // default page size is 20
    pageSize = pageSize || 20

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize)

    var startPage, endPage
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1
      endPage = totalPages
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1
        endPage = 10
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9
        endPage = totalPages
      } else {
        startPage = currentPage - 5
        endPage = currentPage + 4
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

    // create an array of pages to ng-repeat in the pager control
    var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i)

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    }
  }

  renderPagination (pager) {
    return (
      <ul className='pagination'>
        <li className={pager.currentPage === 1 ? 'disabled' : ''}>
          <div onClick={() => this.setPage(1)}>Anfang</div>
        </li>
        <li className={pager.currentPage === 1 ? 'disabled' : ''}>
          <div onClick={() => this.setPage(pager.currentPage - 1)}> <FontAwesomeIcon icon={faArrowLeft} /> </div>
        </li>
        {pager.pages.map((page, index) =>
          <li key={index} className={pager.currentPage === page ? 'active' : ''}>
            <div onClick={() => this.setPage(page)}>{page}</div>
          </li>
        )}
        <li className={pager.currentPage === pager.totalPages ? 'disabled' : ''}>
          <div onClick={() => this.setPage(pager.currentPage + 1)}> <FontAwesomeIcon icon={faArrowRight} /> </div>
        </li>
        <li className={pager.currentPage === pager.totalPages ? 'disabled' : ''}>
          <div onClick={() => this.setPage(pager.totalPages)}>Ende</div>
        </li>
      </ul>
    )
  }

  render () {
    var pager = this.state.pager
    const checkDisplayPage = !pager.pages || pager.pages.length <= 1 // don't display pager if there is only 1 page

    return (
      <>
        {!checkDisplayPage ? this.renderPagination(pager) : null}
        <div style={{ paddingBottom: 20 }}>
          {this.props.children}
        </div>
        {!checkDisplayPage ? this.renderPagination(pager) : null}
      </>
    )
  }
}

export default Pagination
