import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import discovery from '@soccerwatch/discovery'
import OverlayImport from './modules/overlayImportClub'
import OverlayImportMatch from './modules/overlayImportMatch'
import ContentMenu from '../static/menus/contentMenu'
import { postClub } from '../../api/api-club'
import { uploadClubLogo } from '../../api/api-mediaupload'
import { header, getLazyLoading } from '../../api/helper'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'
import { priorityTenScale } from '../static/dropdownValues'
import './club.css'

const config = {
  tableName: 'clubTable',
  tableUrl: '/club/',
  tableDialogTitle: 'Club',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'RowKey', title: 'RowKey', operation: 'equal', editable: false, width: 90 },
    { name: 'logo', toCreate: false, title: 'logo', imagesUpload: { width: 300, height: 300, methode: 'contain', fileFormat: ['png'], noImgUrl: 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png' }, width: 66 },
    { name: 'name', title: 'name', toCreate: true, width: 140 },
    { name: 'priority', title: 'priority', toCreate: false, width: 90, value: 'number', dropdown: true, dropdownValue: priorityTenScale },
    { name: 'searchName', title: 'searchName', toCreate: true, width: 140 },
    { name: 'link', title: 'Link', disableDialog: true, toCreate: true },
    { name: 'fupaSlug', title: 'fupaSlug', value: 'string', toCreate: true, width: 100 },
    { name: 'color', title: 'Color', toCreate: true },
    // { name: 'hasCategory', title: 'hasCategory', value: 'boolean', toCreate: true },
    { name: 'thumbnail', title: 'logo url', toCreate: true, width: 210, editable: false },
    { name: 'hasSystem', title: 'hasSystem', value: 'boolean', toCreate: true },
    // { name: 'commercialReady', toCreate: true, title: 'commercialReady', value: 'boolean' },
    // { name: 'cityOverlay', title: 'cityOverlay', value: 'cityOverlay', width: 120 },
    { name: 'city', title: 'city', value: 'cityOverlay', toCreate: true, width: 120 },
    { name: 'cityId', title: 'cityId', toCreate: false, editable: false, width: 120 },
    { name: 'Ref_CC_Priority_Score', title: 'Ref_CC_Priority_Score', toCreate: false, editable: false, width: 120 },
    { name: 'Ref_Team_SportType', title: 'Ref_Team_SportType', toCreate: false, editable: false, width: 120 },
    { name: 'Ref_CC_Count_Videos', title: 'Ref_CC_Count_Videos(dep)', toCreate: false, editable: false, width: 120 },
    { name: 'location', toCreate: true, title: 'location', width: 280 },
    { name: 'zip', title: 'zip', toCreate: true, value: 'number' },
    /* { name: 'dId', title: 'dId', width: 300, toCreate: true }, */
    { name: 'soccerSourceId', title: 'soccerSourceId', width: 300, toCreate: true },
    { name: 'soccerSourceLink', title: 'soccerSourceLink', width: 300, toCreate: true },
    { name: 'manualTagging', title: 'manualTagging',value: 'boolean', width: 50, editable: true  },
    // { name: 'icehockeySourceId', title: 'soccerSourceId', width: 300, toCreate: true },
    // { name: 'icehockeySourceLink', title: 'soccerSourceLink', width: 300, toCreate: true },
    // { name: 'basketballSourceId', title: 'soccerSourceLink', width: 300, toCreate: true },
    // { name: 'basketballSourceLink', title: 'soccerSourceId', width: 300, toCreate: true },
    // { name: 'volleyballSourceId', title: 'soccerSourceLink', width: 300, toCreate: true },
    // { name: 'volleyballSourceLink', title: 'soccerSourceId', width: 300, toCreate: true },
    // { name: 'handballSourceId', title: 'soccerSourceId', width: 300, toCreate: true },
    // { name: 'handballSourceLink', title: 'soccerSourceLink', width: 300, toCreate: true },
    // { name: 'hockeySourceId', title: 'soccerSourceId', width: 300, toCreate: true },
    // { name: 'hockeySourceLink', title: 'soccerSourceLink', width: 300, toCreate: true },
    // { name: 'nonCompetitiveEventSourceId', title: 'soccerSourceId', width: 300, toCreate: true },
    // { name: 'nonCompetitiveEventSourceLink', title: 'soccerSourceLink', width: 300, toCreate: true },
    { name: 'latitude', title: 'latitude', value: 'number', toCreate: true, width: 140 },
    { name: 'longitude', title: 'longitude', value: 'number', toCreate: true, width: 140 },
    { name: 'paywallCase', title: 'paywallCase', value: 'boolean', toCreate: false, width: 140 },
    { name: 'paywallTemplate', title: 'paywallTemplate', value: 'string', toCreate: false, width: 140 },
    { name: 'subscriptionExclusive', title: 'subscriptionExclusive', value: 'boolean', toCreate: true, width: 140 },
    { name: 'internetFeePerMonth', title: 'internetFeePerMonth', value: 'number', toCreate: false, width: 180 },
    { name: 'internetFeePerMatch', title: 'internetFeePerMatch', value: 'number', toCreate: false, width: 180 },
    { name: 'productionFeePerMath', title: 'productionFeePerMath', value: 'number', toCreate: false, width: 180 },
    { name: 'priceForCamera', title: 'priceForCamera', value: 'number', toCreate: false, width: 180 },

    { name: 'tenant', title: 'tenant', value: 'tagInput', label: 'tenant', toCreate: false, width: 180 },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class Club extends Component {
  constructor (props) {
    super(props)

    this.state = {
      clubs: [],
      rows: [],
      tempId: 0,
      check: false,
      loadingRows: true,
      openOverlayImport: false,
      openOverlayImportMatch: false,
      loadingCall: false
    }

    this.handleOpenOverlayImportMatch = this.handleOpenOverlayImportMatch.bind(this)
    this.closeOverlayImportMatch = this.closeOverlayImportMatch.bind(this)
    this.handleOpenOverlayImport = this.handleOpenOverlayImport.bind(this)
    this.handleGetClubList = this.handleGetClubList.bind(this)
    this.handleEditColum = this.handleEditColum.bind(this)
    this.handleAddColum = this.handleAddColum.bind(this)
    this.handleMounted = this.handleMounted.bind(this)
    this.handleGetCall = this.handleGetCall.bind(this)
    this.handleId = this.handleId.bind(this)
    this.closeOverlayImport = this.closeOverlayImport.bind(this)
  }

  _isMounted = false;

  handleId (id) {
    if (id != null) {
      this.setState({ clubs: [], tempId: id })
    }
  };

  /**
   * Api-call get ClubList
   */
  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleMounted () {
    return this._isMounted
  }

  handleGetClubList (res, checkLast) {
    // Sort and ADD Id by Rows
    res.sort((a, b) => {
      return null
      // return Number(a.RowKey) - Number(b.RowKey)
    }).map((row, index) => {
      row.link = (
        <a
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: 'blue' }}
          href={`https://www.fussball.de/verein/id/${row.dId}#!/`}
        >
          Fussball.de
        </a>
      )
      const tempThumpnail = row.thumbnail || 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
      const now = new Date().getTime()
      row.logo = (
        <img
          id={'img-' + row.RowKey}
          src={tempThumpnail + '?t=' + now}
          alt='no-logo'
          style={{ width: 32, height: 32, marginTop: 2 }}
          onError={(e) => { e.target.onerror = null; e.target.src = 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png' }}
        />
      )
      row.tableId = index

      if (tempThumpnail === '/assets/img/clubq.jpg' || tempThumpnail === 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png') {
        row.thumbnail = 'nologo'
      }

      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

  handleGetCall (check) {
    if (check) {
      this.state.clubs.splice(0, this.state.clubs.length)
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: discovery.API_CLUB + '/clubs/',
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetClubList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  async handleEditColum (data) {
    this.setState({ loadingCall: true })
    let checkPostStatus = false
    // SET 'RowKey' to Json, need this for backend
    data.json.RowKey = data.row.RowKey
    if (data.json.soccerSourceId) {
      data.json.dId = data.json.soccerSourceId
    }
    // Check Thumbnail for imagesUpload
    if (data.json.newThumbnail) {
      let newUrl, color
      const imgInfo = {
        clubId: data.json.RowKey,
        img: data.json.newThumbnail
      }

      await uploadClubLogo(imgInfo).then((res) => {
        if (res.status === 200) {
          newUrl = res.data.thumbnail
          color = res.data.color
        }
      })

      data.json.newThumbnail = undefined
      data.json.thumbnail = newUrl
      data.json.color = color
    }

    await postClub(data.json).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
        this.setState({ loadingCall: false })
      }
    })

    return checkPostStatus
  }

  async handleAddColum (data) {
    let checkPostStatus = false
    data.json.zip = data.json.zip || 0
    data.json.latitude = data.json.latitude || 0
    data.json.longitude = data.json.longitude || 0

    if (data.json.name && data.json.city) {
      await postClub(data.json).then((res) => {
        if (res.status === 200) {
          checkPostStatus = true
        }
      })
    }
    return checkPostStatus
  }

  handleOpenOverlayImport () {
    this.setState({ openOverlayImport: true })
  }

  closeOverlayImport () {
    this.setState({ openOverlayImport: false })
  }

  handleOpenOverlayImportMatch () {
    this.setState({ openOverlayImportMatch: true })
  }

  closeOverlayImportMatch () {
    this.setState({ openOverlayImportMatch: false })
  }

  render () {
    return (
      <div>
        <HeadText text={this.props.headingText} />

        <ContentMenu config={[
          { title: 'Import Club', onClick: this.handleOpenOverlayImport },
          { title: 'Import Match', onClick: this.handleOpenOverlayImportMatch }
        ]}
        />

        <OverlayImport close={this.closeOverlayImport} open={this.state.openOverlayImport} />
        <OverlayImportMatch close={this.closeOverlayImportMatch} open={this.state.openOverlayImportMatch} rowinfo={this.state.rows[this.state.tempId]} />

        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              resetData={this.handleGetCall}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              param={this.props.match}
              loadingData={this.state.loadingRows}
              handleEditRow={this.handleEditColum}
              handleCreateRow={this.handleAddColum}
              loadingCall={this.state.loadingCall}
              // addColum={AddColum}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Club
