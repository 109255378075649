import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Table from '../../static/table'
import Hidden from '@material-ui/core/Hidden'
import Info from './container/info'

import { getHeaders, postHeaders } from '../../../api/api-redactional'
import { getSignedUrlHeaderFile, uploadHeaderFile } from '../../../api/api-mediaupload'

const noImg = 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_noimg_288x73.png'

const config = {
  tableName: 'contentTable',
  tableUrl: '/editing/header/',
  tableDialogTitle: 'Clip',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { disabled: true, name: 'thumbnailImg', title: 'img', imagesUpload: { width: 1280, height: 720, noImgUrl: noImg, fileFormat: ['png', 'jpeg'] } },
    { disabled: true, name: 'clipStreamFile', title: 'clip', videoUpload: { fileFormat: ['mp4'] } },
    { name: 'RowKey', title: 'RowKey', operation: 'equal', toEditable: false },
    { name: 'PartitionKey', title: 'PartitionKey', dropdown: true, dropdownValue: [{ value: 'streamPage', label: 'streamPage' }, { value: 'matchPage', label: 'matchPage' }] },
    { name: 'active', title: 'active', value: 'boolean', tooltip: 'Wenn gesetzt, erscheint dieser Header oben auf der Startseite' },
    { name: 'validContext', title: 'validContext', value: 'validContext', tooltip: 'Gibt an bei Welchen Sportarten dieser Header angezeigt wird' },
    { name: 'isTeaser', title: 'isTeaser', value: 'boolean', default: true },
    { name: 'mediaType', title: 'mediaType', toCreate: false, dropdown: true, dropdownValue: [{ value: 'image', label: 'image' }, { value: 'video', label: 'video' }], tooltip: 'Medientyp, der verwendet werden soll' },
    { name: 'priority', title: 'Priorität', toCreate: false, dropdown: true, dropdownValue: [{ value: 0, label: 'Sehr hoch' },{ value: 1, label: 'Hoch' },{ value: 2, label: 'Mittel' },{ value: 3, label: 'Niedrig' },{ value: 4, label: 'Sehr Niedrig' }] },
    { name: 'mediaUrl', title: 'mediaUrl', width: 150, tooltip: "Wird gesetzt wenn ein Bild oder Video hochgeladen wird." },
    { name: 'subTitle', title: 'subTitle', width: 150, tooltip: "Der Untertitel" },
    { name: 'title', title: 'title', width: 150, tooltip: "Titel oben Links" },
    { name: 'buttonText', title: 'buttonText', width: 150, tooltip: 'Text auf dem Button oben rechts' },
    { name: 'link', title: 'link', width: 150, tooltip: 'Ziel des Buttons oben rechts (z.B. "/spiel/9999")' },
    { name: 'validFrom', title: 'validFrom', value: 'datetime', width: 110, tooltip: 'Ab wann soll der Header aktiv sein?' },
    { name: 'validTill', title: 'validTill', value: 'datetime', width: 110, tooltip: 'Bis wann soll der Header aktiv sein?' }
  ],
  defaultHiddenColumnNames: [
    { label: 'Alles', value: [''] }
  ]
}
class EditingContent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      tempId: 0,
      check: false,
      loadingRows: true
    }

    this.onGetCall = this.onGetCall.bind(this)
    this.onEditRow = this.onEditRow.bind(this)
    this.onCreateRow = this.onCreateRow.bind(this)
    this.handleSelectId = this.handleSelectId.bind(this)
    this.handleUploadBar = this.handleUploadBar.bind(this)
  }

  handleSelectId (id) {
    if (id != null) {
      this.setState({ tempId: id })
    }
  };

  componentDidMount () {
    this.onGetCall()
  }

  handleUploadBar (prozess, finish) {
    if (finish) {
      this.setState({
        uploadProcess: undefined
      })
    } else {
      this.setState({
        uploadProcess: prozess
      })
    }
  }

  async onGetCall (check) {
    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    getHeaders().then((res) => {
      res.map((row, index) => {
        row.tableId = index
        if(row.validContext) {
          row.validContext = JSON.stringify(row.validContext)
        }
        return row
      })
      this.setState({
        rows: res,
        loadingRows: false
      })
    })
  }

  /**
   * @param {object} data
   * @returns
   *     data.json // new or edited value
   *     data.row  // return: current row
   */
  async onEditRow (data) {
    // check object is emty / there is no change so nothing is saved
    if(data.json && Object.keys(data.json).length === 0 && data.json.constructor === Object) {
      return true
    }

    let checkEditStatus = false
    this.setState({ loadingCall: true })
    const name = data.row.RowKey
    data = await this.uploadFile(data)
    // validContext parse JSON
    if(data.json.validContext) {
      data.json.validContext = JSON.parse(data.json.validContext)
    }

    await postHeaders(data.json, name).then(res => {
      if (res.status === 200) {
        checkEditStatus = true
      }
    })
    // validContext reset
    if(data.json.validContext) {
      data.json.validContext = JSON.stringify(data.json.validContext)
    }

    this.setState({ loadingCall: false })
    return checkEditStatus
  }

  /**
   * @param {object} data
   * @returns
   *     data.json // new or edited value
   *     data.row  // return: current row
   */
  async onCreateRow (data) {
    let checkPostStatus = false
    if (data.json.RowKey) {
      this.setState({ loadingCall: true })
      const name = data.json.RowKey
      data = await this.uploadFile(data)
      data.json.Timestamp = new Date()
      // validContext parse JSON
      if(data.json.validContext) {
        data.json.validContext = JSON.parse(data.json.validContext)
      }
      await postHeaders(data.json, name).then(res => {
        if (res.status === 200) {
          checkPostStatus = true
        }
      })
      // validContext reset
      if(data.json.validContext) {
        data.json.validContext = JSON.stringify(data.json.validContext)
      }

      this.setState({ loadingCall: false })
    }
    return checkPostStatus
  }

  async uploadFile (data) {
    this.setState({ loadingCall: true })
    const name = data.json.RowKey || data.row.RowKey
    let fileNameTemp, splitName, fileName, bodyForSignedUrl, type, file

    if (data.json.newThumbnail) {
      type = 'image/png'
      file = data.json.newThumbnail
      fileName = name + '.png'
      bodyForSignedUrl = { name: fileName, type: type }
      data.json.mediaType = 'image'

      file = file.replace('data:image/png;base64,', '')
      var imageContent = window.atob(file)
      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)
      // fill the view, using the decoded base64
      for (var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }
      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: type })
      file = blob
      // reset newThumbnail for post
      data.json.newThumbnail = undefined
    }

    if (data.json.newVideoFile) {
      type = data.json.newVideoFile.type
      file = data.json.newVideoFile
      fileNameTemp = data.json.newVideoFile.name
      splitName = String(fileNameTemp).split('.')
      fileName = name + '.' + splitName[splitName.length - 1]
      bodyForSignedUrl = { name: fileName, type: type }
      data.json.mediaType = 'video'
      data.json.newVideoFile = undefined
    }
    // bodyForSignedUrl = false
    if (bodyForSignedUrl) {
      // TODO GET signedurl
      let signedUrl
      await getSignedUrlHeaderFile(bodyForSignedUrl).then(res => {
        if (res.status === 200) {
          signedUrl = res.data.signedUrl
        }
      })

      // upload file
      if (signedUrl) {
        const check = await uploadHeaderFile(file, signedUrl, type, this.handleUploadBar)
        if (check) {
          const newUrl = 'https://storage.googleapis.com/sw-sc-de-assets/Header/' + fileName
          data.json.mediaUrl = newUrl
        }
      }
    }

    return data
  }

  render () {
    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs={12} lg={8} />
        </Grid>
        <Grid container spacing={24}>
          <Hidden lgUp>
            <Grid item xs={12} lg={12}>
              <Info rowinfo={this.state.rows[this.state.tempId]} />
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={8}>
            <Table
              tableConfig={config}
              tableRows={this.state.rows}
              loadingData={this.state.loadingRows}
              onSelectId={this.handleSelectId}
              resetData={this.onGetCall}
              handleEditRow={this.onEditRow}
              handleCreateRow={this.onCreateRow}
              loadingCall={this.state.loadingCall}
              uploadProcess={this.state.uploadProcess}
              param={this.props.match}
            />
          </Grid>
          <Hidden mdDown>
            <Grid item lg={4}>
              <Info rowinfo={this.state.rows[this.state.tempId]} />
            </Grid>
          </Hidden>
        </Grid>
      </div>
    )
  }
}

export default EditingContent
