import React, { Component } from 'react'
import { getLazyLoading, header } from '../../../api/helper'
import discovery from '@soccerwatch/discovery'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { CSVLink } from 'react-csv'

class SearchDoocket extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rows: [],
      loading: false,
      cameraCsvExport: false,
      cameraCsvOptions: {
        simId: false,
        installHeight: false,
        distance: false,
        offsetSideline: false
      },
      exportData: []
    }

    this.csvLink = React.createRef()
  }

  _isMounted = true;

  handleMounted = () => {
    return this._isMounted
  }

  exportCsvCamera = async () => {
    const configArray = [
      {
        url: discovery.API_METRIC + '/newestDockets/',
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetClubList
      }
    ]

    const { simId, installHeight, distance, offsetSideline } = this.state.cameraCsvOptions
    if (!simId && !installHeight && !distance && !offsetSideline) {
      await this.setState({
        exportData: this.props.rows,
        cameraCsvExport: false,
        loading: false
      })
      this.csvLink.current.link.click()
      return 
    }

    this.setState({
      loading: true
    })
    getLazyLoading(configArray, this.handleMounted)
  }

  handleGetClubList = async (res, checkLast) => {
    if (checkLast === true) {
      this.setState({
        rows: res
      })

      this.finalMergeExport(res)
    
    } else {
      this.setState({
        rows: res
      })
    }
  }

  finalMergeExport = async (dockets) => {
    const { simId, installHeight, distance, offsetSideline } = this.state.cameraCsvOptions
    dockets = dockets.sort((a, b) => a.lastUpdate < b.lastUpdate)

    const res = this.props.rows.map((row) => {
      for (let index = 0; index < dockets.length; index++) {
        const docket = dockets[index]
        if (docket.PartitionKey === 'swcs-' + row.RowKey) {
          if (simId) {
            if (row.cellular_SimId === undefined && docket.data.Cellular_SimId !== undefined) {
              row = {...row, cellular_SimId: docket.data.Cellular_SimId}
            }
          }

          if (installHeight) {
            if (row.cameraPos_Height === undefined && docket.data.CameraPos_Height !== undefined) {
              row = {...row, cameraPos_Height: docket.data.CameraPos_Height}
            }
          }

          if (distance) {
            if (row.cameraPos_Distance === undefined && docket.data.CameraPos_Distance !== undefined) {
              row = {...row, cameraPos_Distance: docket.data.CameraPos_Distance}
            }
          }

          if (offsetSideline) {
            if (row.cameraPos_Offset === undefined && docket.data.CameraPos_Offset !== undefined) {
              row = {...row, cameraPos_Offset: docket.data.CameraPos_Offset}
            }
          }
        }
      }
      return row
    })

    await this.setState({
      exportData: res,
      cameraCsvExport: false,
      loading: false
    })
    this.csvLink.current.link.click()
  }

  handleCsvExportOverlay = (value) => {
    if (!value) {
      this.setState({
        cameraCsvOptions: {
          simId: false,
          installHeight: false,
          distance: false,
          offsetSideline: false
        }
      })
    }

    this.setState({
      cameraCsvExport: value
    })
  }

  handleCangeCsvOption = (name, value) => {
    this.setState({
      cameraCsvOptions: {
        ...this.state.cameraCsvOptions,
        [name]: value
      }
    })
  }

  render () {
    return (
      <>
        <Dialog fullWidth open={this.state.cameraCsvExport} onClose={this.state.loading ? null : () => {this.handleCsvExportOverlay(false)}} aria-labelledby='alert-dialog-title'>
          <DialogTitle id='alert-dialog-title'>CSV Export</DialogTitle>

          {this.state.loading ? (
            <DialogContent>
              Daten werden geladen, Bitte warten.
            </DialogContent>
          ) : (
            <DialogContent>
              Zusatzinformation aus Laufzettel mit exportieren:
              <div>
                <Checkbox
                  checked={this.state.cameraCsvOptions.simId}
                  onChange={() => {this.handleCangeCsvOption('simId', !this.state.cameraCsvOptions.simId)}}
                  color='primary'
                  margin='normal'
                />
                SIM Seriennummer
              </div>
              <div>
                <Checkbox
                  checked={this.state.cameraCsvOptions.installHeight}
                  onChange={() => {this.handleCangeCsvOption('installHeight', !this.state.cameraCsvOptions.installHeight)}}
                  color='primary'
                  margin='normal'
                />
                Installationshöhe
              </div>
              <div>
                <Checkbox
                  checked={this.state.cameraCsvOptions.distance}
                  onChange={() => {this.handleCangeCsvOption('distance', !this.state.cameraCsvOptions.distance)}}
                  color='primary'
                  margin='normal'
                />
                Distanz zwischen dem Kamera Lot und der Seitenlinien
              </div>
              <div>
                <Checkbox
                  checked={this.state.cameraCsvOptions.offsetSideline}
                  onChange={() => {this.handleCangeCsvOption('offsetSideline', !this.state.cameraCsvOptions.offsetSideline)}}
                  color='primary'
                  margin='normal'
                />
                Seitlicher Versatz der Kamera von der Mittellinie
              </div>
            </DialogContent>
          )}
          
          <DialogActions>
            <Button onClick={() => {this.handleCsvExportOverlay(false)}} color='primary' disabled={this.state.loading}>
              Abrechen
            </Button>
            <Button onClick={() => {this.exportCsvCamera()}} color='primary' autoFocus disabled={this.state.loading}>
              Export
            </Button>
          </DialogActions>
        </Dialog>

        <Button
          ariant='fab'
          disabled={this.props.rows.length <= 0 || this.props.setGetterRows}
          onClick={() => {this.handleCsvExportOverlay(true)}}
        >
          <i className='fa fa-download' style={{ fontSize: '1.45em' }} />
        </Button>
        <CSVLink
          data={this.state.exportData}
          filename={this.props.tableConfig.tableName + '.csv' || 'table.csv'}
          ref={this.csvLink}
          className='hidden'
          target='_blank'
        />
      </>
    )
  }
}

export default SearchDoocket
