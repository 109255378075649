import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

export const circularProgressCenter = (size) => {
  const sizeCheck = size || 60
  return (
    <div style={{ margin: 'auto', width: '40px', padding: '30px' }}>
      <CircularProgress size={sizeCheck} thickness={3} />
    </div>
  )
}
