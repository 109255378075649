import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import * as _ from 'lodash'
import moment from 'moment'
import ReactPlayer from 'react-player'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { getCameraSystem, getCamStatus } from '../../../api/api-camera'
import { getMetaById } from '../../../api/api-video'
import { getMatchDelay } from '../../../api/api-delay'

import { getTaggingState } from '../../../api/api-camera'
import { CameraInfo } from './helper/CameraInfo'
import { MatchInfo } from './helper/MatchInfo'
import { ChatInfo } from './helper/ChatInfo'
import { UploadInfo } from './helper/UploadInfo'

moment.locale('de')

class Content extends Component {
  constructor (props) {
    super(props)

    const RowKey = this.props.data.RowKey
    const socket = this.props.socket
    socket.on('video-' + RowKey, (msg) => {
      this.handleChatMassages(msg)
    })
    socket.emit('join', 'video-' + RowKey)

    this.state = {
      data: this.props.data,
      chatMassage: [],
      cameraTrelloTickets: [],
      cameraHardwarePlatform: undefined,
      uploadInfo: undefined,
      openVideo: false,
      player: null,
      tagState: undefined,
      cameraStatus: undefined,
      trelloTicketLink: undefined,
      reasonForCancelling: undefined,
    }

    this.handleOpenVideoPlayer = this.handleOpenVideoPlayer.bind(this)
  }

  async asyncForEach (array) {
    let arrayList = []
    for (let index = 0; index < array.length; index++) {
      const newItem = await getMatchDelay('gridStream', array[index].RowKey).then(res => {
        const checkNegativStart = res.data[0] > 0 ? res.data[0] : 0
        const checkNegativEnd = res.data[127] > 0 ? res.data[127] : 0
        const time = moment(array[index].startTime).format('DD.MM.YYYY HH:mm')
        return { start: checkNegativStart > 0 ? checkNegativStart : 1, startColor: 'rgb(232, 193, 160)', end: checkNegativEnd > 0 ? checkNegativEnd : 1, endColor: 'rgb(245, 229, 216)', startTime: time, timestamp: array[index].startTime, RowKey: array[index].RowKey }
      })
      arrayList.push(newItem)
    }

    if (arrayList.length > 0 && this.props.data.state !== 'done') {
      let mathStart = 0
      let mathEnd = 0

      arrayList.map(itm => {
        mathStart = mathStart + itm.start
        mathEnd = mathEnd + itm.end
        return null
      })
      mathStart = mathStart / arrayList.length
      mathEnd = mathEnd / arrayList.length
      arrayList.push({
        start: mathStart > 0 ? mathStart : 1,
        startColor: 'rgb(151, 227, 213)',
        end: mathEnd > 0 ? mathEnd : 1,
        endColor: 'rgb(120, 254, 232)',
        startTime: moment(this.props.data.startTime).format('DD.MM.YYYY HH:mm'),
        timestamp: this.props.data.startTime,
        RowKey: this.props.data.RowKey
      })
    }

    if (this.props.data.state === 'done') {
      arrayList.map((match) => {
        if (match.RowKey === this.props.data.RowKey) {
          match.startColor = 'rgb(160, 232, 177)'
          match.endColor = 'rgb(120, 254, 165)'
        }
        return match
      })
    }

    arrayList = _.sortBy(arrayList, 'timestamp').reverse()

    this.setState({
      uploadInfo: arrayList.slice(0, 4)
    })
  }

  componentDidMount () {
    getCamStatus(this.props.data.swcsID).then((res) => {
      this.setState({
        cameraStatus: res.data.online ? 'Aktiv' : 'Inaktiv'
      })
    })

    getCameraSystem(this.props.data.swcsID).then((res) => {
      this.setState({
        cameraTrelloTickets: res.data.tickets,
        cameraHardwarePlatform: res.data.hardwarePlatform
      })
    })

    getMetaById(this.props.data.swcsID, 'done').then((res) => {
      res = res.data.data.filter(row => {
        const checkTime = new Date(row.startTime) < new Date()
        return row.clubAId > 0 && row.active && checkTime
      })
      res = _.sortBy(res, 'startTime').reverse()
      const sliceRes = res.slice(0, 10)
      this.asyncForEach(sliceRes)
    })

    if (this.props.data.canceledReason) {
      let cancelledreason = this.props.data.canceledReason
      cancelledreason = cancelledreason.toLowerCase().replace(/\s+/g, '').replace('-', '')

      const cancelledText = {
        nomatch: 'Es fand kein Spiel statt.',
        legal: 'Aus rechtlichen Gründen wurde das Spiel deaktiviert.',
        priority3match: 'Das Spiel hat Priorität 3 und wurde deshalb deaktiviert.',
        calibrationrecord: 'Das Spiel wurde nur zu Kalibrierungszwecken aufgzeichnet und ist deshalb deaktiviert.',
        wrongfield: 'Das genannte Spiel fand nicht auf dem angegeben Platz statt und wurde daher deaktiviert.',
        breakoff: 'Das Spiel wurde abgesetzt und daher manuell deaktiviert.',
        offline: 'Die Kamera war während des Spiels offline und konnte daher nicht das Spiel aufzeichnen.',
        breakoffauto: 'Laut fussball.de fand das Spiel nicht statt und wurde daher automatisch deaktiviert.'
      }

      this.setState({
        tagState: 'Tagging Failed',
        reasonForCancelling: cancelledText[cancelledreason],
      })
    }

    if (this.props.data.flagTagged) {
      this.setState({
        tagState: 'Tagging Done'
      })
    } else {
      getTaggingState(this.props.data.RowKey)
        .then(res => res.data)
        .then(({statusText, ticketLink}) => {
          this.setState({
            tagState: statusText,
            trelloTicketLink: ticketLink,
          })
        })
        .catch(console.error)

      // TODO when should this happen? what does it do?
      if (!this.props.data.canceledReason) {
        this.setState({
          tagState: 'Keine Information gefunden'
        })
      }
    }
  }

  componentDidUpdate (prevProps) {
    // check of newMsg and than reset value
    if (this.props.data.newMsg) {
      this.props.data.newMsg = undefined
    }

    if (this.props.data.index !== prevProps.data.index) {
      this.setState({
        data: this.props.data
      })
    }
  }

  handleChatMassages (msg) {
    // delete one msg
    if (msg.deleted) {
      msg = this.state.chatMassage.filter(msgList => {
        return msgList.id !== msg.id
      })
    }

    // add one msg
    if (msg.active === true) {
      const tempObj = msg
      msg = this.state.chatMassage
      msg.unshift(tempObj)
    }

    this.setState({
      chatMassage: msg
    })
  }

  componentWillUnmount () {
    this.props.socket.off('video-' + this.props.data.RowKey)
  }

  handleOpenVideoPlayer () {
    this.setState({
      openVideo: !this.state.openVideo
    })
  }

  ref = player => {
    this.setState({
      player: player
    })
  }

  render () {
    const { clubAName, clubBName, swcsID } = this.state.data
    let heightInfobox = 258

    // Element will be rendered by ./helper/createContainer.js
    if (document.getElementById('infotool-infobox') != null) {
      heightInfobox = document.getElementById('infotool-infobox').offsetHeight - 22
    }

    return (
      <div className='socialMedia-contentContainers'>
        <div className='contentContainers-header'>
          <Button
            onClick={this.props.onInactiveContainer}
            color='primary'
            variant='contained'
            className='btn'
            size='small'
            style={{ minWidth: '100px' }}
          >
            <FontAwesomeIcon style={{ fontSize: '18px', margin: '-2px 8px 0 2px' }} icon={faArrowLeft} /> Zurück
          </Button>
          <div className='header-text'>
            <p>{clubAName}</p> vs <p>{clubBName}</p>
          </div>
        </div>
        <div className='contentContainers-content'>
          <Grid container spacing={24}>
            <Grid item lg={this.state.openVideo ? 4 : 6} xs={12} sm={12}>
              <MatchInfo
                match={this.state.data}
                openVideoPlayer={this.state.openVideo}
                handleVideoPlayer={this.handleOpenVideoPlayer}
                tagState={this.state.tagState}
                trelloTicketLink={this.state.trelloTicketLink}
                cancelledReasonDescription={this.state.reasonForCancelling}
              />
            </Grid>
            {this.state.openVideo ? (
              <Grid item lg={4} xs={12} sm={12}>
                <div className='content-player'>
                  <ReactPlayer
                    controls
                    width='100%'
                    height={heightInfobox + 'px'}
                    url={this.state.data.userStream}
                    playing
                    onError={(err, err2) => {
                      err = undefined
                      if (err2.details === 'bufferNudgeOnStall') {
                        this.state.player.seekTo(1)
                      }
                    }}
                    ref={this.ref}
                  />
                </div>
              </Grid>
            ) : null}
            <Grid item lg={this.state.openVideo ? 4 : 6} xs={12} sm={12}>
              <ChatInfo
                msg={this.state.chatMassage}
                match={this.state.data}
                heightInfoBox={heightInfobox}
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={12}>
              <CameraInfo
                swcsID={swcsID}
                trelloTickets={this.state.cameraTrelloTickets}
                hardwarePlatform={this.state.cameraHardwarePlatform}
                cameraStatus={this.state.cameraStatus}
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={12}>
              <UploadInfo
                uploadInfo={this.state.uploadInfo}
                checkMatchDone={this.props.data.state !== 'done'}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

export default Content
