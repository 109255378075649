import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'
rax.attach()

export const getCitySearch = async function getCitySearch (search) {
  return axios.get(`${discovery.API_SEARCH}/search?query=${search}&kind=cityall&fuzzy=true&match=name`, { headers: { Authorization: await getAuthorization() } })
}

export const getClubSearch = async function getCitySearch (search) {
  return axios.get(`${discovery.API_SEARCH}/search?query=${search}&kind=skyhook-beta-cluball&fuzzy=true&match=name`, { headers: { Authorization: await getAuthorization() } })
}