import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import DialogActions from '@material-ui/core/DialogActions'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

function TextProperty(props) {
  return <TextField
    key={props.name}
    className='textField-table'
    id={props.name}
    value={props.value}
    label={props.name.charAt(0).toUpperCase() + props.name.slice(1)}
    onChange={props.handleChange(props.name)}
    margin='normal'
  />
}

const KnownLicenses = {
  AdManagerAccess: {
    additionalProps: [{ name: 'clubId', safeAs: 'clubId', safeInDataAs: ['club', 'id'] }],
  },
  CameraLicense: {
    additionalProps: [
      { name: 'swcsId', safeAs: 'swcsId', safeInDataAs: ['swcsId'] },
      { name: 'clubId', safeAs: 'club', safeInDataAs: ['club'], excludeOnLegacy: true }
    ]
  },
  TrainerSubscription: {
    additionalProps: [
      { name: 'clubId', safeAs: 'clubId', safeInDataAs: ['club'] }
    ]
  },
  ContractOwnerLicense: {
    additionalProps: []
  },
  ClubCommentatorLicense: {
    additionalProps: [{ name: 'clubId', safeAs: 'club', safeInDataAs: ['club'] }]
  },
  ClubTaggerLicense: {
    additionalProps: [{ name: 'clubId', safeAs: 'club', safeInDataAs: ['club'] }]
  },
  ClubStreamerLicense: {
    additionalProps: [{ name: 'clubId', safeAs: 'club', safeInDataAs: ['club'] }]
  },
}


const KnownPaymentTypes = ['Manual', 'Stripe', 'No Payment']
class CustomLicenseDialog extends Component {
  constructor(props) {
    super(props)
    const inAYearDate = new Date()
    inAYearDate.setFullYear(inAYearDate.getFullYear() + 1)
    const inAYear = inAYearDate.toISOString()
    this.state = this.props.addRow ? {
      legacyLicense: false,
      activeUntil: inAYear,
      validUntil: inAYear,
      type: 'CameraLicense',
      subType: 'Club',
      contractId: '',
      paymentId: '',
      paymentType: 'Manual',
      clubId: '',
      swcsId: '',
    } : {
      legacyLicense: !this.props.tableRow.contractId,
      // DateProperties are not used on Non-Legacy Contracts, so set them to default
      activeUntil: !!this.props.tableRow.contractId ? inAYear : this.props.tableRow.activeUntil,
      validUntil: !!this.props.tableRow.contractId ? inAYear : this.props.tableRow.validUntil,
      type: this.props.tableRow.type,
      subType: this.props.tableRow.subType,
      contractId: this.props.tableRow.contractId,
      paymentId: this.props.tableRow.paymentId,
      paymentType: this.props.tableRow.paymentType,
    }
    if (!this.props.addRow) {
      const additionalProps = KnownLicenses[this.state.type].additionalProps
      additionalProps.forEach((property) => {
        if (this.state.legacyLicense && property.excludeOnLegacy) {
          return
        }
        this.state[property.name] = this.props.tableRow[property.safeAs]
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {

  }
  componentDidMount() {

  }

  handleChange = (name) => (event) => {
    if (name === 'type' && event.target.value === 'contractOwnerLicense') {
      return this.setState({
        [name]: event.target.value,
        legacyLicense: false
      })
    }
    this.setState({
      [name]: event.target.value
    })
  };

  handleSave() {
    const newLicense = {
      PartitionKey: this.props.tableRow.PartitionKey,
      activeUntil: '*',
      validUntil: '*',
      type: this.state.type,
      contractId: this.state.contractId,
      paymentId: this.state.paymentId,
      paymentType: this.state.paymentType,
      data: {}
    }
    if(!this.props.addRow) {
      newLicense.RowKey = this.props.tableRow.RowKey
    }

    const additionalProps = KnownLicenses[this.state.type].additionalProps

    additionalProps.forEach((prop) => {
      if (this.state.legacyLicense && prop.excludeOnLegacy) {
        return
      }
      newLicense[prop.saveAs] = this.state[prop.name]
      prop.safeInDataAs.forEach((key) => {
        newLicense.data[key] = this.state[prop.name]
      })
    })

    if (newLicense.type === 'ContractOwnerLicense') {
      newLicense.data.swcsId = '*'
      newLicense.data.club = '*'
    }
    if (newLicense.type === 'AdManagerAccess') {
      newLicense.subType = 'club'
    }
    if (
      this.entryValid()
    ) {
      this.props.handleClose() // Close Edit-Dialog
      this.props.handleRow(newLicense)
    }
  }

  entryValid = () => {
    // If is Legacy License exclude all additionalProps that are marked as "excludeOnLegacy"
    const additionalProps = KnownLicenses[this.state.type].additionalProps.filter((prop) => !this.state.legacyLicense || (this.state.legacyLicense && !prop.excludeOnLegacy))
    const validProps = additionalProps.filter((prop) => { return (this.state[prop.name] && this.state[prop.name] !== '') })
    // Entry is valid when all defined additional Props are set
    const additionalPropsValid = validProps.length === additionalProps.length
    // && there is a Payment Id (or the PaymentType no Payment is Selected)
    const paymentIdValid = (this.state.paymentType === 'No Payment' || this.state.paymentType !== 'No Payment' && this.state.paymentId && this.state.paymentId !== '')
    // && The ContractId is set or it is a LegacyLicense and the Dates are set
    const contractIdOrTimeSetValid =
      (this.state.legacyLicense && this.state.validUntil && this.state.validUntil !== '' && this.state.activeUntil && this.state.activeUntil !== '')
      || (!this.state.legacyLicense && this.state.contractId && this.state.contractId !== '')

    return additionalPropsValid
      && paymentIdValid
      && contractIdOrTimeSetValid

  }

  handleChangeSwitch = (name) => (event) => {
    this.setState({
      [name]: event.target.checked
    })
  }

  onChangeColorPicker = (name, colors) => {
    this.setState({
      [name]: colors
    })
  }

  getFormFieldsForCurrentType = () => {
    // If is Legacy License exclude all additionalProps that are marked as "excludeOnLegacy"
    const additionalProps = KnownLicenses[this.state.type].additionalProps.filter((prop) => !this.state.legacyLicense || (this.state.legacyLicense && !prop.excludeOnLegacy))
    return additionalProps.map((prop) => { return <TextProperty key={prop.name} name={prop.name} value={this.state[prop.name]} handleChange={this.handleChange}></TextProperty> })
  }

  render() {
    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <FormControl>
            <InputLabel htmlFor='type'>Type</InputLabel>
            <Select
              native
              value={this.state.type}
              onChange={this.handleChange('type')}
              disabled={!this.props.addRow}
              inputProps={{
                id: 'type'
              }}
              error={this.state.type !== '' && this.state.type !== undefined ? false : true}
            >
              {Object.keys(KnownLicenses).map(key => <option key={key} value={key}>{key}</option>)}
            </Select>
          </FormControl>
          {this.state.type !== 'ContractOwnerLicense' &&
            <div style={{ color: 'rgba(0, 0, 0, 0.54)', display: 'inline-flex' }}>
              legacy License:
              <FormControlLabel
                className='headpadding-ceck'
                control={
                  <Checkbox
                    checked={this.state.legacyLicense}
                    onChange={this.handleChangeSwitch('legacyLicense')}
                    // value='legacyLicense'
                    color='primary'
                    margin='normal'
                  />
                }
              />
            </div>
          }
          {this.state.legacyLicense && <>
            <TextField
              id='activeUntil'
              label='Aktiv bis'
              type='date-local'
              value={this.state.activeUntil}
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleChange('activeUntil')}
              error={this.props.addRow && new Date(this.state.activeUntil).getTime() < new Date().getTime()}
              helperText={new Date(this.state.activeUntil).getTime() < new Date().getTime() ? 'Die Lizenz sollte nicht berreits abgelaufen sein' : false}
              fullWidth
            />
            <TextField
              id='validUntil'
              label='Valide bis'
              type='date-local'
              value={this.state.validUntil}
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleChange('validUntil')}
              error={this.props.addRow && new Date(this.state.validUntil).getTime() < new Date().getTime()}
              helperText={new Date(this.state.validUntil).getTime() < new Date().getTime() ? 'Die Lizenz sollte nicht berreits abgelaufen sein' : false}
              fullWidth
            />
          </>}
          {!this.state.legacyLicense &&
            <TextField
              className='textField-table'
              id='contractId'
              label='contractId'
              value={this.state.contractId}
              onChange={this.handleChange('contractId')}
              margin='normal'
            />
          }
          <FormControl>
            <InputLabel htmlFor='paymentType'>Payment Type</InputLabel>
            <Select
              native
              value={this.state.paymentType}
              onChange={this.handleChange('paymentType')}
              inputProps={{
                id: 'paymentType'
              }}
              error={this.state.paymentType !== '' && this.state.paymentType !== undefined ? false : true}
            >
              {KnownPaymentTypes.map(key => <option key={key} value={key}>{key}</option>)}
            </Select>
          </FormControl>
          <TextField
            className='textField-table'
            id='paymentId'
            label='PaymentId'
            value={this.state.paymentId}
            onChange={this.handleChange('paymentId')}
            margin='normal'
          />

          {this.getFormFieldsForCurrentType()}

        </Grid>
        <Grid className='dialog-btn'>
          <DialogActions>
            <Button onClick={this.props.handleClose} className='btn' color='primary' variant='contained'>
              Abbrechen
            </Button>
            <Button
              onClick={this.handleSave.bind(this)}
              className='btn'
              color='primary'
              variant='contained'
              autoFocus
              disabled={!this.entryValid()}
            >
              {this.props.addRow ? 'Hinzufügen' : 'Speichern'}
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    )
  }
}

export default CustomLicenseDialog
