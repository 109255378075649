import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

class Overlay extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      loading: false,
      disableAccess: false
    }

    this.handleLoading = this.handleLoading.bind(this)
    this.handleAccess = this.handleAccess.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.refAccess = React.createRef()
  }

  componentDidUpdate (prevProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({
        open: this.props.open
      })
    }
  }

  // Close Overlay
  handleClose () {
    this.props.close()
    this.setState({
      open: false
    })
  }

  handleAccess () {
    this.refAccess.current.handlePostRequest()
  }

  handleLoading (value, disableAccess) {
    this.setState({
      loading: value,
      disableAccess: disableAccess || false
    })
  }

  render () {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'>{this.props.title}</DialogTitle>
        <DialogContent>
          {React.cloneElement(this.props.children, { closeOverlay: this.handleClose.bind(this), ref: this.refAccess, onLoading: this.handleLoading })}
        </DialogContent>
        <DialogActions>
          <Button disabled={this.state.loading} onClick={this.handleClose} color='primary' autoFocus>
              Abbrechen
          </Button>
          {this.state.disableAccess ? null : (
            <Button disabled={this.state.loading} onClick={this.handleAccess} color='primary'>
              {this.props.accessTitle || 'Bestätigen'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

export default Overlay
