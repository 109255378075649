import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'

import { getCameraCornerPointsAll } from '../../api/api-v2-admin'

import './static.scss'

class InputCornerField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdown: []
    }
  }

  componentDidMount () {
    if (this.props.camId) {
      getCameraCornerPointsAll(this.props.camId).then(res =>{       
        if(res.status === 200) {
          this.setState({
            dropdown: res.data
          })
        }
      })
    }
  }

  render () {
    return (
      <>
        <div className='textField-label'>
          {this.props.label}
          {this.props.tooltip ? (
            <Tooltip disabled title={this.props.tooltip} placement="bottom-end">
              <div className="tooltip-icon">?</div>
            </Tooltip>
          ) : null}
        </div>
  
        <Select
          id='select-currency-native'
          className='textField-table-dropdowns'
          value={this.props.value}
          onChange={this.props.handleChange}
          native
          margin='none'
          disabled={this.props.disabled}
          fullWidth
        >
          {this.state.dropdown.map((option, index) => (
            <option key={index} value={option.fieldName}>
              {option.fieldName}
            </option>
          ))}
        </Select>
      </>
    )
  }
}

export default InputCornerField