import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { apiList, getAuthorization, callAs } from './helper'
rax.attach()

export const getCitys = async function getCitys () {
  return apiList(`${discovery.API_CITY}/cities/`, null, { headers: { Authorization: await getAuthorization() } })
}

export const setCity = async function setCitys (city, body) {
  return axios.post(`${discovery.API_CITY}/city/${city}`, JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

// const GEOggsRadius = 5000
// const GEOmaxImages = 20
export const getGEOImages = async function getGEOImages (lat, long) {
  return axios.get(`${discovery.API_CITY}/geoImages/${lat}/${long}`, { headers: { Authorization: await getAuthorization() } })
}

export const setGEOImage = async function setGEOImage (city, body) {
  return axios.post(`${discovery.API_CITY}/setGeoImage/${city}`, JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const setNewCity = async function setNewCity (body) {
  return axios.post(`${discovery.API_CITY}/city/`, JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const mergeCity = async (json, RowKey) => {
  // console.log('Would send', json, RowKey)
  return axios.post(`${discovery.API_CITY}/merge/${RowKey}/${callAs('admin')}`, JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getByIdList = async function getGEOImages (body) {
  return axios.post(`${discovery.API_CITY}/getByIdList`, body, { headers: { Authorization: await getAuthorization() } })
}
