import React from 'react'
import Markerz from './module/marker'
import HeadText from '../../modules/static/tableHeading'
import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input'
import marker_icon_b from '../../../icon/map-marker-black.png'
import marker_icon_mid from '../../../icon/map-marker-yellow2.png'
import marker_icon_high from '../../../icon/map-marker-litered.png'
import marker_icon_highest from '../../../icon/map-marker-red.png'
import marker_icon_ina_test from '../../../icon/map-marker-greay.png'
import { withScriptjs, withGoogleMap, GoogleMap, DirectionsRenderer } from 'react-google-maps'

import { compose } from 'recompose'
import { getCameraSystemList } from '../../api/api-camera'
import { getClubs } from '../../api/api-club'
import { InputSwitch } from '../static/inputFields'
import { Button } from '@material-ui/core'

import './map.css'

const google = window.google

const { lifecycle } = require('recompose')

const MapWithAMakredInfoWindow = compose(
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidUpdate (prevProps) {
      if (
        prevProps.startRoute !== this.props.startRoute ||
        prevProps.counterX !== this.props.counterX ||
        prevProps.endRoute !== this.props.endRoute
      ) {
        this.setState({
          directions: null,
          newDirections: []
        })
        this.drawRoutes()
      }
    },
    componentDidMount () {
      this.drawRoutes()
      this.setState({
        newDirections: []
      })
    },
    drawRoutes () {
      const DirectionsService = new google.maps.DirectionsService()

      DirectionsService.route(
        {
          origin: this.props.startRoute,
          destination: this.props.endRoute,
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: this.props.waypoint
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result,
              newDirections: this.state.newDirections
            })
          }
        }
      )
    }
  })
)((props) => (
  <GoogleMap defaultZoom={9} defaultCenter={{ lat: 51.4871666, lng: 6.9808231 }}>
    {props.directions && <DirectionsRenderer directions={props.directions} />}

    {props.directions !== undefined && props.directions !== null
      ? props.handleRouteTime(props.directions.routes[0])
      : null}

    {props.markers.map((marker) => {
      return (
        <Markerz
          key={marker.RowKey}
          lat={Number(marker.latitude)}
          lng={Number(marker.longitude)}
          info={marker}
          filterA={props.filterA}
          filterB={props.filterB}
          filterC={props.filterC}
          filterD={props.filterD}
          filterE={props.filterE}
          csv={props.csv}
          handleId={props.handleId}
          searchField={props.searchField}
          closeMarker={props.closeMarker}
          handleChangeStart={props.handleChangeStart}
          handleChangeEnd={props.handleChangeEnd}
          handleChangeMid={props.handleChangeMid}
        />
      )
    })}
  </GoogleMap>
))

class Home extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      markers: [],
      markersPlus: [],
      clubInfo: [],
      height: 0,
      filterOk: true,
      filterMid: true,
      filterHigh: true,
      filterHighest: true,
      filterTest: true,
      tempCSV: [],
      tempIdOverlay: undefined,
      searchField: '',
      closeMarker: '',
      closeInfo: false,
      timer: false,
      legendeOpen: false,
      btn: '+',
      routOverlay: false,
      startRoute: '',
      endRoute: '',
      waypoint: [
        /* {location: "berlin"}, {location: "Hamburg"} */
      ],
      routeTime: 0,
      counterX: 0,
      routeKm: 0
    }

    this.getCSV()
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.handleIdOverlay = this.handleIdOverlay.bind(this)
  }

  async componentDidMount () {
    var legend = document.querySelector('#legend')
    var map = document.querySelector('.map')
    var route = document.querySelector('#route')
    var routeMain = document.querySelector('#routeMain')

    setTimeout(() => {
      map.firstChild.firstChild.firstChild.firstChild.appendChild(legend)
      map.firstChild.firstChild.firstChild.firstChild.appendChild(route)
      map.firstChild.firstChild.firstChild.firstChild.appendChild(routeMain)

      this.setState({ timer: true })
    }, 4000)

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    await getClubs().then((res) => {
      this.setState({ clubInfo: res })
    })

    await getCameraSystemList().then((res) => {
      this.setState({ markers: res })
    })
  }

  handleIdOverlay = (value, valueZ, valueX) => {
    this.setState({ tempIdOverlay: value, closeMarker: valueZ, closeInfo: valueX })
  };

  getCSV () {
    const request = require('request')
    const csv = require('csvtojson')

    csv()
      .fromStream(
        request.get(
          'https://docs.google.com/spreadsheets/d/e/2PACX-1vRqbzrdTsPhG5rwEkA8Qz6ZQ7osmzcDo5nr41AWyloP16ZDDZOY9vTg4NvqvyEFWmnNWt0cz8P0bl8C/pub?gid=288124158&single=true&output=csv'
        )
      )
      .on('json', (jsonObj, rowIndex) => {
        this.setState({
          tempCSV: [...this.state.tempCSV, jsonObj]
        })
      })
      .on('done', (error) => {})
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions () {
    this.setState({ height: window.innerHeight })
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  };

  handleChangeInput = (prop) => (event) => {
    this.setState({ [prop]: event.target.value })

    if (prop === 'searchField') {
      this.setState({
        filterOk: true,
        filterMid: true,
        filterHigh: true,
        filterHighest: true,
        filterTest: true
      })
    }
  };

  handleChangeInputMid = (prop) => (event) => {
    const temp = this.state.waypoint
    if (temp !== -1) {
      temp[prop].location = event.target.value
    }
    this.setState({ waypoint: temp })
    this.forceUpdate()
  };

  handleMidDelet = (prop) => {
    const temp = this.state.waypoint
    if (temp !== -1) {
      temp.splice(prop, 1)
    }
    this.setState({ waypoint: temp, counterX: this.state.counterX + 1 })
  };

  handleOverlay () {
    let temp = this.state.btn

    if (temp === '+') {
      temp = '-'
    } else {
      temp = '+'
    }

    this.setState({
      legendeOpen: !this.state.legendeOpen,
      btn: temp
    })
  }

  handleRoute () {
    this.setState({ routOverlay: !this.state.routOverlay })
  }

  handleRouteStart (value) {
    this.setState({ startRoute: value, routOverlay: true, closeInfo: false })
  }

  handleRouteMid (value) {
    const temp = this.state.waypoint

    temp.push({ location: value })

    this.setState({ waypoint: temp, routOverlay: true, counterX: this.state.counterX + 1, closeInfo: false })

    this.forceUpdate()
  }

  handleRouteEnd (value) {
    this.setState({ endRoute: value, routOverlay: true, closeInfo: false })
  }

  handleRouteTime (value) {
    let temp = 0
    let tempKm = 0

    for (let i = 0; i < value.legs.length; i++) {
      temp = temp + value.legs[i].duration.value
      tempKm = tempKm + value.legs[i].distance.value
    }

    const km = Math.floor(tempKm / 1000, 2)

    const hour = Math.floor(temp / 3600)
    const minute = Math.floor((temp % 3600) / 60, 2)

    this.setState({ routeKm: km, routeTime: hour + ' Std. ' + minute + ' Min.' })
  }

  handleClearRoute () {
    this.setState({ waypoint: [], startRoute: '', endRoute: '' })

    setTimeout(() => {
      this.setState({ routeTime: 0 })
    }, 10)
  }

  render () {
    if (this.state.markersPlus.length === 0) {
      this.state.markers.map((n, index) => {
        let clubName
        let thumbnail
        let adress
        let longitudeX = n.longitude
        let latitudeX = n.latitude
        let clubHasSystem = 'Nein'

        this.state.clubInfo.map((clubId, index) => {
          if (n.ownerClubId === clubId.RowKey) {
            clubName = clubId.name
            thumbnail = clubId.thumbnail
            adress = clubId.location

            if (longitudeX === '0' || longitudeX === 'string') {
              longitudeX = clubId.longitude
              latitudeX = clubId.latitude
            }

            if (clubId.hasSystem === true) {
              clubHasSystem = 'Ja'
            }
          }
          return null
        })

        this.state.markersPlus.push({
          name: clubName,
          hasSystem: clubHasSystem,
          ownerClubId: n.ownerClubId,
          id: index,
          PartitionKey: n.PartitionKey,
          RowKey: parseInt(n.RowKey, 10),
          uid: parseInt(n.uid, 10),
          reachable: String(n.reachable),
          currentTask: n.currentTask,
          lastUpdate: n.lastUpdate,
          installed: n.installed,
          club_info: n.club_info,
          color_calibration: n.color_calibration,
          stitching: n.stitching,
          lenses: n.lenses,
          connectionKey: n.connectionKey,
          systemState: n.systemState,
          version_services: n.version_services,
          version_basis_setup: n.version_basis_setup,
          expectedStartTime: n.expectedStartTime,
          version_recorder: n.version_recorder,
          latitude: latitudeX,
          longitude: longitudeX,
          notes: n.notes,
          Timestamp: n.Timestamp,
          calibrationAsset: n.calibrationAsset,
          detail: n.detail,
          deviceId: n.deviceId,
          deviceKey: n.deviceKey,
          initialized: n.initialized,
          iotHubPrivateKey: n.iotHubPrivateKey,
          logicPosition: n.logicPosition,
          systemType: n.systemType,
          version: n.version,
          thumbnail: thumbnail,
          location: adress
        })
        return null
      })
    }

    return (
      <div>
        <HeadText link={false} text={this.props.headingText} />
        <Paper className='map-paper' elevation={4}>
          <div className='map-apper-filtering'>
            <Input
              className='searchField'
              placeholder='KameraID / Clubname...'
              inputProps={{
                'aria-label': 'Description'
              }}
              value={this.state.searchField}
              onChange={this.handleChangeInput('searchField')}
            />
          </div>
          <div className='map'>
            <MapWithAMakredInfoWindow
              googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyAWCBrESCzrUB9ke6jkhBHSSmaeOFgu9Sk&v=3.exp&libraries=geometry,drawing,places'
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height: this.state.height - 205 }} />}
              mapElement={<div style={{ height: '100%' }} />}
              markers={this.state.markersPlus}
              filterA={this.state.filterOk}
              filterB={this.state.filterMid}
              filterC={this.state.filterHigh}
              filterD={this.state.filterHighest}
              filterE={this.state.filterTest}
              csv={this.state.tempCSV}
              handleId={this.handleIdOverlay}
              searchField={this.state.searchField}
              closeMarker={this.state.closeMarker}
              startRoute={this.state.startRoute}
              endRoute={this.state.endRoute}
              waypoint={this.state.waypoint}
              handleRouteTime={this.handleRouteTime.bind(this)}
              handleChangeStart={this.handleRouteStart.bind(this)}
              handleChangeMid={this.handleRouteMid.bind(this)}
              handleChangeEnd={this.handleRouteEnd.bind(this)}
              counterX={this.state.counterX}
            />
          </div>

          <div className='map-route-container-btn' id='route'>
            <Button onClick={this.handleRoute.bind(this)} variant='contained' className='map-route-btn'>
              Routen Planer
            </Button>
          </div>

          <div className='map-route-container' id='routeMain'>
            {this.state.routOverlay ? (
              <div className='map-route-container-x'>
                Routen Planer:
                <Input
                  className='map-route-container-input'
                  placeholder='Start...'
                  value={this.state.startRoute}
                  onChange={this.handleChangeInput('startRoute')}
                />
                {this.state.waypoint.map((n, index) => {
                  return (
                    <div key={index}>
                      <Input
                        className='map-route-container-input-x'
                        placeholder='...'
                        value={n.location}
                        onChange={this.handleChangeInputMid([index]).bind(this)}
                      />

                      <button
                        onClick={this.handleMidDelet.bind(this, [index])}
                        className='map-route-container-input-btn'
                      >
                        X
                      </button>
                    </div>
                  )
                })}
                <Input
                  className='map-route-container-input'
                  placeholder='Ende...'
                  value={this.state.endRoute}
                  onChange={this.handleChangeInput('endRoute')}
                />
                {this.state.routeTime !== 0 ? (
                  <div>
                    <div className='routeTime'> {this.state.routeTime + ' Fahrzeit'} </div>
                    <div className='routeKm'> {this.state.routeKm + ' Km'} </div>
                  </div>
                ) : null}
                <Button className='map-route-container-btn map-btn-top' onClick={this.handleClearRoute.bind(this)}>
                  Route löschen
                </Button>
              </div>
            ) : null}
          </div>
          {this.state.tempIdOverlay !== undefined && this.state.closeInfo && (
            <div className='xls-container'>
              <div className='xls'>
                Verein:{' '}
                <p>
                  {this.state.tempIdOverlay.club_name} (ID: {this.state.tempIdOverlay.club_RowKey}){' '}
                </p>
              </div>
              {this.state.tempIdOverlay.info !== '' && (
                <div className='xls'>
                  Info: <p>{this.state.tempIdOverlay.info} </p>
                </div>
              )}
              <div className='xls'>
                Kamera:{' '}
                <p>
                  {this.state.tempIdOverlay.cam_status} (ID: {this.state.tempIdOverlay.cam_ID})
                </p>
              </div>
              <div className='map-underline' />
              {this.state.tempIdOverlay.TASK !== '' && (
                <div className='xls'>
                  {' '}
                  TASK: <p>{this.state.tempIdOverlay.TASK} </p>
                </div>
              )}
              {this.state.tempIdOverlay.task_priority !== '' && (
                <div className='xls'>
                  Task priority: <p>{this.state.tempIdOverlay.task_priority} </p>
                </div>
              )}
              {this.state.tempIdOverlay.task_coordinator !== '' && (
                <div className='xls'>
                  Task coordinator: <p>{this.state.tempIdOverlay.task_coordinator} </p>
                </div>
              )}
              {this.state.tempIdOverlay.task_status !== '' && (
                <div className='xls'>
                  Task status: <p>{this.state.tempIdOverlay.task_status} </p>
                </div>
              )}
              {this.state.tempIdOverlay.task_date !== '' && (
                <div className='xls'>
                  Task date: <p>{this.state.tempIdOverlay.task_date} </p>
                </div>
              )}
              {this.state.tempIdOverlay.task_processor !== '' && (
                <div className='xls'>
                  Task processor: <p>{this.state.tempIdOverlay.task_processor} </p>
                </div>
              )}
              <div className='map-underline' />
              {this.state.tempIdOverlay.hardware_issues !== '' && (
                <div className='xls'>
                  Hardware issues: <p>{this.state.tempIdOverlay.hardware_issues} </p>
                </div>
              )}
              {this.state.tempIdOverlay.sound !== '' && (
                <div className='xls'>
                  Sound: <p>{this.state.tempIdOverlay.sound} </p>
                </div>
              )}
              {this.state.tempIdOverlay.cam_version !== '' && (
                <div className='xls'>
                  Kamera version: <p>{this.state.tempIdOverlay.cam_version} </p>
                </div>
              )}
              {this.state.tempIdOverlay.cam_features !== '' && (
                <div className='xls'>
                  Features: <p>{this.state.tempIdOverlay.cam_features} </p>
                </div>
              )}
              {this.state.tempIdOverlay.BaseBox_version !== '' && (
                <div className='xls'>
                  Basisbox Version: <p>{this.state.tempIdOverlay.BaseBox_version} </p>
                </div>
              )}
              {this.state.tempIdOverlay.electrics !== '' && (
                <div className='xls'>
                  Electrics: <p>{this.state.tempIdOverlay.electrics} </p>
                </div>
              )}
              <div className='map-underline' />
              {this.state.tempIdOverlay.connection_issues !== '' && (
                <div className='xls'>
                  Connection issues: <p>{this.state.tempIdOverlay.connection_issues} </p>
                </div>
              )}
              {this.state.tempIdOverlay.upload_LTE !== '' && (
                <div className='xls'>
                  Upload LTE: <p>{this.state.tempIdOverlay.upload_LTE} </p>
                </div>
              )}
              {this.state.tempIdOverlay.upload_ethernet !== '' && (
                <div className='xls'>
                  Upload ethernet: <p>{this.state.tempIdOverlay.upload_ethernet} </p>
                </div>
              )}
              {this.state.tempIdOverlay.antenna !== '' && (
                <div className='xls'>
                  Antenne: <p>{this.state.tempIdOverlay.antenna} </p>
                </div>
              )}
              <div className='map-underline' />
              {this.state.tempIdOverlay.future !== '' && (
                <div className='xls'>
                  Future: <p>{this.state.tempIdOverlay.future} </p>
                </div>
              )}
              {this.state.tempIdOverlay.history !== '' && (
                <div className='xls'>
                  History: <p>{this.state.tempIdOverlay.history} </p>
                </div>
              )}
              {this.state.tempIdOverlay.installation_date !== '' && (
                <div className='xls'>
                  Installation date: <p>{this.state.tempIdOverlay.installation_date} </p>
                </div>
              )}
            </div>
          )}
        </Paper>
        {this.state.timer === true ? (
          <div className='map-legend' id='legend'>
            <div className='legend-overlay'>
              <h3>Legende</h3>
              <button className='legend-btn' onClick={this.handleOverlay.bind(this)}>
                {this.state.btn}
              </button>

              <div className='underline'>
                <InputSwitch check={this.state.filterOk} value='filterOk' handle={this.handleChange('filterOk')} />
                <img src={marker_icon_b} alt='img' />
                {this.state.legendeOpen && <div className='infoBox high-one'> Alles Ok</div>}
              </div>
              <div className='underline'>
                <InputSwitch check={this.state.filterMid} value='filterMid' handle={this.handleChange('filterMid')} />
                <img src={marker_icon_mid} alt='img' />
                {this.state.legendeOpen && (
                  <div className='infoBox high-two'>
                    Telefonejob,
                    <br /> leichte Wartung
                  </div>
                )}
              </div>
              <div className='underline'>
                <InputSwitch
                  check={this.state.filterHigh}
                  value='filterHigh'
                  handle={this.handleChange('filterHigh')}
                />
                <img src={marker_icon_high} alt='img' />
                {this.state.legendeOpen && (
                  <div className='infoBox high-three'>
                    Wichtiger TASK, <br />
                    unbekanter Fehler,
                    <br />
                    Defekt
                  </div>
                )}
              </div>
              <div className='underline'>
                <InputSwitch
                  check={this.state.filterHighest}
                  value='filterHighest'
                  handle={this.handleChange('filterHighest')}
                />
                <img src={marker_icon_highest} alt='img' />
                {this.state.legendeOpen && (
                  <div className='infoBox high-two'>
                    extrem wichtiger TASK, <br />
                    Verbindungsverlus
                  </div>
                )}
              </div>
              <div className='underline'>
                <InputSwitch
                  check={this.state.filterTest}
                  value='filterTest'
                  handle={this.handleChange('filterTest')}
                />
                <img src={marker_icon_ina_test} alt='img' />
                {this.state.legendeOpen && (
                  <div className='infoBox high-two'>
                    Deaktiviertes System,
                    <br />
                    Testsystem
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='map-legend-off' id='legend'>
            <div className='legend-overlay' />
          </div>
        )}
      </div>
    )
  }
}

export default Home
