import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import './dashboard.css'

class TableMatch extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div style={{ overflow: 'auto' }} >
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow
              style={{
                height: '45px'
              }}
            >
              <TableCell>RowKey</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Uptime</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {this.props.data.length > 0 ? (
          <div style={{ overflow: 'auto', height: '300px' }}>
            <Table style={{ tableLayout: 'fixed' }}>
              <TableBody>
                {this.props.data.map((row, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell>{row.RowKey}</TableCell>
                    <TableCell component='th' scope='row' style={{ paddingLeft: '30px' }}>
                      {row.Timestamp}
                    </TableCell>
                    <TableCell style={{ paddingLeft: '35px' }}>{row.message.uptime}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div style={{ marginTop: '18px', textAlign: 'center', height: '40px' }}>
          Keine Daten vorhanden.
          </div>
        )}
      </div>
    )
  }
}

export default TableMatch
