import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'
rax.attach()

export const uploadClubLogo = async (json) => {
  return axios.post(discovery.API_MEDIAUPLOAD + '/clublogo/upload/', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const uploadThumpnailLogo = async (json) => {
  return axios.post(discovery.API_MEDIAUPLOAD + '/clipthumpnail/upload/', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getSignedUrl = async (fileName) => {
  return axios.post(discovery.API_MEDIAUPLOAD + '/signedUrl/videoUpload/content/' + fileName, {}, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getSignedUrlGeneric = async (body) => {
  return axios.post(discovery.API_MEDIAUPLOAD + '/signedUrl/genricUpload/', JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const uploadVideo = async (file, signedUrl, callback, callbackFinish) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', signedUrl, true)
    xhr.onload = () => {
      const status = xhr.status
      if (status === 200) {
        // alert("File is uploaded")
        resolve(true)
        callback(null, true)
      } else {
        // alert("Something went wrong!")
        reject(false)
      }
    }
    xhr.upload.onprogress = function (event) {
      const percent = Math.round(100 * event.loaded / event.total)
      callback(percent)
    }
    xhr.setRequestHeader('Content-Type', 'video/mp4')
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
    xhr.send(file)
  })
}

export const uploadGeneric = async (file, signedUrl, callback, callbackFinish, type) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', signedUrl, true)
    xhr.onload = () => {
      const status = xhr.status
      if (status === 200) {
        // alert("File is uploaded")
        resolve(true)
        callback(null, true)
      } else {
        // alert("Something went wrong!")
        reject(false)
      }
    }
    xhr.upload.onprogress = function (event) {
      const percent = Math.round(100 * event.loaded / event.total)
      callback(percent)
    }
    xhr.setRequestHeader('Content-Type', type)
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
    xhr.setRequestHeader('Cache-Control', 'no-cache')
    xhr.send(file)
  })
}

export const getSignedUrlHeaderFile = async (body) => {
  return axios.post(discovery.API_MEDIAUPLOAD + '/signedUrl/fileUpload/', JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const uploadHeaderFile = async (file, signedUrl, type, callback) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', signedUrl, true)
    xhr.onload = () => {
      const status = xhr.status
      if (status === 200) {
        // alert("File is uploaded")
        resolve(true)
        callback(null, true)
      } else {
        // alert("Something went wrong!")
        reject(false)
      }
    }
    xhr.upload.onprogress = function (event) {
      const percent = Math.round(100 * event.loaded / event.total)
      callback(percent)
    }
    xhr.setRequestHeader('Content-Type', type)
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
    xhr.setRequestHeader('Cache-Control', 'no-cache')
    xhr.send(file)
  })
}
