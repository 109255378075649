import React, { Component } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Paper from '@material-ui/core/Paper'
import Settingsx from './settings'
import SearchCamera from './search'

class Settings extends Component {
  constructor (props) {
    super(props)

    this.state = {
      expanded: undefined
    }
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  };

  render () {
    const { expanded } = this.state

    return (
      <div>
        <Paper>
          <div className='layout-head'>{this.props.headText}</div>
        </Paper>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
          <ExpansionPanelSummary className='ExpansionPanelSummary' expandIcon={<ExpandMoreIcon />}>
            <Typography>Suche</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <SearchCamera
              cameraSystemList={this.props.cameraSystemList}
              handleSearch={this.props.handleSearch}
              clubs={this.props.clubs}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
          <ExpansionPanelSummary className='ExpansionPanelSummary' expandIcon={<ExpandMoreIcon />}>
            <Typography>TTS / Active Check</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Settingsx
              handleCounter={this.props.handleCounter}
              headText={this.props.headText}
              ttsMatchCounter={this.props.ttsMatchCounter}
              activeMatchCounter={this.props.activeMatchCounter}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}

export default Settings
