import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Sorting from '../modules/sorting'

class SearchFilterBar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      searchInput: '',
      filter: {
        paywall: false,
        videoType: [],
        priority: [],
        date: [],
        color: []
      },
      btn: {
        all: false
      }
    }

    this.handleFilterSettings = this.handleFilterSettings.bind(this)
    this.handleSearchInput = this.handleSearchInput.bind(this)
    this.handleSearchBtn = this.handleSearchBtn.bind(this)
    this.createButton = this.createButton.bind(this)
  }

  handleSearchInput = (event) => {
    this.setState({
      searchInput: event.target.value
    })
    this.handleSearchBtn(event.target.value, true)
  }

  handleFilterSettings (filterType, value) {
    const { filter, btn, searchInput } = this.state
    if (filterType === 'all') {
      this.setState({
        filter: { paywall: false, videoType: [], priority: [], date: [], color: [] },
        btn: { all: true }
      })
      this.props.onSearchAndFilter(searchInput, { paywall: false, videoType: [], priority: [], date: [], color: [] })
      return null
    }

    if (filterType === 'paywall') {
      filter.paywall = !filter.paywall
      btn[value] = filter.paywall
      btn.all = false

      let checkBtn = true
      // eslint-disable-next-line
      for (const list in btn) {
        if (btn[list] === true) {
          checkBtn = false
        }
      }

      if (checkBtn) {
        btn.all = true
      }
    }

    if (filterType === 'priority' || filterType === 'date' || filterType === 'videoType' || filterType === 'color') {
      let tempValue
      filter[filterType].map((prio, index) => {
        if (prio === value) {
          tempValue = { prio: prio, index: index }
        }
        return null
      })

      if (tempValue) {
        filter[filterType].splice(tempValue.index, 1)
        btn[value] = false

        let checkBtn = true
        // eslint-disable-next-line
        for (const list in btn) {
          if (btn[list] === true) {
            checkBtn = false
          }
        }

        if (checkBtn) {
          btn.all = true
        }
      } else {
        filter[filterType].push(value)
        btn[value] = true
        btn.all = false
      }
    }

    this.props.onSearchAndFilter(searchInput, filter)
    this.setState({
      filter: filter,
      btn: btn
    })
  }

  handleSearchBtn (searchInput, checkInput) {
    if (!checkInput) {
      searchInput = this.state.searchInput
    }
    const { filter, btn } = this.state
    this.props.onSearchAndFilter(searchInput, filter)

    let checkBtn = true
    // eslint-disable-next-line
    for (const list in btn) {
      if (btn[list] === true) {
        checkBtn = false
      }
    }

    if (checkBtn) {
      btn.all = true
    }

    this.setState({
      btn
    })
  }

  createButton (title, filterType, value) {
    return (
      <Button
        onClick={() => this.handleFilterSettings(filterType, value)}
        color='primary'
        variant='contained'
        className={this.state.btn[value] ? 'filter-btn' : 'filter-btn-disabled'}
        size='small'
      >
        {title}
      </Button>
    )
  }

  render () {
    return (
      <div className='socialMedia-heading'>
        <div className='heading-searchBar'>
          <TextField
            label='Suche nach Vereins- und Mannschaftsnamen / Spiel ID / Vereins ID'
            value={this.state.searchInput}
            fullWidth
            onChange={this.handleSearchInput}
            margin='none'
          />
          <Button
            onClick={this.handleSearchBtn}
            color='primary'
            variant='contained'
            className='searchBar-btn'
          >
              Suche
          </Button>
        </div>
        <div className='heading-filter'>
          <div className='filter-text'>
            <div>Filter</div>
          </div>
          <div className='flex-column'>
            <div>
              {this.createButton('Alle', 'all', 'all')}
              {this.createButton('Paywall', 'paywall', 'paywall')}
              {this.createButton('Prio 0', 'priority', 0)}
              {this.createButton('Prio 1', 'priority', 1)}
              {this.createButton('Prio 2', 'priority', 2)}
              {this.createButton('Prio 3', 'priority', 3)}
            </div>
            <div style={{ paddingLeft: '72px' }}>
              {this.createButton('Kommende', 'date', 'coming')}
              {this.createButton('Aktuell', 'date', 'current')}
              {this.createButton('Vergangene', 'date', 'past')}
            </div>
            <div style={{ paddingLeft: '72px' }}>
              {this.createButton('Grün', 'color', 'green')}
              {this.createButton('Gelb', 'color', 'yellow')}
              {this.createButton('Rot', 'color', 'red')}
            </div>
            <div style={{ paddingLeft: '72px' }}>
              {this.createButton('Fussball', 'videoType', 'soccer')}
              {this.createButton('Eishockey', 'videoType', 'icehockey')}
            </div>
          </div>
        </div>
        <Sorting onSorting={this.props.onSorting} />
      </div>
    )
  }
}

export default SearchFilterBar
