import React, { Component } from 'react'

export default class Upload extends Component {
  constructor (props) {
    super(props)
    this._handleVideoChange = this._handleVideoChange.bind(this)
    this._handleVideoReset = this._handleVideoReset.bind(this)

    this.state = {
      rawVideo: undefined,
      error: undefined
    }
  }

  async _handleVideoChange (e) {
    this.props.handleLoading(true)
    e.preventDefault()

    const file = e.target.files[0]
    /// ///////////////////
    // eslint-disable-next-line
    const form = new FormData()
    form.append('file', file)
    if (this.props.fileFormat) {
      const format = this.props.fileFormat
      const fileType = file && file.type ? file.type : undefined
      let checkFile = false

      for (let i = 0; i < format.length; i++) {
        if ('video/' + format[i] === fileType) {
          checkFile = true
          break
        }
      }

      if (checkFile) {
        this.setState({ error: undefined })
      } else {
        let text = 'Es wird nur '
        for (let i = 0; i < format.length; i++) {
          text = text + '.' + format[i] + ' '
        }
        text = text + 'akzeptiert'
        this.setState({
          error: text
        })
        this.props.handleLoading(false)
        return null
      }
    }
    /// ///////////////////
    await this.setState({ rawVideo: file.name })
    this.props.videoSelectCallback(file)
    this.props.handleLoading(false)
  }

  _handleVideoReset () {
    this.props.videoPreviewUrlReset()
    this.setState({
      rawVideo: undefined
    })
  }

  render () {
    return (
      <div className=''>
        <div className='textField-label' style={{ paddingBottom: '6px' }}>{this.props.label}</div>
        <div className='uploadImg-prevImg videoUpload-prevText '>
          <div className={this.state.rawVideo ? 'uploadImg-prevImg-tri uploadImg-prevImg-tri-red' : 'uploadImg-prevImg-tri'} />

          {this.state.rawVideo ? this.state.rawVideo : 'Video Datei...'}

          <div className={this.state.rawVideo ? 'uploadImg-prevImg-tri-right uploadImg-prevImg-tri-right-red' : 'uploadImg-prevImg-tri-right'} />
        </div>
        <div className={this.state.rawVideo ? 'uploadImg-imgUploadWrapper uploadImg-fileInput-warning' : 'uploadImg-imgUploadWrapper'}>
          {this.state.rawVideo ? 'Zurücksetzten' : 'Video Updaten'}
          {this.state.rawVideo ? (
            <input className='uploadImg-fileInput' onClick={this._handleVideoReset} />
          ) : (
            <form method='post' encType='multipart/form-data'>
              <input
                className='uploadImg-fileInput'
                type='file'
                name='video'
                onChange={(e) => this._handleVideoChange(e)}
              />
            </form>
          )}
        </div>
        {this.state.error ? (
          <div className='uploadImg-fileInput-error'>
            {this.state.error}
          </div>
        ) : null}
        {typeof this.props.uploadProcess === 'number' ? (
          <div className='uploadBar-container'>
            upload {this.props.uploadProcess}%
            <div className='uploadBar-inner' style={{ width: this.props.uploadProcess + '%' }} />
          </div>
        )
          : null}
      </div>
    )
  }
}
