import React, { Component } from 'react'
import * as _ from 'lodash'

import { createCard } from './helper/createCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faTimes, faComments, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

class ContentChatContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      globalChatCard: [],
      openChatContainer: true,
      msgCounter: 0,
      moreCards: false
    }

    this.handleChatMassages = this.handleChatMassages.bind(this)
    this.handleMoreCards = this.handleMoreCards.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.filterActive !== prevProps.filterActive) {
      this.setState({
        openChatContainer: !this.props.filterActive
      })
    }

    if (this.props.rows !== prevProps.rows || this.props.globalMsgList !== prevProps.globalMsgList) {
      const msgList = this.props.globalMsgList
      const msgSortList = {}
      let msgCounter = 0
      const newArrayRows = []
      Object.assign(newArrayRows, this.props.rows)

      msgList.map(msg => {
        const splitChannelId = msg.channelId.slice(6, msg.channelId.length)
        if (msgSortList[splitChannelId]) {
          msgSortList[splitChannelId].push(msg)
        } else {
          msgSortList[splitChannelId] = [msg]
        }
        return null
      })

      let searchCards = newArrayRows.filter(row => {
        if (msgSortList[row.RowKey]) {
          if (msgSortList[row.RowKey][0].newMsg) {
            msgSortList[row.RowKey][0].newMsg = undefined
            row.newMsg = true
          }
          row.newMsgNumber = msgSortList[row.RowKey].length
          row.lastMsgTimestamp = msgSortList[row.RowKey][0].timestamp
          msgCounter++
          return row
        }
        return null
      })

      searchCards = _.sortBy(searchCards, 'lastMsgTimestamp').reverse()
      this.setState({
        globalChatCard: searchCards,
        msgCounter: msgCounter
      })
    }
  }

  handleChatMassages () {
    this.setState({
      openChatContainer: !this.state.openChatContainer
    })
  }

  handleMoreCards () {
    this.setState({
      moreCards: !this.state.moreCards
    })
  }

  render () {
    let checkNewMsg = false
    let newMsgCounter = 0
    this.props.rows.map(row => {
      if (row.newMsg) {
        checkNewMsg = true
        newMsgCounter++
      }
      return null
    })

    return (
      <div className='content-newChat'>
        <div className='newChat-topBar'>
          <div className='left'>
            <div className='topBar-title'>
              {this.props.totalItems} von {this.props.dataSize}
            </div>
          </div>
          <div className='right'>
            <div className='topBar-title'>
              Neueste Chatbeiträge
            </div>
            <div className={checkNewMsg ? 'topBar-msgCounter topBar-msgCounterPuls' : 'topBar-msgCounter'}>
              <FontAwesomeIcon icon={faComments} /> {checkNewMsg ? newMsgCounter : this.state.msgCounter}
            </div>
            {this.state.msgCounter > 0 ? (
              <div className='topBar-btn' onClick={() => { this.handleChatMassages() }}>
                {this.state.openChatContainer ? <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faMinus} /> : <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faPlus} />}
              </div>
            ) : (
              <div className='topBar-btnDisabled'>
                <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faTimes} />
              </div>
            )}
          </div>
        </div>

        {this.state.openChatContainer ? (
          <div>
            <div className='content-boxes' style={this.state.globalChatCard.length > 0 ? { padding: '0 0 12px', borderBottom: '1px solid #0000001f' } : null}>
              {this.state.globalChatCard.map((itm, index) => {
                if (!this.state.moreCards) {
                  if (index < 4) {
                    return createCard(itm, index, this.props.onActiveContainer)
                  } else {
                    return null
                  }
                } else {
                  return createCard(itm, index, this.props.onActiveContainer)
                }
              })}
              {this.state.globalChatCard.length > 4 ? (
                <div className='content-moreBtn'>
                  <div className='btn' onClick={() => { this.handleMoreCards() }}>
                    <FontAwesomeIcon style={{ paddingRight: '10px' }} icon={!this.state.moreCards ? faChevronDown : faChevronUp} />
                    {!this.state.moreCards ? 'Mehr anzeigen' : 'Weniger anzeigen'}
                    <FontAwesomeIcon style={{ paddingLeft: '10px' }} icon={!this.state.moreCards ? faChevronDown : faChevronUp} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default ContentChatContainer
