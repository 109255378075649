import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Overlay from './overlay'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import './contentMenu.css'

/**
 * @class Menu
 * @extends {Component}
 *
 *  config={[
 *    { title: 'title text', overlay: <Component />, overlayTitle: 'title' }
 *  ]}
 *
 *
 *  Config Helper
 *    title: string
 *      - default: 'error text'
 *    overlay: object = <Component />
 *      - default: false
 *    overlayTitle: string
 *      - default: false
 *
 *  Overlay Children Props
 *    close Overlay: this.props.closeOverlay
 *
 */

class Menu extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.handleOverlay = this.handleOverlay.bind(this)
  }

  componentDidMount () {
    if (this.props.config) {
      this.props.config.map((itm, index) => {
        if (itm.overlay) {
          this.setState({
            [index]: false
          })
        }
        return null
      })
    }
  }

  renderMenuItem (title, onClick) {
    return (
      <Button disabled={this.props.disabled} onClick={onClick} className='contentMenu-btn' color='primary' variant='text'>
        <FontAwesomeIcon className='contentMenu-icon' icon={faPlusCircle} /> {title}
      </Button>
    )
  }

  handleOverlay (index) {
    this.setState({
      [index]: !this.state[index]
    })
  }

  render () {
    return (
      <>
        <Paper className='contentMenu-background' style={this.props.style}>
          {this.props.config.map((itm, index) => {
            const stateIndex = index
            return (
              <div key={index}>
                {index >= 1 ? '|' : null}
                {this.renderMenuItem(itm.title, itm.overlay ? () => { this.handleOverlay(stateIndex) } : itm.onClick)}
              </div>
            )
          })}
        </Paper>

        {this.props.config.map((itm, index) => {
          if (itm.overlay) {
            return (
              <Overlay
                key={index}
                open={this.state[index] ? this.state[index] : false}
                close={() => { this.handleOverlay(index) }}
                title={itm.overlayTitle || 'Überschrift Fehlt...'}
                accessTitle={itm.overlayAccessTitle}
              >
                {itm.overlay}
              </Overlay>
            )
          }
          return null
        })}
      </>
    )
  }
}

export default Menu
