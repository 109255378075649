import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import JSONPretty from 'react-json-pretty'

class FormDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: true,
      openBlackListed: false
    }

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    this.props.onCloseOverlay()
    this.setState({
      open: false
    })
  }

  handleOpenBar (name, index) {
    const tempValue = this.state[name]
    if (tempValue && this.state[name].index === index) {
      this.setState({ [name]: null })
    } else {
      this.setState({ [name]: { value: true, index: index } })
    }
  }

  handleOpenBarDetail (name, index, i) {
    name = 'detail-' + name
    const tempValue = this.state[name]
    if (tempValue && this.state[name].index === index && this.state[name].i === i) {
      this.setState({ [name]: null })
    } else {
      this.setState({ [name]: { value: true, index: index, i: i } })
    }
  }

  renderMatchDetails (value, name, data, index) {
    const check = this.state[name] && this.state[name].value && this.state[name].index === index
    return (
      <>
        <div className={check ? 'match-info-bar-open' : 'match-info-bar'} onClick={data[value].length > 0 ? this.handleOpenBar.bind(this, [name], index) : null}>
          {value}: {data[value].length}
        </div>
        {check ? (
          <>
            {data[value].map((itm, i) => {
              return (
                <div key={i}>
                  <div className='match-info-bar-match' onClick={this.handleOpenBarDetail.bind(this, [name], index, i)}>
                    {itm.league}
                  </div>

                  {this.state['detail-' + name] && this.state['detail-' + name].index === index && this.state['detail-' + name].i === i ? (
                    <div className='match-info-bar-match-detail'>
                      <JSONPretty data={itm} />
                    </div>
                  ) : null}

                </div>
              )
            })}
            {data[value].length > 0 ? <br /> : null}
          </>
        ) : null}
      </>
    )
  }

  renderDate (day) {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + day)
    return tomorrow.toLocaleDateString()
  }

  render () {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
        >
          <DialogTitle id='alert-dialog-title'>Matches wurden importiert</DialogTitle>
          <DialogContent>

            {this.props.data && this.props.data.map((data, index) => (
              <div key={index}>
                <div className='match-info-headbar'>
                  {this.renderDate(index + 1)}
                </div>

                <div className='match-checkclub-bar' style={{ backgroundColor: data.club.RowKey === this.props.club.RowKey && data.club.dId === this.props.club.dId ? '#91f994' : '#f99191' }}>
                  {data.club.RowKey === this.props.club.RowKey && data.club.dId === this.props.club.dId ? 'Richtiger Club zugeordnet' : 'Mit dem Club stimmt etwas nicht, dId oder RowKey sind nicht die selben'}
                </div>

                {this.renderMatchDetails('blackListed', 'openBlackListed', data, index)}
                {this.renderMatchDetails('cameraNotFound', 'openCameraNotFound', data, index)}
                {this.renderMatchDetails('importedGames', 'openImportedGames', data, index)}
                {this.renderMatchDetails('possibleGames', 'openPossibleGames', data, index)}
                <br />

              </div>
            ))}

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary' autoFocus>
              Zurück
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default FormDialog
