import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'
rax.attach()

export const patchSchedule = async (json) => {
  return axios.post(discovery.API_IMPORT + '/extern/rest/v1/game/1337', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const postTestgame = async (json) => {
  return axios.post(discovery.API_IMPORT + '/match?callAs=admin', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const addPanoramaRenderingForMatch = async (matchId, body) => {
  return axios.post(discovery.API_IMPORT + '/panorama/' + matchId, JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getImportClub = async (id) => {
  return axios.get(discovery.API_IMPORT + '/club/' + id, {
    headers: { Authorization: await getAuthorization() }
  })
}

export const getImportMatches = async (did, days) => {
  const didReplace = did.replace('#!', '')
  return axios.get(encodeURI(discovery.API_IMPORT + '/match/' + didReplace + '/' + days), {
    headers: { Authorization: await getAuthorization() }
  })
}
