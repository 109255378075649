import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import PrioMatcheList from './prioMatchList'

import { getDetectPrioMatches } from '../../../../api/api-server'

class PrioMatches extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dataOne: [],
      dataTwo: [],
      open: {
        prioOne: false,
        prioTwo: false
      }
    }
  }

  componentDidMount () {
    getDetectPrioMatches().then((res) => {
      if (res.data.status === 'INVALID_API_KEY') {
        res.data.one = []
        res.data.two = []
      }

      this.setState({
        dataOne: res.data.one,
        dataTwo: res.data.two
      })
    })
  }

  handleOpen (value) {
    let tempName
    switch (value) {
      case '1':
        tempName = 'prioOne'
        break
      case '2':
        tempName = 'prioTwo'
        break
      default:
        break
    }

    this.setState({
      open: {
        [tempName]: true
      }
    })
  }

  render () {
    return (
      <div>
        <Paper>
          <div className='layout-head'>Priorisierte Spiele</div>
          <div>
            <PrioMatcheList
              open={this.state.open.prioOne}
              headText={'Priotät 0'}
              data={this.state.dataOne}
              handleOpen={this.handleOpen.bind(this)}
              id='1'
            />
            <PrioMatcheList
              open={this.state.open.prioTwo}
              headText={'Priotät 1'}
              data={this.state.dataTwo}
              handleOpen={this.handleOpen.bind(this)}
              id='2'
            />
          </div>
        </Paper>
      </div>
    )
  }
}

export default PrioMatches
