import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const config = {
  apiKey: 'AIzaSyD7ZaM7-weZiC9zhuv0LA_BLtbKMq6vcLs',
  authDomain: 'aisw-ww-prod.firebaseapp.com',
  projectId: 'aisw-ww-prod',
  storageBucket: 'aisw-ww-prod.appspot.com',
  messagingSenderId: '287116487249',
  appId: '1:287116487249:web:d77aeb375c6508fe75e670',
  databaseURL: 'https://aisw-ww-prod-default-rtdb.europe-west1.firebasedatabase.app/',
  databaseId: 'ember'
}

export const app = initializeApp(config)
export const firestore = getFirestore(app, 'ember')
export const auth = getAuth(app)
// auth.
export default app