import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import ReactPlayer from 'react-player'
import moment from 'moment'
import SwitchVideo from '@material-ui/icons/SwitchVideo'
import SwitchImg from '@material-ui/icons/SwitchCamera'
import 'moment/locale/de'

class InfoContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openVideo: false
    }

    this.handleVideoSwitch = this.handleVideoSwitch.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.rowinfo !== prevProps.rowinfo) {
      this.setState({
        url: this.props.rowinfo !== undefined ? this.props.rowinfo.clipStream : undefined,
        openVideo: false
      })
    }
  }

  handleVideoSwitch () {
    this.setState({
      openVideo: !this.state.openVideo
    })
  }

  render () {
    let time
    let date
    if (this.props.rowinfo) {
      moment.locale('de')
      time = moment(this.props.rowinfo.referenceDate).format('LT')
      date = moment(this.props.rowinfo.referenceDate).format('L')
    }

    return (
      <Paper className='game-layout' style={{ backgroundColor: '#1e1e24' }}>
        <button className={'prevbox-switchBtn'} onClick={this.handleVideoSwitch}>
          {this.state.openVideo ? (
            <SwitchImg />
          ) : (
            <SwitchVideo />
          )}

        </button>
        <div className='layout-head' style={{ backgroundColor: '#fff' }}>
          Vorschau
        </div>

        <div style={{ maxWidth: '300px', margin: 'auto', padding: '20px' }}>
          {this.props.rowinfo ? (
            <div className={'prevbox'}>
              <div className={'prevbox-header'}>
                {this.state.openVideo ? (
                  <ReactPlayer
                    width={'100%'}
                    height={'170px'}
                    url={this.state.url}
                    controls
                  />
                ) : (
                  <img
                    src={this.props.rowinfo.thumbnail || 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_noimg_16x9.png'}
                    alt={'no-thumbnail'}
                    style={{ width: '100%' }}
                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_noimg_16x9.png' }}
                  />
                )}

              </div>
              <div className={'prevbox-content'}>
                <div className={'prevbox-content-time'}>{date} UM {time} UHR</div>
                <div className={'prevbox-content-name'}>{this.props.rowinfo.name}</div>
                <div className={'prevbox-content-desc'}>{this.props.rowinfo.description || '...'}</div>
              </div>
            </div>

          ) : (null)}
        </div>
      </Paper>
    )
  }
}

export default InfoContainer
