import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { apiList, getAuthorization, callAs } from './helper'
rax.attach()

export const getTeamInfo = async (id) => {
  return axios.get(discovery.API_TEAM + '/team/' + id + callAs('admin'), { headers: { Authorization: await getAuthorization() } })
}

export const getTeams = async () => {
  return apiList(discovery.API_TEAM + '/teams/', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const updateTeam = async (json) => {
  return axios.post(discovery.API_TEAM + '/team/' + json.RowKey + callAs('admin'), JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const createTeam = async (json) => {
  return axios.post(discovery.API_TEAM + '/team/' + callAs('admin'), JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const deleteTeam = async (id) => {
  return axios.delete(discovery.API_TEAM + '/delete/' + id + callAs('admin'), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}